import axios from "axios";  
import { AuthHeader } from "../../helper/auth.token";
import cookie from 'react-cookies';
import {message} from 'antd';
import { Map, View, Feature, } from "ol";
import { Projection } from 'ol/proj.js'
import TileLayer from "ol/layer/Tile";
import ImageLayer from "ol/layer/Image";
import MousePosition from 'ol/control/MousePosition';
import TileImage from 'ol/source/TileImage';
import {createStringXY} from 'ol/coordinate';
import TileGrid from 'ol/tilegrid/TileGrid';
import { ScaleLine } from 'ol/control.js';
import { MouseWheelZoom, defaults, DoubleClickZoom, Draw, Snap } from 'ol/interaction';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { getPrefixedUrl, getFormattedLength } from '../../utils/utils';
import Static from "ol/source/ImageStatic";
import { mapLayerTypes, liveModeLoadingMessages } from "../../utils/const";
import { Stroke, Fill, Style, RegularShape, Text } from "ol/style";
import Point from "ol/geom/Point";
import CircleStyle from "ol/style/Circle";

export const getSlideAndInitialiseMapState = (value, setState, updateLiveModeAction, moveToPixelAndFocus, map, updateLayers)=> {
    
    let txtyInfoUrl = `/api/get_txty_info?morphle_id=${value}&time=${Date.now()}`;
    let slideUrl = `/api/slide?morphle_id=${value}&time=${Date.now()}`;
    let backgroundColorUrl = `/api/get_background_color?morphle_id=${value}&time=${Date.now()}`

    let headers = {
        headers: {
            Authorization: AuthHeader()
        }
    };

    let slideDataRequest = axios.get(slideUrl, headers);
    let txtyInfoRequest = axios.get(txtyInfoUrl, headers);
    let backgroundColorRequest = axios.get(backgroundColorUrl, headers);

    axios.all([
          slideDataRequest,
          txtyInfoRequest, 
          backgroundColorRequest
      ])
      .then(axios.spread((slideDataResponse, txtyInfoResponse, backgroundColorResponse) => {

        if (backgroundColorResponse.status === 200) {
            let backgroundColor = backgroundColorResponse.data['background_color'];
            setState({
                backgroundColor: 'rgb(' + backgroundColor[0] + ',' + backgroundColor[1] + ',' + backgroundColor[2] + ')'
            })
        }

        if (slideDataResponse.status === 200 && txtyInfoResponse.status === 200) {
            console.log("txty-got_txty_info")
            loadMap(slideDataResponse.data.results[0], setState, updateLiveModeAction, txtyInfoResponse.data, moveToPixelAndFocus, map, updateLayers);
        } else {
            console.log("txty-failed",slideDataResponse.status, txtyInfoResponse.status )
            updateLiveModeAction(false, liveModeLoadingMessages.TAKING_PREVIEW);
         }
      }))
      .catch((err) => {
        message.error("Error occured while taking preview. Contact Admin.");
        console.log("err-", err)
        updateLiveModeAction(false, liveModeLoadingMessages.TAKING_PREVIEW);
      });
}

export const loadMap = (slideData, setState, updateLiveModeAction, txtyInfo, moveToPixelAndFocus, map, updateLayers) => {
    let imageInfo = getImageInfo(slideData, txtyInfo);
    initialiseMap(slideData, setState, updateLiveModeAction, txtyInfo, imageInfo, moveToPixelAndFocus, map, updateLayers);
}

export const recreateMapLayers = (slidemap, viewLevelsInfo, slide_data, imageInfo, projection, resolutions, image_pos_data, 
    tileSize, setState) => {
    let nextZoom = slidemap.getView().getZoom();
    let urlSuffix = getUrlSuffix(slide_data, isZoomForTiled(nextZoom, viewLevelsInfo));
    let url = getPrefixedUrl(urlSuffix, slide_data);
    let viewport = slidemap.getView().calculateExtent(slidemap.getSize());
    let newLayers;

    if (isZoomForTiled(nextZoom, viewLevelsInfo)) {
        let tiledZoom = nextZoom - (viewLevelsInfo.lastStitchedLevel - viewLevelsInfo.lastTiledLevel);
        newLayers = getImageLayers(url, tiledZoom, imageInfo, slide_data, image_pos_data, viewport, viewLevelsInfo.lastTiledLevel);
        let allOldLayers = slidemap.getLayers().getArray();

        let layersToRemove = [];

        for (let oi in allOldLayers) {

            let foundInNewLayers = false;
            let layerFoundAtIndex = -1;
            let oldLayer = allOldLayers[oi];
            
            for (let i = 0; i < newLayers.length; i++) {
                let newLayer = newLayers[i];
                if (oldLayer.values_.posI === newLayer.values_.posI && 
                    oldLayer.values_.posJ === newLayer.values_.posJ) {
                    if (oldLayer.values_.posZ !== newLayer.values_.posZ) {
                        let oldUrlParts = oldLayer.getSource().getUrl().split('/');
                        oldUrlParts[oldUrlParts.length - 2] = newLayer.values_.posZ;
                        oldLayer.setSource(new Static({
                            imageExtent: oldLayer.getSource().getImageExtent(),
                            url: oldUrlParts.join('/'),
                            imageSize: imageInfo.imageSizes[newLayer.values_.posZ],
                        }))
                        oldLayer.values_.posZ = newLayer.values_.posZ;
                    }
                    foundInNewLayers = true;
                    layerFoundAtIndex = i;
                }
            }
            
            if (foundInNewLayers) {
                newLayers.splice(layerFoundAtIndex, 1);
            } else if (oldLayer.values_.name !== mapLayerTypes.STITCHED_LAYER_NAME && oldLayer.values_.name !== mapLayerTypes.ANNOTATION_LAYER_NAME) {
                layersToRemove.push(oi);
            } else if (oldLayer.values_.name == mapLayerTypes.ANNOTATION_LAYER_NAME) {
                oldLayer.setVisible(true);
            } else {
                oldLayer.setVisible(false);
            }
        }

        layersToRemove.reverse();

        for (let oi in layersToRemove) {
            let oldLayer = allOldLayers[layersToRemove[oi]];
            slidemap.removeLayer(oldLayer);
        }
        
        for(let index in newLayers) {
            slidemap.addLayer(newLayers[index]);
        }   
    } else {
        let allOldLayers = slidemap.getLayers().getArray();
        let stitchedLayerExists = false;
        let layersToRemove = [];
        
        for (let oi in allOldLayers) {
            let oldLayer = allOldLayers[oi];
            if (oldLayer.values_.name === mapLayerTypes.STITCHED_LAYER_NAME) {
                stitchedLayerExists = true;
                oldLayer.setVisible(true);
            } else if (oldLayer.values_.name === mapLayerTypes.ANNOTATION_LAYER_NAME) {
                oldLayer.setVisible(true);
            } else if (oldLayer.values_.name === mapLayerTypes.TILED_LAYER_NAME) {
                layersToRemove.push(oi);
            }
        }

        layersToRemove.reverse();
        
        for (let oi in layersToRemove) {
            let oldLayer = allOldLayers[layersToRemove[oi]];
            slidemap.removeLayer(oldLayer);
        }

        if (!stitchedLayerExists) {
            newLayers = getLayer(tileSize, projection, resolutions, url);
            
            for(let index in newLayers) {
                slidemap.addLayer(newLayers[index]);
            }   
        } else {

        }
    }
    setState({
        layer: newLayers,
    });

    return newLayers;
}

const initialiseMap = (slide_data, setState, updateLiveModeAction, image_pos_data, imageInfo, moveToPixelAndFocus, map, updateLayers) => {
    let tileSize = getTileSize(slide_data, false);
    let zoomLevels = getZoomLevels(slide_data);
    let resolutions = getResolutions(slide_data, zoomLevels);
    let maxZoom = zoomLevels.length - 1;

    let imageShape = getImageShape(slide_data);
    let projection = getProjection(imageShape);
    // let view = getView(projection, resolutions, imageShape, slide_data.viewer_rotation);
    // let view = getView(projection, resolutions, imageShape, 0);
    let view = getView(projection, resolutions, imageShape, 0);

    let zoom = 0;
    
    let splitStitchedLevels = slide_data.z_levels.split(',');
    let lastStitchedLevel = parseInt(splitStitchedLevels[splitStitchedLevels.length - 1]);
    let splitTiledLevels = slide_data.tiling_z_levels.split(',');
    let lastTiledLevel = parseInt(splitTiledLevels[splitTiledLevels.length - 1]);
    let numberOfTiledLevels = lastTiledLevel - slide_data.smallest_tiled_level + 1;

    let viewLevelsInfo = {
        lastStitchedLevel,
        numberOfTiledLevels,
        lastTiledLevel,
        smallestTiledLevel: slide_data.smallest_tiled_level
    }

    let urlSuffix = getUrlSuffix(slide_data, isZoomForTiled(zoom, viewLevelsInfo));
    let url = getPrefixedUrl(urlSuffix, slide_data);

    let tileCenters = getTileCenters(imageInfo, slide_data, image_pos_data, lastTiledLevel);
    let [tileCentersTopOrigin, baseCanvasY] = getTileCentersTopOrigin(imageInfo, slide_data, image_pos_data, lastTiledLevel)

    let layer;
    if (isZoomForTiled(zoom, viewLevelsInfo))
        layer = getImageLayers(url, zoom, imageInfo, slide_data, image_pos_data, projection.getExtent(), lastTiledLevel);
    else
        layer = getLayer(tileSize, projection, resolutions, url);
    let zoomScale =  getZoomScale(slide_data, maxZoom);
    let desiredZoomScale = getDesiredZoomScale(slide_data, maxZoom);
    let ZValues =  getZValue(zoomScale, desiredZoomScale);
    let vectorLayer = getVectorLayer();
    let vectorSource = new VectorSource({});
    let annotationLayer = getAnnoLayer(vectorSource);
    layer.push(vectorLayer)
    layer.push(annotationLayer)
    let draw = new Draw({
        source: vectorSource,
        type: "LineString",
        maxPoints: 2
    });
    let snap = new Snap({ source: vectorSource });
    draw.on("drawend", onDrawEnd);
    if (map instanceof Map) {
        const layers = [...map.getLayers().getArray()];
        layers.forEach((singleLayer) => map.removeLayer(singleLayer));
        for(let index in layer) {
            map.addLayer(layer[index]);
        }
        map.setView(view);
    } else {
        let slidemap = getMap(view, layer, null);
        let dblClickInteraction;
        slidemap.getInteractions().getArray().forEach(function (interaction) {
            if (interaction instanceof DoubleClickZoom) {
                dblClickInteraction = interaction;
            }
        });
        slidemap.removeInteraction(dblClickInteraction);

        slidemap.on('click', moveToPixelAndFocus);

        slidemap.addEventListener('moveend', (e) => {
            updateLayers();
        }, false);
        setState({
            slidemap,
        });
    }

    setState({
            slide_data, 
            maxZoom, 
            zoomLevels,
            zoomScale, 
            view, 
            layer,  
            ZValues, 
            desiredZoomScale,
            imageShape,
            extent: projection.getExtent(),
            viewLevelsInfo,
            imageInfo,
            projection,
            image_pos_data,
            tileSize,
            resolutions,
            vectorLayer,
            annotationLayer,
            tileCenters,
            tileCentersTopOrigin,
            baseCanvasY,
            draw,
            snap
    });

    updateLiveModeAction(false, liveModeLoadingMessages.TAKING_PREVIEW);

}

const onDrawEnd = (e) => {
    let feature = e.feature;
    let geometry = feature.getGeometry();
    let params = getGeometricParams(geometry);
    feature.set("name", getFormattedLength(params.perimeter));
}

const getGeometricParams = (geometry) => {
  let coordinates = geometry.getCoordinates();
  let perimeter = geometry.getLength();
  return {
    area: 0,
    perimeter,
    coordinates
  };
}

const getVectorLayer = () => {
    let stroke = new Stroke({ color: '#7CFC00', width: 2 });
    let fill = new Fill({ color: 'red' });

    let iconStyle = new Style({
        image: new RegularShape({
            fill: fill,
            stroke: stroke,
            points: 4,
            radius: 10,
            radius2: 0,
            angle: 0,
        }),
    });

    let iconFeature = new Feature({
        geometry: new Point([])
    });

    iconFeature.setStyle(iconStyle);

    let vectorSource = new VectorSource({
        features: [iconFeature]
    });

    let vectorLayer = new VectorLayer({
        source: vectorSource,
        name: mapLayerTypes.ANNOTATION_LAYER_NAME,
        layerId: Date.now(),
        zIndex: 10000000,
    });
    return vectorLayer;
}

const getAnnoLayer = (vectorSource) => {

    let vectorLayer = new VectorLayer({
        source: vectorSource,
        name: mapLayerTypes.ANNOTATION_LAYER_NAME,
        layerId: Date.now(),
        zIndex: 20000000,
        style: styleFunction, 
    });
    return vectorLayer;
}

const styleFunction = (feature, resolution) => {
    return new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)"
        }),
        stroke: new Stroke({
          color: "#ffcc33",
          width: 2
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: "#ffcc33"
          })
        }),
        text: new Text({
          font: 'bold 20px "Open Sans", "Helvetica", "sans-serif"',
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)"
          }),
          stroke: new Stroke({
            color: "#ffcc33",
          }),
          placement: "line",
          textBaseline: "top",
          text: feature.get("name") != undefined ? feature.get("name") : ""
        })
    });
}

const getTileSize = (slide_data, stackMode) => {
    let tileSize;
    if (slide_data.specimen_type === "blood" || stackMode) {
        tileSize = [slide_data.tile_width, slide_data.tile_height];
    } else {
        tileSize = [slide_data.stitched_tile_width, slide_data.stitched_tile_height];
    }
    return tileSize;
}

const getImageShape = (slide_data, imageInfo) => {
    let imageWidth, imageHeight;
    if (slide_data.specimen_type === "blood") {
        imageWidth = slide_data.uperpixel * slide_data.tile_width * slide_data.x_fields;
        imageHeight = slide_data.uperpixel * slide_data.tile_height * slide_data.y_fields;
    } else if (slide_data.specimen_type === "preview") {
        imageWidth = slide_data.x_step * (slide_data.x_fields + 2);
        imageHeight = slide_data.y_step * (slide_data.y_fields + 2);
    } else {
        imageWidth = slide_data.uperpixel * slide_data.stitched_tile_width * slide_data.stitched_x_max;
        imageHeight = slide_data.uperpixel * slide_data.stitched_tile_height * slide_data.stitched_y_max;
    }
    return [imageWidth, imageHeight];
}

export const getZValue = (zoomScale, desiredZoomScale) => {

    let ZValues = [];
    for(let i = 0; i < desiredZoomScale.length; i++) {
        if(desiredZoomScale[i] == zoomScale[i]) {
            ZValues[i] = i;
        } else {
            ZValues[i] = i + ((desiredZoomScale[i] - zoomScale[i]) / (zoomScale[i + 1] - zoomScale[i]));
        }
    }

    return ZValues;
}

const getImageInfo = (slide_data, image_info_data) => {
    let imageSizes = [[slide_data.tile_width, slide_data.tile_height]];
    let splitTiledLevels = slide_data.tiling_z_levels.split(',');
    let lastTiledLevel = parseInt(splitTiledLevels[splitTiledLevels.length - 1]);
    let imageInfo = {
        xcount: slide_data.x_fields,
        ycount: slide_data.y_fields,
        zcount: lastTiledLevel + 1,
        totalImages: slide_data.total_images
    }
    for( let i = 1; i < imageInfo.zcount; i++) {
        imageSizes.push([
            Math.floor(imageSizes[i-1][0]/2),
            Math.floor(imageSizes[i-1][1]/2),
        ]);
    }
    imageSizes.reverse();
    imageInfo.imageSizes = imageSizes;

    let indexImagePosInfo = [];

    for(let i = 0; i < imageInfo.xcount; i++) {
        let temp = [];
        for(let j = 0; j < imageInfo.ycount; j++)
            temp.push([]);
        indexImagePosInfo.push(temp);
    }

    for(let image in image_info_data) {
        let xIndex = parseInt(image_info_data[image]["x_index"], 10);
        let yIndex = parseInt(image_info_data[image]["y_index"], 10);
        indexImagePosInfo[xIndex][yIndex] = image_info_data[image]['pos'];
    }
    imageInfo.posList = indexImagePosInfo;

    return imageInfo;
}

export const isZoomForTiled = (zoom, viewLevelsInfo) => {
    if (viewLevelsInfo.smallestTiledLevel == -1) {
        return false;
    }
    else if (zoom > viewLevelsInfo.lastStitchedLevel - viewLevelsInfo.numberOfTiledLevels) {
        return true;
    }
    else return false;
}

const getUrlSuffix = (slide_data, isZoomForTiled) => {
    let urlSuffix;
    if (isZoomForTiled)
        urlSuffix = `${slide_data.bucket_name}/${slide_data.path}tiled`
    else if (slide_data.specimen_type === "blood")
        urlSuffix = `${slide_data.bucket_name}/${slide_data.path}tiled/{z}/x{x}y{y}.` + slide_data.img_type;
    else
        urlSuffix = `${slide_data.bucket_name}/${slide_data.path}stitched/{z}/x{x}y{y}.` + slide_data.img_type;
    return urlSuffix
}


export const getImageLayers = (urlSuffix, zoom, imageInfo, slideData, image_info_data, viewport, maxZoom) => {
    let imageType = slideData.img_type;
    let uperpixel  =slideData.uperpixel;
    let posList = {};
    let mstCountMap = {};
    let layers = [];

    let imageShape = getImageShape(slideData, imageInfo)
    let baseCanvasY = imageShape[1];
    let minY = baseCanvasY;

    let xKey = 'X';
    let yKey = 'Y';
    let mstCountKey = 'displayOrder';

    for(let image in image_info_data) {
        let i = parseInt(image_info_data[image]["x_index"], 10);
        let j = parseInt(image_info_data[image]["y_index"], 10);

        let posInfo = imageInfo.posList[i][j];

        let xleft = posInfo[xKey] * uperpixel;
        let xright = (posInfo[xKey] + imageInfo.imageSizes[maxZoom][0]) * uperpixel;
        let yleft = baseCanvasY - (posInfo[yKey] + imageInfo.imageSizes[maxZoom][1]) * uperpixel;
        let yright = baseCanvasY - (posInfo[yKey]) * uperpixel;
        if (!isNaN(xleft)) {
            if (xright > viewport[0] && xleft < viewport[2] && yright > viewport[1] && yleft < viewport[3]) {
                posList[i + "_" + j] = [ xleft, yleft, xright, yright];
                mstCountMap[i + "_" + j] = posInfo[mstCountKey];
                if (minY > yleft)
                    minY = yleft;
            }
        }
    }

    for(let pos in posList) {
        let posI = parseInt(pos.split("_")[0]);
        let posJ = parseInt(pos.split("_")[1]);
        const imageLayer = new ImageLayer({
            source: new Static({
                imageExtent: posList[pos],
                // url: `${urlSuffix}/${zoom}/x${posI}y${posJ}.${imageType}?time=${Date.now()}`,
                url: `${urlSuffix}/${zoom}/x${posI}y${posJ}.${imageType}`,
                imageSize: imageInfo.imageSizes[zoom],
            }),
            posI: posI,
            posJ: posJ,
            posZ: zoom, 
            posS: 0,
            zIndex: mstCountMap[pos]*100, 
            name: mapLayerTypes.TILED_LAYER_NAME,
        });
    
        layers.push(imageLayer);
    }

    return layers;
}


export const getTileCenters = (imageInfo, slideData, image_info_data, maxZoom) => {
    let uperpixel  = slideData.uperpixel;

    let imageShape = getImageShape(slideData, imageInfo)
    let baseCanvasY = imageShape[1];
    let tileCenters = {};

    let xKey = 'X';
    let yKey = 'Y';

    for(let image in image_info_data) {
        let i = parseInt(image_info_data[image]["x_index"], 10);
        let j = parseInt(image_info_data[image]["y_index"], 10);

        let posInfo = imageInfo.posList[i][j];

        let xleft = posInfo[xKey] * uperpixel;
        let xright = (posInfo[xKey] + imageInfo.imageSizes[maxZoom][0]) * uperpixel;
        let yleft = baseCanvasY - (posInfo[yKey] + imageInfo.imageSizes[maxZoom][1]) * uperpixel;
        let yright = baseCanvasY - (posInfo[yKey]) * uperpixel;
        if (!isNaN(xleft)) {
            tileCenters[i + "_" + j] = [xleft + ((xright - xleft) / 2), yleft + ((yright - yleft) / 2)];
        }
    }
    return tileCenters;
}

export const getTileCentersTopOrigin = (imageInfo, slideData, image_info_data, maxZoom) => {
    let uperpixel  = slideData.uperpixel;

    let imageShape = getImageShape(slideData, imageInfo)
    let baseCanvasY = imageShape[1];
    let tileCentersTopOrigin = {};

    let xKey = 'X';
    let yKey = 'Y';

    for(let image in image_info_data) {
        let i = parseInt(image_info_data[image]["x_index"], 10);
        let j = parseInt(image_info_data[image]["y_index"], 10);

        let posInfo = imageInfo.posList[i][j];

        let xleft = posInfo[xKey] * uperpixel;
        let xright = (posInfo[xKey] + imageInfo.imageSizes[maxZoom][0]) * uperpixel;
        let yleft = posInfo[yKey] * uperpixel;;
        let yright = (posInfo[yKey] + imageInfo.imageSizes[maxZoom][1]) * uperpixel;

        // console.log("maxZoom ", imageInfo.imageSizes[maxZoom], uperpixel);
        if (!isNaN(xleft)) {
            tileCentersTopOrigin[i + "_" + j] = [xleft + ((xright - xleft) / 2), yleft + ((yright - yleft) / 2)];
        }
        // if (!isNaN(xleft)) {
        //     tileCentersTopOrigin[i + "_" + j] = [xleft, yleft];
        // }
    }
    return [tileCentersTopOrigin, baseCanvasY];
}


export const getProjection = (imageShape) => {

    let projection = new Projection({
        code: 'MORPHLE',
        units: 'microns',
        extent: [0, 0, imageShape[0], imageShape[1]],
        metersPerUnit: 0.000001,
        global: true,
        getPointResolution: (resolution, point) => resolution,
    });

    return projection;
}

export const getView = (projection, resolutions, imageShape, viewer_rotation) => {

    let view = new View({
        projection: projection,
        extent: projection.getExtent(),
        center: [imageShape[0] / 2, imageShape[1] / 2],
        zoom: 0,
        maxResolution: resolutions[0],
        maxZoom: (resolutions.length - 1),
        rotation: ((viewer_rotation * Math.PI) / 180)
    });

    return view;
}

export const getLayer = (tileSize, projection, resolutions, url) => {

    let layer = new TileLayer({
        extent: projection.getExtent(),
        source: new TileImage({
            tileGrid: new TileGrid({
                extent: projection.getExtent(),
                origin: [0, projection.getExtent()[3]],
                resolutions: resolutions,
                tileSize: tileSize,
            }),
            projection: projection,
            url: url,
            wrapX: false,
            crossOrigin: 'anonymous'
        }),
        name: mapLayerTypes.STITCHED_LAYER_NAME
    });

    return [layer];
}

export const getMap = (view, layers, setKeyboardInteractions) => {
    let slidemap = new Map({
        controls: [],
        interactions: defaults({mouseWheelZoom: false, pinchZoom: false}).extend([
            new MouseWheelZoom({
              constrainResolution: true, // force zooming to a integer zoom
            })
          ]),
        target: 'map',
        layers,
        view: view,
        keyboardEventTarget: setKeyboardInteractions ? document : null,
        loadTilesWhileAnimating: true,
        loadTilesWhileInteracting: true
    });

    return slidemap;
}

const getZoomLevels = (slide_data) => {

    let zoomLevels = [];
    zoomLevels = slide_data.z_levels.split(",");
    return zoomLevels;
}

export const getResolutions = (slide_data, zoomLevels) => {

    let resolutions = [];
    (zoomLevels).forEach((level) => {
        resolutions.push(slide_data.uperpixel * Math.pow(2, parseInt(level)));
    });

    resolutions = resolutions.reverse();

    return resolutions;
}

export const getZoomScale = (slide_data, max_zoom) => {
    var scale = [];
    let zoomRatio = 1;
    let max = (0) + parseInt(max_zoom);
    let i = max - 1;
    if (slide_data.objective_type === "hundred_x") {
	    scale[max] = 200;
    } else if (slide_data.objective_type === "twenty_x") {
	    scale[max] = 40;
    } else if (slide_data.objective_type === "sixty_x") {
	    scale[max] = 120;
    } else {
      scale[max] = 80;
    }
    for (i; i > -1; i--) {
      scale[i] = parseFloat((scale[i + 1] / 2).toFixed(1));
    }
    for(i = 0; i < scale.length; i++) {
        scale[i] = parseFloat((scale[i] / zoomRatio).toFixed(1));
    }
    if(zoomRatio != 1) {
        if (slide_data.objective_type === "hundred_x") {
            scale.push(parseFloat((400 / zoomRatio).toFixed(1)));
        } else if (slide_data.objective_type === "twenty_x") {
            scale.push(parseFloat((80 / zoomRatio).toFixed(1)));
        } else if (slide_data.objective_type === "sixty_x") {
            scale.push(parseFloat((240 / zoomRatio).toFixed(1)));
        } else {
            scale.push(parseFloat((160 / zoomRatio).toFixed(1)));
        }
    }
    return scale;
}

export const get2XZoomScale = (slide_data, max_zoom) => {
    var scale = [];
    let zoomRatio = 1;
    let max = (0) + parseInt(max_zoom);
    let i = max - 1;
    if (slide_data.objective_type === "hundred_x") {
	    scale[max] = 400;
    } else if (slide_data.objective_type === "twenty_x") {
	    scale[max] = 80;
    } else if (slide_data.objective_type === "sixty_x") {
	    scale[max] = 240;
    } else {
      scale[max] = 160;
    }
    for (i; i > -1; i--) {
      scale[i] = parseFloat((scale[i + 1] / 2).toFixed(1));
    }
    for(i = 0; i < scale.length; i++) {
        scale[i] = parseFloat((scale[i] / zoomRatio).toFixed(1));
    }
    if(zoomRatio != 1) {
        if (slide_data.objective_type === "hundred_x") {
            scale.push(parseFloat((400 / zoomRatio).toFixed(1)));
        } else if (slide_data.objective_type === "twenty_x") {
            scale.push(parseFloat((80 / zoomRatio).toFixed(1)));
        } else if (slide_data.objective_type === "sixty_x") {
            scale.push(parseFloat((240 / zoomRatio).toFixed(1)));
        } else {
            scale.push(parseFloat((160 / zoomRatio).toFixed(1)));
        }
    }
    return scale;
}

export const getDesiredZoomScale = (slide_data, max_zoom) => {
    var scale = [];
    let max = (0) + parseInt(max_zoom);
    let i = max - 1;
    if (slide_data.objective_type === "hundred_x") {
	    scale[max] = 200;
    } else if (slide_data.objective_type === "twenty_x") {
	    scale[max] = 40;
    } else if (slide_data.objective_type === "sixty_x") {
	    scale[max] = 120;
    } else
    {
      scale[max] = 80;
    }
    for (i; i > -1; i--) {
      scale[i] = parseFloat((scale[i + 1] / 2).toFixed(1));
    }
    return scale;
}

export const getDesired2XZoomScale = (slide_data, max_zoom) => {
    var scale = [];
    let max = (0) + parseInt(max_zoom);
    let i = max - 1;
    if (slide_data.objective_type === "hundred_x") {
	    scale[max] = 400;
    } else if (slide_data.objective_type === "twenty_x") {
	    scale[max] = 80;
    } else if (slide_data.objective_type === "sixty_x") {
	    scale[max] = 240;
    } else
    {
      scale[max] = 160;
    }
    for (i; i > -1; i--) {
      scale[i] = parseFloat((scale[i + 1] / 2).toFixed(1));
    }
    return scale;
}
