import React, { Component } from "react";
import { Col, Row, DatePicker, Select, Button, Modal, Input, Tooltip } from "antd";
import {InfoCircleOutlined} from "@ant-design/icons"
import { connect } from "react-redux";
import { historyMP } from '../../helper/history';
import { Link } from 'react-router-dom'
import cookie from "react-cookies";
import { getStarred, getAll, changeOrdering, clearAllFilters, setDateRange, getBarcodeFailed, getCaseNotAssigned, getAllInvalid } from "../../action/search.action";
import moment from 'moment';

var today = new Date();
var dd = today.getDate();

const { RangePicker } = DatePicker;

const Option = Select.Option;

class FilterComp extends Component {
  constructor(props) {
    super(props);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.state = {
      valType: undefined,
      valSort: "-id",
      valDateRange: undefined
    };
  }

  handleTypeChange(value) {
    if (value === "starred") {
      this.props.dispatch(getStarred(this.props.urlState));
    } else if (value === "barcode_failed") {
      this.props.dispatch(getBarcodeFailed(this.props.urlState));
    } else if (value === "case_not_assigned") {
      this.props.dispatch(getCaseNotAssigned(this.props.urlState));
    } else if (value === "invalid") {
      this.props.dispatch(getAllInvalid(this.props.urlState));
    } else {
      this.props.dispatch(getAll(this.props.urlState));
    }
  }

  handleSortChange(value) {
    this.props.dispatch(changeOrdering(value, this.props.urlState));
  }

  handleDateRangeChange(moment) {
    try {
      this.props.dispatch(setDateRange(moment[0].format("YYYY-MM-DD"), moment[1].format("YYYY-MM-DD"), this.props.urlState));
    } catch (err) {
      this.props.dispatch(setDateRange('', '', this.props.urlState));
    }
  }

  defaultFilterApply() {
  }

  handleClear() {
    this.props.dispatch(clearAllFilters());
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    var isMobile = cookie.loadAll().isMobile === "true";

    return (
        this.props.isFetching === true ? 
          <Row >
          </Row>
         : 
          <Row style={isMobile ? {height:"6em"} : {}}>
            <Col span={isMobile?6:5}>
              <Select
                value={this.props.urlState.invalid ? "invalid" : this.props.urlState.starred ? "starred" : this.props.urlState.barcode_failed ? "barcode_failed" : this.props.urlState.case_not_assigned ? "case_not_assigned" : "all"}
                placeholder="Select Type"
                onChange={this.handleTypeChange}
                style={isMobile?{width:120, marginLeft:8}:{width:150, marginLeft:8}}
              >
                <Option value="case_not_assigned">Case Not Assigned</Option>
                <Option value="barcode_failed">Barcode Failed</Option>
                <Option value="starred">Starred</Option>
                <Option value="invalid">Invalid <Tooltip title="Lists Scans that are Cancelled, Errored Out or are in Progress"><InfoCircleOutlined /></Tooltip></Option>
                <Option value="all">All</Option>
              </Select>
            </Col>
            <Col span={isMobile?8:11}>
              <RangePicker
                dateRender={current => {
                  const style = {};
                  if (current.date() === dd) {
                    style.backgroundColor = "transparent";
                    style.color = "black";
                  }
                  return (
                    <div className="ant-calendar-date" style={style}>
                      {current.date()}
                    </div>
                  );
                }}
                showToday
                onChange={this.handleDateRangeChange}
                value={this.props.urlState.date__lte.length === 0 ? undefined : [moment(this.props.urlState.date__gte, "YYYY-MM-DD"), moment(this.props.urlState.date__lte, "YYYY-MM-DD")]}
                format="YYYY-MM-DD"
                defaultValue={[undefined, undefined]}
              />
            </Col>
            {isMobile&&this.props.drawerCollapsed?<Col span={1}></Col>:null}
            <Col span={5}>
              <Select
                value={this.props.urlState.ordering}
                placeholder="Sort List"
                onChange={this.handleSortChange}
                style={{width:130}}
              >
                <Option value="name">
                  {" "}
                  A-Z
                </Option>
                <Option value="-name">
                  {" "}
                  Z-A
                </Option>
                <Option value="date_time">
                  Oldest First
                </Option>
                <Option value="-date_time">
                  Recent First
                </Option>
              </Select>
            </Col>
            <Col span={isMobile?4:3}>
              <Button
                style={{
                  marginLeft: 8,
                  backgroundColor: "white",
                  color: "gray"

                }}
                onClick={this.handleClear}
              >
                Clear Filters
              </Button>
            </Col>
          </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.searchReducer.isFetching,
    loginStatus: state.loginAlerts.loginStatus,
    urlState: state.searchReducer,
    drawerCollapsed: state.dashboardReducer.drawerCollapsed,
  };
};

export default connect(mapStateToProps)(FilterComp);
