import { bloodAnnoGridActions } from "../actionTypes/brainAnno.constant";
import axios from "axios";
import { AuthHeader } from "../helper/auth.token"
import { message } from "antd";

var querystring = require('querystring');

export const addAnnotation = (value) => ({
    type: bloodAnnoGridActions.ADD_ANNOTATION,
    response: value
});

export const removeAnnotations = (value) => ({
    type: bloodAnnoGridActions.REMOVE_ANNOTATIONS,
})

export const updateLoadingState = (value) => ({
    type: bloodAnnoGridActions.UPDATE_LOADING_STATE,
    response: value,
})

export const updateTotalArea = (value) => ({
    type: bloodAnnoGridActions.UPDATE_TOTAL_AREA,
    response: value,
})

export const updateSavedCount = (value) => ({
    type: bloodAnnoGridActions.UPDATE_COUNT_SAVED,
    response: value
})

export const updateSlideState = (value) => ({
    type:bloodAnnoGridActions.UPDATE_SLIDE_STATE,
    response: value
})

export const updateClusterId = (value) => ({
    type:bloodAnnoGridActions.UPDATE_CLUSTER_ID,
    response: value
})

export const getWholeCluster = (value) => ({
    type: bloodAnnoGridActions.GET_WHOLE_CLUSTER,
    response: value
})

export const updateMetaStasisArea = (value) => ({
    type: bloodAnnoGridActions.UPDATE_METASTASIS_AREA,
    response: value
})

export const updateFullTissueArea = (value) => ({
    type: bloodAnnoGridActions.UPDATE_METASTASIS_AREA,
    response: value
})

export const saveAnnotations = (annos) => (dispatch) => {
    let url = `/api/store_brain_annotations/`;
    console.log("reducer_save", annos)
    axios.post(url, querystring.stringify({'annotations':JSON.stringify(annos)}), { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                message.success("Annotations Saved Successfully")
                dispatch(removeAnnotations());
            }
            else if (response.status === 403 || response.status === 401 || response.status === 400) {
                // dispatch(caseListUpdateFailed(id, response));
                message.info("Annotations couldnt be saved")
                dispatch(removeAnnotations());
            }
        })
        .catch(err => {
            // dispatch(caseListUpdateFailed(id, err));
        });
}