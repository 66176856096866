import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, message, Popover, Radio, Row, Spin, Tabs } from 'antd';
import axios from "axios";
import React, { Component } from 'react';
import { connect } from "react-redux";
import { setDeviceBusy, setDeviceIdle } from '../../../action/device_status.action';
import { startScanning, takePreview, takeSelectedPreview, selectionDone, selectionConfirmed, updatePreviewStatus, setPreview, initPreviewForScan } from "../../../action/preview_status.action";
import { RegionSelectionConstants } from "../../../actionTypes/region_selection.constant";
import { objectiveType, scanSpeed, specimenType, progressStage, globalUrlPrefix } from '../../../utils/const';
import SlotComponent from '../slot_component_new';
import BloodSlideView from './blood_slide_view';
import LoadSlidesPrompt from './loadSlidesPromptNew';
import BloodPreviewSettingsComponent from './previewSettingsComponentBlood'
import {resetMapParams} from '../../../action/morpheus.state.action'
import '../../../asset/style/workflow/set-params.css';
import {resetCountSlotWise} from "../../../action/bloodConfig.action"

const { TabPane } = Tabs;

let SPECIMEN = {
    'PAP': specimenType.PAP,
    'HE': specimenType.HNE_TISSUE,
    'IHC': specimenType.IHC,
    // 'Blood': specimenType.BLOOD,
}

let SCAN_SPEED = {
    'Minor': scanSpeed.FAST,
    'Few': scanSpeed.MEDIUM,
    'Major': scanSpeed.SLOW,
    'ZSTACK': scanSpeed.ZSTACK,
}

let OBJECTIVE = {
    '20x': objectiveType.TWENTY_X,
    '40x': objectiveType.FORTY_X,
    '100x': objectiveType.HUNDRED_X,
}

let ALL_SLOTS_SELECTED = [true, true, true, true, true, true]
let ALL_SLOTS_UNSELECTED = [false, false, false, false, false, false]
let FIRST_SLOT_SELECTED = [true, false, false, false, false, false]


class SetScanParamsBloodView extends Component {
    constructor(props) {
        super(props)

        let cassetteSizeArray = [];

        for (let i = 0; i < this.props.scannerProperties.cassetteSize; i++) {
            cassetteSizeArray.push(i);
        }

        this.state = {
            specimenType: this.getKeyByValue(SPECIMEN, this.props.scannerProperties.defaultConfig.specimenType),
            scanSpeed: this.getKeyByValue(SCAN_SPEED, this.props.scannerProperties.defaultConfig.scanSpeed),
            objectiveType: this.getKeyByValue(OBJECTIVE, this.props.scannerProperties.defaultConfig.objectiveType),
            expandedSlide: false,
            selectedSlots:  this.props.scannerProperties.deviceType === "INDEX" ? FIRST_SLOT_SELECTED : ALL_SLOTS_UNSELECTED,
            isFetching: false,
            isFetchingAllPreviews: false,
            slotIndexes: this.props.scannerProperties.deviceType==="INDEX" ? [0] : cassetteSizeArray,
            device_id: 1,
            totalSelectedSlots: 1,
            cassetteSize: this.props.scannerProperties.cassetteSize,
            selectedSlotIndex: (this.props.scannerProperties.cassetteSize == 1 || this.props.scannerProperties.cassetteSize == 2) ? 0 : -1,
            defaultConfig: this.props.scannerProperties.defaultConfig,
        };
        this.slideElement =  { 
            0: React.createRef(),
            1: React.createRef(),
            2: React.createRef(),
            3: React.createRef(),
            4: React.createRef(),
            5: React.createRef(),
        }
    }

    componentDidMount = () => {
        if (this.props.scannerProperties.deviceType === "INDEX") {
            this.toggleCheckbox(0);
            }
        // this.getScannerProperties();
        
    }

    componentDidUpdate = (prevProps) => {
        if ((prevProps.deviceStatus.uiBusy || {}).status === true
                && (this.props.deviceStatus.uiBusy || {}).status === false) {
            this.setState({
                isFetching: false,
            })
        }
        if ((this.state.cassetteSize == 1 || this.state.cassetteSize == 2) && 
            prevProps.previews[0] != undefined && this.props.previews[0] != undefined &&
            prevProps.previews[0].status != this.props.previews[0].status && this.props.previews[0].status == RegionSelectionConstants.CONFIRMED) {
            this.onStartScanning();
        }
    }

    getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    getScannerProperties = () => {
        this.props.dispatch(setDeviceBusy(this.props.device_id, "Setting Up"));
        const url = "/server/devices/" + this.props.device_id + "/settings/get_scanner_properties";
        axios
        .get(url)
        .then(res => {
            this.setState({
                specimenType: this.getKeyByValue(SPECIMEN, res.data.defaultConfig.specimenType),
                scanSpeed: this.getKeyByValue(SCAN_SPEED, res.data.defaultConfig.scanSpeed),
                objectiveType: this.getKeyByValue(OBJECTIVE, res.data.defaultConfig.objectiveType),
                defaultConfig: res.data.defaultConfig,
                cassetteSize: res.data.cassetteSize,
            })
            this.props.dispatch(setDeviceIdle(this.props.device_id));
        })
        .catch(err => {
            console.log(err);   
            this.props.dispatch(setDeviceIdle(this.props.device_id));
        });
    }

    setAllTissueType = (value) => {
        this.setState({
            specimenType: value,
        });
        for (let i=0; i<this.state.cassetteSize; i++) {
            this.slideElement[i].current.setTissueType(value);
        }
    }

    setAllScanSpeed = (value) => {
        this.setState({
            scanSpeed: value,
        });
        for (let i=0; i<this.state.cassetteSize; i++) {
            this.slideElement[i].current.setScanSpeed(value);
            console.log("Setting scan speed ", this.slideElement[i], value);
        }
    }

    setAllObjective = (value) => {
        this.setState({
            objectiveType: value
        });
        for (let i=0; i<this.state.cassetteSize; i++) {
            this.slideElement[i].current.setObjectiveType(value);
        }
    }

    toggleCheckbox = (index) => {
        let selectedSlots = [...this.state.selectedSlots];
        selectedSlots[index] = selectedSlots[index] ? false : true
        this.setState({
            selectedSlots: selectedSlots
        });
        if (this.props.previews[index].status === RegionSelectionConstants.CONFIRMED) {
            // if (this.props.previews[index].response !== undefined){
            //     this.props.previews[index].status = RegionSelectionConstants.FETCHED_IMAGE
            // } else {
            //     this.props.previews[index].status = RegionSelectionConstants.NOT_STARTED
            // }
            // this.props.previews[index].status = RegionSelectionConstants.NOT_STARTED;
            // this.props.dispatch(updatePreviewStatus(index, RegionSelectionConstants.NOT_STARTED));
            let url = `/server/devices/${this.props.device_id}/remove_blood_params/?slot_id=${index}`;
                axios.get(url).then(res=>{
                console.log("slot reset done");
            })
            this.props.dispatch(initPreviewForScan(index, this.props.defaultConfig));
        } else {
            this.props.dispatch(updatePreviewStatus(index, RegionSelectionConstants.CONFIRMED));
        }
        this.slideElement[index].current.toggleSelection();
    }

    toggleAllCheckbox = () => {
        if (this.state.selectedSlots === ALL_SLOTS_SELECTED) {
            for (let index=0; index<this.state.cassetteSize; index++) {
                if (this.props.previews[index].response !== undefined){
                    this.props.previews[index].status = RegionSelectionConstants.FETCHED_IMAGE
                } else {
                    this.props.previews[index].status = RegionSelectionConstants.NOT_STARTED
                }
                this.slideElement[index].current.toggleSelection();
            }
        } else {
            for (let index=0; index<this.state.cassetteSize; index++) {
                this.props.previews[index].status = RegionSelectionConstants.CONFIRMED;
                this.slideElement[index].current.toggleSelection();
            }
        }
        this.setState({
            selectedSlots: this.state.selectedSlots === ALL_SLOTS_SELECTED ?  ALL_SLOTS_UNSELECTED : ALL_SLOTS_SELECTED
        })
    }

    expandedSlideView = (key) => {
        if (this.props.previews[key].status === RegionSelectionConstants.SELECTION_DONE || 
            this.props.previews[key].status === RegionSelectionConstants.CONFIRMED) {
			this.props.dispatch(updatePreviewStatus(key, RegionSelectionConstants.FETCHED_IMAGE));
        }
        this.setState({
            selectedSlotIndex: key,
            expandedSlide: true,
        });
    }

    closeExpadedSlideView = (slot_id) => {
        this.setState({
            expandedSlide: false,
        });
        this.toggleCheckbox(slot_id);
    }

    activate = (key) => {
        this.setState({
            isFetching: true,
        });
        this.props.dispatch(takePreview(this.state.device_id, key));
    }

    ejectCassette = () => {
        const url = "/server/devices/" + this.state.device_id + "/eject/?background=false";
        axios
        .get(url)
        .then(res => {
            message.success("Ejected successfully.");
            this.props.navigateToScannerDashboard();
        })
        .catch(err => {
            console.log(err)
            message.error("Eject Failed. Contact Admin");
        });
    }

    beforePreview = () => {
        let goScan = false;
        for(let i=0; i < Object.keys(this.props.previews || {}).length-1; i++) {
            if ((this.props.previews[i] || {}).status === RegionSelectionConstants.CONFIRMED &&
            !((this.props.previews[i] || {}).dropDistancePoint != undefined && (this.props.previews[i] || {}).dropDistancePoint.length > 0)) {
                goScan = true;
            }
        }

        if (goScan === true) {
            this.takeSelectedPreview();
        } else {
            message.error("Please select atleast one slot for taking preview.")
        }
    }

    takeSelectedPreview = () => {
        this.setState({
            isFetchingAllPreviews: true,
        });
        let selectedSlots = [];
        for (let i=0; i<this.state.cassetteSize; i++) {
            if(this.props.previews[i].status == RegionSelectionConstants.CONFIRMED &&
            !((this.props.previews[i] || {}).dropDistancePoint != undefined && (this.props.previews[i] || {}).dropDistancePoint.length > 0)) {
                selectedSlots.push(i)
            }
        }
        
        this.props.dispatch(takeSelectedPreview(this.props.device_id, selectedSlots, this.fetched, true));
    }

    fetched = () => {
        this.setState({
            isFetchingAllPreviews: false,
        })
    }

    onStartScanning = () => {
        let goScan = false;
        for(let i=0; i < Object.keys(this.props.previews || {}).length-1; i++) {
            if ((this.props.previews[i] || {}).status === RegionSelectionConstants.CONFIRMED) {
                goScan = true;
            }
        }

        if (goScan === true) {
            this.props.dispatch(startScanning(this.state.device_id, this.props.previews, this.props.bloodConfig, true));
            this.props.handleProgressChange(progressStage.SCANNING);
            this.props.dispatch(resetCountSlotWise())
        } else {
            message.error("Please select atleast one slot before starting scan.")
        }
    }

    onTabClick = (key) => {
        this.setState({
            selectedSlotIndex: key
        })
    }

    render() {

        let isFetchingAllPreviews = false;

        let currPreviewStatus = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus;

        if (currPreviewStatus == undefined || currPreviewStatus == null) {
            this.props.navigateToScannerDashboard();
            return null;
        }

        for (const [key, value] of Object.entries(currPreviewStatus)) {
            if (value.previewOngoing) {
                isFetchingAllPreviews = true;
                break;
            }
        }

        let date = new Date();
        let totalSelectedSlots = 0, totalScannableSlots = 0, totalSelectedSlotsApplicableForPreview = 0;
        for (let id in this.props.previews) {
            if((this.props.previews[id] || {}).status === RegionSelectionConstants.CONFIRMED) {
                totalSelectedSlots++;

                // if direct scan 
                totalScannableSlots++;
                totalSelectedSlotsApplicableForPreview++;

                // if ((this.props.previews[id] || {}).dropDistancePoint != undefined && (this.props.previews[id] || {}).dropDistancePoint.length > 0) {
                    // totalScannableSlots++;
                // } else {
                    // totalSelectedSlotsApplicableForPreview++;
                // }
            }
        }

        let specimenRadioButtons = Object.keys(SPECIMEN).map((specimen) => {
            return <Radio.Button key={specimen.toString()} value={specimen} style={{padding: '0px 4px'}}>
                {specimen}
            </Radio.Button>    
        });

        let speedRadioButtons = Object.keys(SCAN_SPEED).map((speed) => {
            return <Radio.Button key={speed.toString()} value={speed} style={{padding: '0px 4px'}}>
                {speed}
            </Radio.Button>
        });

        let objectiveTypeButtons = Object.keys(OBJECTIVE).map((objective) => {
            return <Radio.Button key={objective.toString()} value={objective} style={{padding: '0px 4px'}}>
                {objective}
            </Radio.Button>    
        });

        const specimenOptions = (
            <div style={{padding: '2px 0px'}}>
                <Radio.Group size={'small'} value={this.state.specimenType} 
                onChange={(e) => {e.stopPropagation(); this.setAllTissueType(e.target.value)}}
                disabled={isFetchingAllPreviews}>
                    {specimenRadioButtons}
                </Radio.Group>
            </div>
        )

        const scanSpeedOptions = (
            <div style={{padding: '2px 0px'}}>
                <Radio.Group size={'small'} value={this.state.scanSpeed} onChange={(e) => {e.stopPropagation(); this.setAllScanSpeed(e.target.value)}}>
                    {speedRadioButtons}
                </Radio.Group>
            </div>
        )

        const objectiveTypeOptions = (
            <div style={{padding: '2px 0px'}}>
                <Radio.Group size={'small'} value={this.state.objectiveType} onClick={() => {console.log('hit');}} onChange={(e) => {e.stopPropagation(); this.setAllObjective(e.target.value)}}>
                    {objectiveTypeButtons}
                </Radio.Group>
            </div>
        )

        let slides = this.state.slotIndexes.map((index) => {
            return <Col span={this.props.scannerProperties.deviceType === "INDEX" ? 5 : 4 } align={'middle'} justify={'center'}
                className={this.state.selectedSlotIndex == index && this.state.expandedSlide ==  true ? "active-slot" : ""}>
                <BloodSlideView 
                    key={index}
                    ref={this.slideElement[index]}
                    expandedSlide={this.state.expandedSlide} 
                    expandedSlideView={this.expandedSlideView} 
                    slot_id={index} 
                    defaultConfig={this.state.defaultConfig}
                    device_id={this.state.device_id} />
                <Button className="select-button" 
                    size="small" 
                    type={(this.props.previews[index] || {}).status === RegionSelectionConstants.CONFIRMED ? "danger" : ""}
                    onClick={(e) => {e.stopPropagation(); this.toggleCheckbox(index)}}
                    disabled={isFetchingAllPreviews}>
                    {(this.props.previews[index] || {}).status === RegionSelectionConstants.CONFIRMED ? "Remove" : "Select"}
                </Button>
            </Col>
        });

        const backButton = <ArrowLeftOutlined style={{fontSize: '20px', marginLeft: '30px', marginTop: '10px'}} onClick={this.closeExpadedSlideView} />;

        let slideEditors = this.state.slotIndexes.map((index) => {

            let editor = null;
            if (Object.keys(this.props.previews).length === 7){
                if (this.props.previews[index].status !== RegionSelectionConstants.NOT_STARTED && this.state.expandedSlide === true) {
                    editor = <Row>
                        {/* <Spin spinning={(this.props.deviceStatus[this.props.device_id] || {}).uiBusy}> */}
                            <div className='editing-pane'>
                                <SlotComponent id={index}
                                            device_id="1" 
                                            defaultConfig={this.state.defaultConfig} 
                                            cassetteSize={this.state.cassetteSize} 
                                            startScanning={this.onStartScanning}/>
                            </div>
                        {/* </Spin> */}
                    </Row>
                } else if (this.props.previews[index].status === RegionSelectionConstants.NOT_STARTED 
                            && this.state.expandedSlide === true) {
                    editor = <Row>
                        <br></br>
                        <Spin spinning={(this.props.deviceStatus[this.props.device_id] || {}).uiBusy}>
                            <div className="editing-pane">
                                <div className="preview-pane">
                                    <Button onClick={(e) => {e.stopPropagation(); this.activate(index)}}>
                                        Take Preview
                                    </Button>
                                </div>
                            </div>
                        </Spin>
                    </Row>
                }      
            }

            return <TabPane tab={"Slot " + (index+1)} key={index}>
                    {editor}
                </TabPane>
        });

        let isSixSlideScanner = this.props.scannerProperties.deviceType==="OPTIMUS" || this.props.scannerProperties.deviceType==="HEMOLENS_6";

        return (
            // <Spin spinning={this.state.isFetchingAllPreviews} tip={(this.props.deviceStatus[this.props.device_id] || {}).busyMessage}>
                <div className="relative-position">
                    <div className={this.props.currentProcessIndex != progressStage.LOAD_SLIDES ? "" : "blur"}>
                        { !(this.state.expandedSlide) ?
                            <Row>
                                <Col offset={isSixSlideScanner ? 0 : 10} span={isSixSlideScanner ? 24 : 12}> 
                                    <Row>
                                        {slides}
                                    </Row>
                                </Col>
                                {/* <Col span={1}>
                                    <div className="default-settings">
                                        <div style={{height: '206px'}}>
                                            <p style={{transform: 'rotate(-90deg)', fontSize: '24px', marginTop: '300px'}}>Defaults</p>
                                        </div>
                                        <div style={{justify: 'center', borderTop: '2px solid black'}}>
                                            <Popover content={specimenOptions} title={'Set Specimen Type'}>
                                                <div style={{borderBottom: '1px solid grey', textAlign: 'center', padding: '3px'}}>
                                                    {this.state.specimenType}
                                                </div>
                                            </Popover>
                                            <Popover content={scanSpeedOptions} title={'Select Tissue Artifacts Level'}>
                                                <div style={{borderBottom: '1px solid grey', textAlign: 'center', padding: '3px'}}>
                                                    {this.state.scanSpeed}
                                                </div>
                                            </Popover>
                                            <Popover content={objectiveTypeOptions} title={'Select Objective'}>
                                                <div style={{borderBottom: '1px solid grey', textAlign: 'center', padding: '3px'}}>
                                                    {this.state.objectiveType}
                                                </div>
                                            </Popover>
                                        </div>
                                    </div>
                                    <Checkbox className="checkbox" 
                                            indeterminate={totalSelectedSlots !== 0 && totalSelectedSlots !== 6 ? true :  false}
                                            checked={totalSelectedSlots === 6 ? true : false}
                                            onChange={this.toggleAllCheckbox}
                                            disabled={isFetchingAllPreviews}
                                            style={{marginLeft: '30px'}}></Checkbox>
                                </Col> */}
                            </Row>
                        : 
                            this.props.currentProcessIndex != progressStage.LOAD_SLIDES ?
                                <div>
                                    {/* {backButton}
                                    <Tabs
                                        tabPosition="left"
                                        defaultActiveKey="0" 
                                        activeKey={String(this.state.selectedSlotIndex)} 
                                        onTabClick={this.onTabClick}>
                                            {slideEditors}
                                    </Tabs> */}
                                    <Row>
                                        {/* <div className='editing-pane'>
                                            <SlotComponent id={this.state.selectedSlotIndex}
                                                device_id={this.props.device_id}
                                                defaultConfig={this.state.defaultConfig}
                                                cassetteSize={this.state.cassetteSize}
                                                startScanning={this.onStartScanning}
                                                closeExpadedSlideView={this.closeExpadedSlideView}
                                            />
                                        </div> */}
                                            <div className='editing-pane'>
                                                <BloodPreviewSettingsComponent
                                                    device_id = {this.state.device_id}
                                                    slot_id = {this.state.selectedSlotIndex}
                                                    id = {((((((this.props.deviceStatus||{})[this.state.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus||{})[this.state.selectedSlotIndex].slideId}
                                                    closeExpadedSlideView={() => this.closeExpadedSlideView(this.state.selectedSlotIndex)} 
                                                    onPreviewFinalization={this.onPreviewFinalization}
                                                    expandedView = {this.state.expandedSlide}
                                                />
                                            </div>
                                    </Row>
                                </div>
                                : null
                        }

                        <Row className={(this.state.expandedSlide || this.state.cassetteSize == 1 || this.state.cassetteSize == 2) ? "actionable-buttons-parent-div-set-params-index" : "actionable-buttons-parent-div-set-params"}>
                            <Col span={5} className="actionable-buttons-div-set-params">
                                <Button onClick={this.props.navigateToScannerDashboard} type="primary" className="actionable-buttons-set-params"
                                    disabled={(this.props.deviceStatus[this.props.device_id] || {}).uiBusy || isFetchingAllPreviews}>
                                    Back to Scanner Dashboard
                                </Button>
                            </Col>
                            <Col span={5} className="actionable-buttons-div-set-params">
                                <Button onClick={this.ejectCassette} type="danger" className="actionable-buttons-set-params"
                                    disabled={(this.props.deviceStatus[this.props.device_id] || {}).uiBusy || isFetchingAllPreviews}>
                                    Eject Cassette
                                </Button>
                            </Col>
                            <Col span={5} className="actionable-buttons-div-set-params">
                                <Button onClick={this.beforePreview} type="primary" className="actionable-buttons-set-params"
                                    disabled={totalSelectedSlotsApplicableForPreview === 0 || (this.props.deviceStatus[this.props.device_id] || {}).uiBusy || isFetchingAllPreviews}>
                                    Select Monolayer
                                </Button>
                            </Col>
                            <Col span={5} className="actionable-buttons-div-set-params">
                                <Button onClick={this.onStartScanning} type='primary' className="actionable-buttons-set-params"
                                    disabled={totalScannableSlots === 0 || (this.props.deviceStatus[this.props.device_id] || {}).uiBusy || isFetchingAllPreviews}>
                                    Scan Now ({totalScannableSlots} Slots Selected)
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    {this.props.currentProcessIndex == progressStage.LOAD_SLIDES ?
                        <LoadSlidesPrompt 
                            handleProgressChange={this.props.handleProgressChange} 
                            currentProcessIndex={this.props.currentProcessIndex} 
                            cassetteSize={this.state.cassetteSize}
                        />
                        : 
                        null
                    }
                </div>
            // </Spin>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        previews: state.previewStatusReducer,
        deviceStatus: state.deviceStatusReducer,
        bloodConfig: state.bloodConfigReducer,
    };
};

export default connect(mapStateToProps)(SetScanParamsBloodView);
