import { CodeSandboxCircleFilled, RedoOutlined } from '@ant-design/icons';
import { Input, Radio, Spin, Tooltip, Icon, message, InputNumber, Alert, Row } from 'antd';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { setObjectiveType, setScanName, setScanSpeed, setSpecimenType, takePreview, setPreviewStatusToConfirmed, setDropDistancePointLength, setDropDistancePointOnly, setFocusPoints, updatePreviewStatus, receivedPreviewStatus, changeSpecimenType, changeScanSpeed, changeSlideName } from '../../../action/preview_status.action';
import { RegionSelectionConstants } from "../../../actionTypes/region_selection.constant";
import '../../../asset/style/workflow/slide.css';
import { objectiveType, scanSpeed, specimenType } from '../../../utils/const';
import { getBarcodeCrop, getOverlayedMaskForPath } from '../preview_utils';
import { setDeviceBusy, setDeviceIdle } from '../../../action/device_status.action';
import axios from 'axios';
import { ScanApiConstant } from '../../../actionTypes/scan.constant';
import { historyMP } from "../../../../src/helper/history";
import {updateCountSlotWise} from '../../../action/bloodConfig.action'

let SPECIMEN = {
    'PAP': specimenType.PAP,
    'HE': specimenType.HNE_TISSUE,
    'IHC': specimenType.IHC,
    'BM': specimenType.BM,
    'BLOOD': specimenType.BLOOD,
}

let SCAN_SPEED = {
    'Minor': scanSpeed.FAST,
    'Few': scanSpeed.MEDIUM,
    'Major': scanSpeed.SLOW,
    'ZSTACK': scanSpeed.ZSTACK,
}

let SCAN_SPEED_NORMAL = {
    'Minor': scanSpeed.FAST,
    'Few': scanSpeed.MEDIUM,
    'Major': scanSpeed.SLOW
}

let OBJECTIVE = {
    '20x': objectiveType.TWENTY_X,
    '40x': objectiveType.FORTY_X,
    '100x': objectiveType.HUNDRED_X,
}

const initialState = {
    tissueType: null,
    scanSpeed: null,
    objective: null,
    takePreview: false,
    isFetching: false,
    barcodeAngle: -90,
    selected: false,
    slide_name: "",
    nameIsInvalid: false,
    firstLoad:true,
    WBCCount: 100,
}

class SlideView extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {

        // regex_barcode

        // ^([R]{1,1})([0-9]{0,})-([0-9]{2,2})-([0-9]{2,2})-([0-9]{3,3})([A-Z]{1,1})$

        // verify_barcode true

        if((this.props.preview||{}).slideName != undefined){
            this.setState({slide_name:(this.props.preview || {}).slideName})
        }

        //Make SpecimenTypes Config Based
        let specimens_=[]
        if((((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response||{}).supportedSpecimenTypes){
            (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response||{}).supportedSpecimenTypes.map((index)=>{
                specimens_.push(index);
            })
        }
        Object.keys(SPECIMEN).map((specimen) => {
            if(!(specimens_.includes(specimen))){
                if(specimen=="HE"){
                    if(!(specimens_.includes("HNE_TISSUE"))){
                        delete SPECIMEN[specimen]
                    }
                }
                else if(!(specimens_.includes(specimen))){
                    delete SPECIMEN[specimen]
                }
            }
        });

        let device_status = this.props.deviceStatus
        if(this.props.preview["status"] == "not_started"){
            // let slot_status = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus[this.props.slot_id]
            // this.confirmPreviewStatus((slot_status||{}).previewResponse);
            // if(slot_status!=undefined){
            //     this.props.dispatch(receivedPreviewStatus(ScanApiConstant.TAKEN_PREVIEW, (slot_status||{}).previewResponse, this.props.slot_id));
            //  }    
        }
       
        let slot_status = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus[this.props.slot_id]
        let previewresponse = (((slot_status||{}).previewResponse)||{})

        // this.confirmPreviewStatus((slot_status||{}).previewResponse);
        if(slot_status!=undefined){
            this.props.dispatch(receivedPreviewStatus(ScanApiConstant.TAKEN_PREVIEW, (slot_status||{}).previewResponse, this.props.slot_id));
        }

        this.setState({
            tissueType: this.getKeyByValue(SPECIMEN, Object.keys(this.props.preview || {}).length === 0 ? this.props.defaultConfig.specimenType : this.props.preview.specimenType),
            scanSpeed: this.getKeyByValue(SCAN_SPEED, Object.keys(this.props.preview || {}).length === 0 ? this.props.defaultConfig.scanSpeed : this.props.preview.speed),
            objective: this.getKeyByValue(OBJECTIVE, Object.keys(this.props.preview || {}).length === 0 ? this.props.defaultConfig.objectiveType : this.props.preview.objectiveType),
            slot_status: null,
        });

        if((this.props.preview||{}).slideName!=undefined && (this.props.preview||{}).slideName.length > 0){
            this.props.dispatch(changeSlideName(this.props.device_id,this.props.slot_id, (this.props.preview||{}).slideName));
        }

        if((previewresponse||{}).specimenType!=undefined && ((previewresponse||{}).specimenType||{}).length>0){
            // tissueType = this.getKeyByValue(SPECIMEN, Object.keys((previewresponse||{}).specimenType))
            this.props.dispatch(setSpecimenType(this.props.slot_id,(previewresponse||{}).specimenType));
            this.setState({
                tissueType: (previewresponse||{}).specimenType
                })
        }

        if((previewresponse||{}).scanSpeed!=undefined && ((previewresponse||{}).scanSpeed||{}).length>0){
            this.props.dispatch(setScanSpeed(this.props.slot_id, (previewresponse||{}).scanSpeed));
            this.setState({
                scanSpeed: this.getKeyByValue(SCAN_SPEED,(previewresponse||{}).scanSpeed )
                })
        }
        let actualReg = ((((this.props.deviceStatus[this.props.device_id]||{}).scanner_health||{}).scanner_response||{}).scanPreviewStatus[this.props.slot_id]||{}).regionSelected;
        let reg = {
            data: { index: 0 },
            height: 100,
            isChanging: false,
            new: false,
            width: 100,
            x: 0,
            y: 0,
        };
        if(actualReg != undefined && actualReg.length != 0){
            this.props.dispatch(setPreviewStatusToConfirmed(this.props.slot_id, reg, actualReg))
        }
        else if((slot_status||{}).previewStage === "FETCHED_IMAGE"){
            this.createCanvas((this.props.preview || {}).specimenType, reg);
        }

        if(((this.props.bloodConfig||{}).countWBCSlotWise||{})[this.props.slot_id]){
            this.setState({
                WBCCount: ((this.props.bloodConfig||{}).countWBCSlotWise||{})[this.props.slot_id]
            })
        }
        // this.setState({nameIsInvalid: this.isValid((this.props.preview || {}).slideName)})
    }

    sendbacktoparent = (val) =>{
        this.props.onNameValid(val,this.props.slot_id)
    }

    componentDidUpdate = (prevProps) => {
        if((prevProps.preview || {}).slideName !== (this.props.preview||{}).slideName){
            this.setState({
                slide_name: (this.props.preview||{}).slideName
            })
        }
        let id = this.props.slot_id
        if( (this.props.preview||{}).slideName && (prevProps.preview||{}).slideName && this.state.firstLoad){
            this.setState({nameIsInvalid: this.isValid((this.props.preview || {}).slideName)})
            this.setState({firstLoad:false})
        }

        if( prevProps.preview.status !=RegionSelectionConstants.CONFIRMED && this.props.preview.status == RegionSelectionConstants.CONFIRMED ){
            this.sendbacktoparent(this.isValid((this.props.preview || {}).slideName))
        }

        let prevPreviewStatus = (((prevProps.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus;
        let currPreviewStatus = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus;
        for (const [key, value] of Object.entries(currPreviewStatus)) {
            if (key == this.props.slot_id) {
                if ((prevPreviewStatus[key] == undefined && value.previewStage == RegionSelectionConstants.FETCHED_IMAGE) ||
                (prevPreviewStatus[key] != undefined && prevPreviewStatus[key].previewStage.toLowerCase() != RegionSelectionConstants.FETCHED_IMAGE && 
                value.previewStage.toLowerCase() == RegionSelectionConstants.FETCHED_IMAGE)) {
                    let slot_status = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus[this.props.slot_id]
                    this.confirmPreviewStatus(value.previewResponse);
                    this.props.dispatch(receivedPreviewStatus(ScanApiConstant.TAKEN_PREVIEW, value.previewResponse, key));
                }

                if ((prevPreviewStatus[key] == undefined && value.previewStage == RegionSelectionConstants.CONFIRMED) ||
                (prevPreviewStatus[key] != undefined && ((prevPreviewStatus[key].previewStage.toLowerCase() != RegionSelectionConstants.CONFIRMED && 
                value.previewStage.toLowerCase() == RegionSelectionConstants.CONFIRMED) || 
                (prevPreviewStatus[key].previewConfirmedCount != value.previewConfirmedCount)))) {
                    let slot_status = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus[this.props.slot_id]
                    this.confirmPreviewStatus(value.previewResponse);
                    this.props.dispatch(receivedPreviewStatus(ScanApiConstant.TAKEN_PREVIEW, value.previewResponse, key));
                }
            }

        }

        // if ((prevProps.preview || {}).status !== RegionSelectionConstants.FETCHED_IMAGE 
        //         && (this.props.preview || {}).status === RegionSelectionConstants.FETCHED_IMAGE) {
        // let slot_status = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus[this.props.slot_id]
        if ((prevProps.preview || {}).status === RegionSelectionConstants.GETTING_IMAGE 
        && (this.props.preview || {}).status === RegionSelectionConstants.CONFIRMED){
            let reg = {
                data: { index: 0 },
                height: 100,
                isChanging: false,
                new: false,
                width: 100,
                x: 0,
                y: 0,
            };
            this.createCanvas((this.props.preview || {}).specimenType, reg);
        }
    }

    componentWillUnmount = () => {
        this.setState({
            initialState,
        });
    }

	thresholdCanvasRef = thresholdCanvas => this.thresholdCanvas = thresholdCanvas;

    createCanvas = (specimenTypeVal, reg) => {
        const thresholdContext = this.thresholdCanvas.getContext('2d');
        const thresholdImage = new Image();
        let slot_status = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus[this.props.slot_id]
		let date = new Date();
        let defaultMaskLocation = ((slot_status||{}).previewResponse||{}).defaultMaskLocation + "?d=" + date.getTime();
        if (specimenTypeVal == specimenType.HNE_TISSUE) {
            defaultMaskLocation = ((slot_status||{}).previewResponse||{}).defaultHneMaskLocation + "?d=" + date.getTime();
        } else if (specimenTypeVal == specimenType.IHC) {
            defaultMaskLocation =((slot_status||{}).previewResponse||{}).defaultIhcMaskLocation + "?d=" + date.getTime();
        } else if (specimenTypeVal == specimenType.PAP) {
            defaultMaskLocation = ((slot_status||{}).previewResponse||{}).defaultPapMaskLocation + "?d=" + date.getTime();
        } else if (specimenTypeVal == specimenType.BM) {
            defaultMaskLocation =((slot_status||{}).previewResponse||{}).defaultBmMaskLocation + "?d=" + date.getTime();
        }else if (specimenTypeVal == specimenType.BLOOD){
            defaultMaskLocation = ((slot_status||{}).previewResponse||{}).defaultBloodMaskLocation + "?d=" + date.getTime();
        }
        thresholdImage.src = "/dev-ssd/" + defaultMaskLocation;
        thresholdImage.onload = () => {
            thresholdContext.clearRect(0, 0, this.thresholdCanvas.width, this.thresholdCanvas.height);
            thresholdContext.drawImage(thresholdImage, 0, 0, this.thresholdCanvas.width, this.thresholdCanvas.height);
            this.calculateThreshold(reg);
        }
    }

    calculateThreshold = (reg) => {
        let slot_status = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus[this.props.slot_id]
        let imageURLData = this.thresholdCanvas.toDataURL("image/jpeg");
        let previewHeight = ((slot_status||{}).previewResponse||{}).previewHeight
        let previewWidth = ((slot_status||{}).previewResponse||{}).previewWidth
        let x1 = parseInt((reg.x * previewWidth) / 100.0);
        let x2 = parseInt(((reg.x + reg.width) * previewWidth) / 100.0);
        let y1 = parseInt((reg.y * previewHeight) / 100.0);
        let y2 = parseInt(((reg.y + reg.height) * previewHeight) / 100.0);
        let actualReg = [x1, y1, x2, y2];
        this.props.dispatch(setPreviewStatusToConfirmed(this.props.slot_id, reg, actualReg));
        this.props.dispatch(setDropDistancePointOnly(this.props.slot_id, slot_status.previewResponse.dropDistancePoint));
        this.props.dispatch(setFocusPoints(this.props.slot_id, slot_status.previewResponse.focusPoints));
        this.props.dispatch(setDeviceIdle(this.props.device_id));
        this.props.dispatch(updatePreviewStatus(this.props.slot_id, RegionSelectionConstants.CONFIRMED));

        // console.log("preview after ", reg, actualReg, this.props.preview);
        //  let url = '/server/devices/' + this.props.device_id + "/send_threshold_image_background/?slot_id=" + this.props.slot_id;
        //  axios({
        //      method:"post",
        //      url : url, 
        //      data: {
        //          imgBase64: imageURLData, 
        //          specimenType: this.props.preview.specimenType,
        //          objectiveType: this.props.preview.objectiveType,
        //          actualRegion: actualReg,
        //          previewData: this.props.preview
        //      }
        //  })
        //  .then(response => {
        //      if (response.status === 200) {
        //          this.props.dispatch(setPreviewStatusToConfirmed(this.props.slot_id, reg, actualReg));
        //          this.props.dispatch(setDropDistancePointOnly(this.props.slot_id, response.data.dropDistancePoint));
        //          this.props.dispatch(setFocusPoints(this.props.slot_id, response.data.focusPoints));
        //          this.props.dispatch(setDeviceIdle(this.props.device_id));
                //  this.props.dispatch(updatePreviewStatus(this.props.slot_id, RegionSelectionConstants.CONFIRMED));
        //      }
        //      else {
        //          this.props.dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_CONFIRMING_SELECTION, response, this.props.slot_id))
        //          this.props.dispatch(setDeviceIdle(this.props.device_id));
        //          message.error("Not able to confirm preview settings. Please contact admin.", 2.5);
        //          this.setState({
        //              isFetching: false,
        //          });
        //      }
        //  })
        // .catch(err => {
        //      this.props.dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_CONFIRMING_SELECTION, err, this.props.slot_id))
        //      this.props.dispatch(setDeviceIdle(this.props.device_id));
        //      message.error("Not able to confirm preview settings. Please contact admin.", 2.5);
        //      this.setState({
        //          isFetching: false,
        //      });
        //  })
    }
    
    confirmPreviewStatus = (response) => {
        if(response!=undefined){
            let region = this.props.preview.region != undefined ? this.props.preview.region : {};
            let actualRegion = this.props.preview.actualRegion != undefined ? this.props.preview.actualRegion : [];
            this.props.dispatch(setPreviewStatusToConfirmed(this.props.slot_id, region, actualRegion));
            this.props.dispatch(setDropDistancePointOnly(this.props.slot_id, (response||{}).dropDistancePoint));
            this.props.dispatch(setDropDistancePointLength(this.props.slot_id, (response||{}).dropDistancePoint));
            this.props.dispatch(setFocusPoints(this.props.slot_id, response.focusPoints));
            this.props.dispatch(setDeviceIdle(this.props.device_id));
            this.props.dispatch(updatePreviewStatus(this.props.slot_id, RegionSelectionConstants.CONFIRMED));
            this.setState({
                isFetching: false,
            });
        }
        
    }

    getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    setTissueType = (value) => {

        let isFetchingAllPreviews = false;

        let currPreviewStatus = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus;

        for (const [key, value] of Object.entries(currPreviewStatus)) {
            if (value.previewOngoing) {
                isFetchingAllPreviews = true;
                break;
            }
        }

        this.setState({
            tissueType: value,
        });

        // this.props.dispatch(setSpecimenType(this.props.slot_id, SPECIMEN[value]));
        this.props.dispatch(changeSpecimenType(this.props.device_id, this.props.slot_id, SPECIMEN[value]))

        if ((this.props.preview || {}).region != undefined && !(isFetchingAllPreviews || !((this.props.preview || {}).status === RegionSelectionConstants.CONFIRMED &&
        ((this.props.preview || {}).dropDistancePoint != undefined && (this.props.preview || {}).dropDistancePoint.length > 0)))) {
            this.createCanvas(SPECIMEN[value], this.props.preview.region);
        }
    }

    setScanSpeed = (value) => {
        this.setState({
            scanSpeed: value,
        });
        // this.props.dispatch(setScanSpeed(this.props.slot_id, SCAN_SPEED[value]));
        this.props.dispatch(changeScanSpeed(this.props.device_id, this.props.slot_id, SCAN_SPEED[value]))
    }

    setObjectiveType = (value) => {
        this.setState({
            objective: value
        });
        this.props.dispatch(setObjectiveType(this.props.slot_id, OBJECTIVE[value]));
    }

    takePreview = () => {
        if(!(this.props.deviceStatus[this.props.device_id] || {}).uiBusy){
            this.setState({
                isFetching: true,
            });
            this.props.dispatch(takePreview(this.props.device_id, this.props.slot_id));
        }
        // else {
        //     message.error("Please wait while the device takes a preview")
        // }
    }

    expandEditor = () => {
        this.props.expandedSlideView(this.props.slot_id);
    }

    rotateBarcode = () => {
        this.setState({
            barcodeAngle: this.state.barcodeAngle + 90,
        })
    }

    isValid = (name_to_check) => {
        let regex=new RegExp('^$')
        regex = new RegExp(((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).regex_barcode)
        return !regex.test(name_to_check)       
    }

    setSlideName = (e) => {
        this.props.dispatch(changeSlideName(this.props.device_id, this.props.slot_id, e.target.value));
        this.setState({nameIsInvalid: this.isValid(e.target.value)})
        this.sendbacktoparent(this.isValid(e.target.value))
        this.setState({slide_name:e.target.value})
    }

    toggleSelection = () => {
        this.setState({
            selected: this.state.selected ? false : true,
        })
    }

    setWBCCount = (count) => {
        this.setState({
            WBCCount: count,
        })
        this.props.dispatch(updateCountSlotWise([this.props.slot_id, count]));
    }

    render() {

        let deviceType = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {})['deviceType']
        let isHemoLens = deviceType && deviceType.includes("HEMOLENS");
        let runningAdvancedMode = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {})['runningAdvancedMode'];
        let isFetchingAllPreviews = false;

        let currPreviewStatus = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus;
        let cps = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus[this.props.slot_id]
        for (const [key, value] of Object.entries(currPreviewStatus)) {
            if (value.previewOngoing) {
                isFetchingAllPreviews = true;
                break;
            }
        }

        let specimenRadioButtons = Object.keys(SPECIMEN).map((specimen) => {
            return <Radio.Button value={specimen} style={{padding: '0px 4px'}}>
                {specimen}
            </Radio.Button>    
        });

        let speedRadioButtons = (this.state.scanSpeed || "").toUpperCase() === scanSpeed.ZSTACK? 
                <Radio.Button checked={true} defaultChecked={true} value={scanSpeed.ZSTACK} style={{padding: '0px 4px'}}>
                    {scanSpeed.ZSTACK}
                </Radio.Button> : 
            Object.keys(SCAN_SPEED_NORMAL).map((speed) => {
                return <Radio.Button value={speed} style={{padding: '0px 4px'}}>
                    {speed}
                </Radio.Button>
            });
        

        let objectiveTypeButtons = Object.keys(OBJECTIVE).map((objective) => {
            return <Radio.Button value={objective} style={{padding: '0px 4px'}}>
                {objective}
            </Radio.Button>    
        });

        let showNameField = (this.props.preview.status == RegionSelectionConstants.CONFIRMED &&
            this.props.preview.status != RegionSelectionConstants.ERRORED_OUT &&
            this.props.preview.status != RegionSelectionConstants.CANCELLED &&
            this.props.preview.status != RegionSelectionConstants.GETTING_IMAGE &&
            ((this.props.preview||{})['response']||{}).slideName!== undefined) ;

        const specimenOptions = (
            <div style={{padding: '2px 0px'}}>
                <Radio.Group size={'small'} value={this.state.tissueType}
                onChange={(e) => {e.stopPropagation(); this.setTissueType(e.target.value)}}
                // disabled={
                //     // isFetchingAllPreviews ||
                //      !((this.props.preview || {}).status === RegionSelectionConstants.CONFIRMED &&
                // ((this.props.preview || {}).dropDistancePoint != undefined && (this.props.preview || {}).dropDistancePoint.length > 0))}
                disabled = {!showNameField}
                >
                    {specimenRadioButtons}
                </Radio.Group>
            </div>
        )

        const scanSpeedOptions = (
            <div style={{padding: '2px 0px'}}>

                <Radio.Group size={'small'} value={this.state.scanSpeed} onChange={(e) => {e.stopPropagation(); this.setScanSpeed(e.target.value)}}
                // disabled={
                //     // isFetchingAllPreviews ||
                //     !((this.props.preview || {}).status === RegionSelectionConstants.CONFIRMED &&
                // ((this.props.preview || {}).dropDistancePoint != undefined && (this.props.preview || {}).dropDistancePoint.length > 0))}
                disabled = {!showNameField}
                >
                    {speedRadioButtons}
                </Radio.Group>
            </div>
        )

        let nameFieldValue = (this.props.preview || {}).slideName
        let titleError = <span>Invalid <br/>Name</span>
        const nameField = (
            <div>
            {showNameField ?
                <React.Fragment>
                    <Tooltip visible={this.state.nameIsInvalid && this.props.barcodeVerify} placement="left" title={titleError} color="red" >
                        <Input bordered="true" size="small" placeholder="Slide Name" style={(this.state.nameIsInvalid && this.props.barcodeVerify) ?{borderColor:"red", margin: '1px 0px'}:{margin: '1px 0px'}} 
                            onChange={this.setSlideName} value = {this.state.slide_name}/>
                    </Tooltip>
                </React.Fragment>
                : null }
            </div>
        )

        const objectiveTypeOptions = (
            <div style={{padding: '2px 0px'}}>
                <Radio.Group size={'small'} value={this.state.objective} onChange={(e) => {e.stopPropagation(); this.setObjectiveType(e.target.value)}}>
                    {objectiveTypeButtons}
                </Radio.Group>
            </div>
        )

        // let showNameField = (!(isFetchingAllPreviews || !((this.props.preview || {}).status === RegionSelectionConstants.CONFIRMED &&
        // ((this.props.preview || {}).dropDistancePoint != undefined && (this.props.preview || {}).dropDistancePoint.length > 0))) &&

        let quickSettings=null;
        this.state.tissueType == 'BM' ?
                        quickSettings = <div className='quick-settings-button-group'>
                            {specimenOptions}
                            <div style={{padding:'2px 0px'}}><b>{isHemoLens?"# WBC:" :"# FLD:" }</b> 
                            <InputNumber min={1} max={5000} value={this.state.WBCCount} onChange={this.setWBCCount} style={{height:'23px'}} />
                            </div>
                            {nameField}
                        </div>:
                        quickSettings = <div className='quick-settings-button-group'>
                            {specimenOptions}
                            {scanSpeedOptions}
                            {/*{objectiveTypeOptions}*/}
                            {nameField}
                            {/* {showNameField ? 
                            <Input size="small" placeholder="Slide Name" style={{margin: '1px 0px'}} 
                                onChange={this.setSlideName,this.isValid} value={(this.props.preview || {}).slideName}/> : null } */}
                            </div>
  
        let date = new Date();
        let status = (this.props.preview || {}).status;

        let slide = null;
        let slot_status = ((((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus||{})[this.props.slot_id]

        // if ((slot_status || {}).previewStage != "RegionSelectionConstants.NOT_STARTED") {
        // console.log(this.props.slot_id,this.props.preview['status'])
        // if ((this.props.preview['status'] == RegionSelectionConstants.FETCHED_IMAGE || this.props.preview['status'] == RegionSelectionConstants.CONFIRMED)
        // if (this.props.preview['response']!== undefined && this.props.preview['status']!= RegionSelectionConstants.GETTING_IMAGE) {
        if ((this.props.preview['status'] == RegionSelectionConstants.FETCHED_IMAGE || this.props.preview['status']== RegionSelectionConstants.CONFIRMED) &&
        (this.props.preview['response']!== undefined && this.props.preview['response']['uploadLocation'].length !== 0)) {
            let d = new Date();
            // if(true){
            slide = <div className='filled-preview-container'
                        style={this.props.preview.status === RegionSelectionConstants.CONFIRMED ? {boxShadow: '0px 4px 8px 2px green'} : {boxShadow: '0px 4px 8px 2px rgba(0,0,0,0.5)'}}>
                        <Tooltip placement="rightTop" title={!((this.props.deviceStatus[this.props.device_id] || {}).uiBusy || isFetchingAllPreviews) ?
                        "Click to modify scan settings" : ""}>
                            <div onClick={
                                // !((this.props.deviceStatus[this.props.device_id] || {}).uiBusy || isFetchingAllPreviews)
                                true ?
                            this.expandEditor : (e) => {e.stopPropagation();}}
                            className={!((this.props.deviceStatus[this.props.device_id] || {}).uiBusy || isFetchingAllPreviews) ?
                            "filled-slide" : "filled-action-ongoing-slide"}>
                                <p>Change Scan Region</p>
                                <img className="slot-image" src={getOverlayedMaskForPath(this.props.slot_id) + '?d=' + date.getTime()} />
                            </div>
                        </Tooltip>
                        <Tooltip placement="rightTop" title={"Rotate Barcode"}>

                            <div className="barcode-filled" onClick={this.rotateBarcode}>
                                <img  key={Math.random()} className="barcode-image" src={getBarcodeCrop(this.props.slot_id) + '?d=' + date.getTime()}
                                    style={{transform: 'rotate(' + this.state.barcodeAngle + 'deg)'}}/>

                                <div className="rotate"><Icon type="reload" /></div>
                            </div>
                        </Tooltip>
                        {/* <RedoOutlined  style={{backgroundColor: 'white', padding: '10px 20px 0px 20px'}} onClick={this.rotateBarcode}/> */}
                        <div style={{display: 'flex', align: 'bottom', borderTop: '2px solid black'}}>
                            {quickSettings}
                        </div>
                    </div>
        } else {
            slide = <div className={(this.props.preview || {}).status === RegionSelectionConstants.CONFIRMED ? 'empty-confirmed-preview-container' : 'empty-unconfirmed-preview-container'}>
                        <div onClick={!((this.props.deviceStatus[this.props.device_id] || {}).uiBusy || isFetchingAllPreviews)&& !runningAdvancedMode ?
                        this.takePreview : (e) => {e.stopPropagation(); message.error("Scanner already in USE!", 2.5);}} className="centered empty-slide"
                            style={!((this.props.deviceStatus[this.props.device_id] || {}).uiBusy || isFetchingAllPreviews) ? {cursor: 'pointer'} : null }>
                            <p>Click to see the Auto-Detected Region</p>
                        </div>
                        <div className="barcode-empty">

                        </div>
                        <div style={{display: 'flex', align: 'bottom', borderTop: '2px solid black'}}>
                            {/* {quickSettings} */}
                            {/* <div className='quick-settings-button-group'> */}
                                {/* {specimenOptions} */}
                                {/* {scanSpeedOptions} */}
                                {/*{objectiveTypeOptions}*/}
                                {/* {quickSettings} */}
                            {/* </div> */}
                        </div>
                        <div style={{display: 'flex', align: 'bottom', borderTop: '2px solid black'}}>
                        {quickSettings}
                        </div>
                    </div>

        }

        let previewOngoing = (((((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus || {})[this.props.slot_id] || {}).previewOngoing;

        return (
            <Spin spinning={previewOngoing != undefined && previewOngoing}
            tip={(this.props.deviceStatus[this.props.device_id] || {}).busyMessage}>
            {/* <Spin spinning={this.state.isFetching} tip={(this.props.deviceStatus[this.props.device_id] || {}).busyMessage}> */}
                <div className='slide-container'>
                    {slide}
                    <canvas ref={this.thresholdCanvasRef} className="region-selection-canvas-new" style={{display: 'none'}} />
                </div>
            </Spin>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        preview: state.previewStatusReducer[ownProps.slot_id],
        deviceStatus: state.deviceStatusReducer,
        slot_id: ownProps.slot_id,
        bloodConfig: state.bloodConfigReducer,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SlideView);