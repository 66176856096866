import { BloodGridPLTActions } from '../actionTypes/bloodGridPLT.const';
import axios from 'axios';
import { AuthHeader } from "../helper/auth.token"

var querystring = require('querystring');

export const sizeRangeChangedPLT = (value) => ({
    type: BloodGridPLTActions.PLT_UPDATE_SIZE_RANGE,
    response: value,
})

export const extremeSizeRangeChangedPLT = (value) => ({
    type: BloodGridPLTActions.PLT_UPDATE_EXTREME_SIZE_RANGE,
    response: value,
})

export const pltGridSelected = (value) => ({
    type: BloodGridPLTActions.PLT_GRID_SELECTED,
    response: value,
})

export const updatePagePLT = (value) => ({
    type: BloodGridPLTActions.UPDATE_PAGE,
    response: value
})

export const updateFetchingGridPLT = (value) => ({
    type: BloodGridPLTActions.UPDATE_FETCHING_GRID,
    response: value
})

export const updatePageSizePLT = (value) => ({
    type: BloodGridPLTActions.UPDATE_PAGE_SIZE,
    response: value
})

export const updateTotalPagesPLT = (value) => ({
    type: BloodGridPLTActions.UPDATE_TOTAL_PAGES,
    response: value
})

export const updateTotalCountPLT = (value) => ({
    type: BloodGridPLTActions.UPDATE_TOTAL_COUNT,
    response: value,
})

export const updateCurrGridsPLT = (value) => ({
    type: BloodGridPLTActions.UPDATE_CURR_GRIDS,
    response: value
})

export const updateSelectedGridOverallIdPLT = (value) => ({
    type: BloodGridPLTActions.SELECTED_GRID_OVERALL_ID,
    response: value
})

export const pltChangeImage = (value) => ({
    type: BloodGridPLTActions.PLT_CHANGE_IMAGE,
    response: value
})