import { MagicToolActions } from "../actionTypes/magictool.const";
import axios from "axios";
import { AuthHeader } from "../helper/auth.token"
import { message } from "antd";

var querystring = require('querystring');

export const updateStartPoint = (value) => ({
    type: MagicToolActions.UPDATE_TOOL_START_POINT,
    response: value
});

export const updateCurrentAnnotations = (value) => ({
    type: MagicToolActions.UPDATE_CURRENT_ANNOTATIONS,
    response: value
})

export const getAnnotations = (start,z, slide_id, extent, user_id) => (dispatch) => {
    let url = `/api/get_brain_slider/`;
    let val = {
        'start_point':JSON.stringify(start),
        'z': z,
        'slide': slide_id,
        'extent': JSON.stringify(extent),
        'user_id': user_id
    }
    console.log("val", val)
    // dispatch(updateStartPoint(start))
    axios.post(url, querystring.stringify(val), { headers: { Authorization: AuthHeader() } })
        .then(response => {
            console.log("resp");
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                dispatch(updateCurrentAnnotations(response.data));
            }
            else if (response.status === 403 || response.status === 401 || response.status === 400) {
                // dispatch(caseListUpdateFailed(id, response));
                dispatch(updateCurrentAnnotations([100,100]));
            }
        })
        .catch(err => {
            dispatch(updateCurrentAnnotations([100,100]));
            console.log("resp-catch");
        });
}