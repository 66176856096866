import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import cookie from "react-cookies";
import { historyMP } from '../../helper/history';
import { AuthHeader } from "../../helper/auth.token";
import { Map, View } from "ol";
import { Projection } from 'ol/proj.js';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import DoubleClickZoom from 'ol/interaction/DoubleClickZoom';
import { Fill, RegularShape, Stroke, Style } from 'ol/style';
import { getCenter } from 'ol/extent';
import TileLayer from "ol/layer/Tile";
import TileImage from 'ol/source/TileImage';
import TileGrid from 'ol/tilegrid/TileGrid';
import LayerGroup from "ol/layer/Group";
import { MouseWheelZoom, defaults } from 'ol/interaction';
import { Row, Col, Button, message, Select, Radio, Icon, Tooltip } from 'antd';
import { updateDeviceIP, updateNumberOfSlots, updateLiveModePreviewVectorLayer, updateDoDropDistanceAF, updateLiveModeAction, updateVisitedArea, updatePreviewImageExtent, updateSlotID, updateTakeZStack, updateDoAutoFocus } from '../../action/admin.state.action';
import { updateLiveView, updateLiveViewUrl } from "../../action/liveview.action";
import { getLiveImageMoveToPixelAndFocus, updateAccessRevoked, updateAccessToLiveMode, updateTakePreviewInLiveMode, updateUseLiveModeInViewMode, updateAtLeastOneImageFetched, updateCurrentMapPosition, updateLiveModeStatus, updateTileCenters, getNearestTile, updateSlideData, updateCurrentPreview, updateUseCoverSlipObjective, markArea, updateGlobalCoordinates, updateVisitedTiles , syncVisitedTilesTrigger} from "../../action/livemode.action";
import { updateLiveView as updateLivePreview } from "../../action/livemaincamerapreview.action";
import { liveModeLoadingMessages, previewCameraType, globalUrlPrefix } from "../../utils/const";
import { FaPencilAlt } from 'react-icons/fa';
import KeyboardMovementListener from "./keyboard_movement_listener";
import Polygon from 'ol/geom/Polygon';
import * as turf from "@turf/turf";
import GeoJSON from 'ol/format/GeoJSON';


import "../../asset/style/manualmode/preview_app.css";
import { getSlideAndInitialiseMapState, recreateMapLayers } from "./preview_live_mode_txtyviewer_utils";
import { getLabelComponent, getLabelComponentRotated } from "../dashboard/slidelist_utils";

const { Option } = Select;

const baseStyle = new Style({
    stroke: new Stroke({
        color: 'rgba(0,0,0,0)',
        width: 0
    }),
    fill: new Fill({
        color: 'rgba(0, 255, 0, 0.4)'
    })
});
const otherStyle = new Style({
    stroke: new Stroke({
        color: 'rgba(0,0,0,0)',
        width: 5
    })
})

class PreviewLiveMode extends Component {

    constructor(props) {
        super(props);

        this.state = {
            initError: false,
            errorMessage: '',
            deviceIP: '',
            numberOfSlots: 0,
            clickActionGoing: false,
            map: null,
            loaded: false,
            isDrawing: false,
            showAnnoLayer: true,
            objectiveRatio: 1,
        }

        this.previewStartTime = 0;

        this.canvasRef = React.createRef();

        this.radioRefs = [];

        this.image = new Image();

        this.image.onload = this.onloadRunner;

        this.onloadRunner = this.onloadRunner.bind(this);

    }

    componentDidMount = () => {
        if (this.props.livemode.deviceId > 0) {
            // this.getLivemodeConfig();
            this.getNumberOfSlots();
        }
        // this.getObjectiveRatio();
    }

    getObjectiveRatio = () => {
        let url = "/server/devices/" + this.props.livemode.deviceId + "/settings/preview_tuning/fetch_objective_ratio";
        console.log("url---", url)
        axios.get(url).then(res => {
            this.setState({
                objectiveRatio: res.data['ratio'],
            })
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.livemode.deviceId != this.props.livemode.deviceId) {
            // this.getLivemodeConfig();
            this.getNumberOfSlots();
        }
        if (prevProps.adminState.slotID != this.props.adminState.slotID && this.props.adminState.slotID >= 0) {
            if (this.props.livemode.useLiveModeInViewMode || !this.props.livemode.takePreviewInLiveMode) {
                this.props.dispatch(updateDoDropDistanceAF(false));
                this.props.dispatch(updateLiveViewUrl(`/dev-ssd/last_preview/z_stack/slot_${this.props.adminState.slotID}/0.jpg?time=` + Date.now()));
                if (this.props.adminState.previewCamera == previewCameraType.FOUR_X) {
                    let loaded_slides_live_mode = ((this.props.device || {}).scanner_health || {}).loaded_slides_live_mode;
                    if (loaded_slides_live_mode != undefined && loaded_slides_live_mode[this.props.adminState.slotID] != "")
                        this.onloadRunner(this.props.adminState.slotID, loaded_slides_live_mode[this.props.adminState.slotID]);
                } else {
                    this.image.src = '/dev-ssd/last_preview/uncropped.jpg?time=' + Date.now();
                }
            } else {
                this.getPreview(this.props.adminState.slotID);
            }
        } else if (prevProps.adminState.slotID != this.props.adminState.slotID && this.props.adminState.slotID < 0) {
            this.props.dispatch(updateAccessToLiveMode(false));
            this.props.dispatch(updateUseLiveModeInViewMode(false));
            this.props.dispatch(updateAccessRevoked(false));
            this.props.dispatch(updateTakePreviewInLiveMode(true));
            this.props.dispatch(updateDoDropDistanceAF(true));
            this.props.dispatch(updateLiveView(undefined));
            this.props.dispatch(updateLiveViewUrl(undefined));
            this.props.dispatch(updateTakeZStack(false));
            this.props.dispatch(updateDoAutoFocus(true));
            this.setState({
                loaded: false,
            });
            const layers = [...this.state.slidemap.getLayers().getArray()];
            layers.forEach((layer) => this.state.slidemap.removeLayer(layer));
        }

        let prevLoadedSlidesLiveMode = ((prevProps.device || {}).scanner_health || {}).loaded_slides_live_mode;
        let loadedSlidesLiveMode = ((this.props.device || {}).scanner_health || {}).loaded_slides_live_mode;

        if (prevLoadedSlidesLiveMode != undefined && loadedSlidesLiveMode == undefined) {
            if (cookie.loadAll().is_staff === 'true' || cookie.loadAll().superuser === 'true' ||
            (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).collapse_menu.scans || (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience) {
                historyMP.push('/' + globalUrlPrefix + '/dashboard');
            } else {
                historyMP.push('/' + globalUrlPrefix + '/cases');
            }
        }

        if ((((prevProps.device || {}).scanner_health || {}).scanner_response || {}).takingPreviewLiveMode != undefined && 
        (((this.props.device || {}).scanner_health || {}).scanner_response || {}).takingPreviewLiveMode != undefined &&
        (((prevProps.device || {}).scanner_health || {}).scanner_response || {}).takingPreviewLiveMode != (((this.props.device || {}).scanner_health || {}).scanner_response || {}).takingPreviewLiveMode) {
            if (!(((this.props.device || {}).scanner_health || {}).scanner_response || {}).takingPreviewLiveMode) {
                if (this.props.adminState.previewCamera == previewCameraType.FOUR_X) {
                    if(loadedSlidesLiveMode[this.props.adminState.slotID] != undefined) {
                        this.onloadRunner(this.props.adminState.slotID, loadedSlidesLiveMode[this.props.adminState.slotID]);
                    }
                } else {
                    this.image.src = '/dev-ssd/last_preview/uncropped.jpg?time=' + Date.now();
                }
            }
        }

        if (((prevProps.device || {}).scanner_health || {}).fourx_tiling_ongoing != undefined && 
        ((this.props.device || {}).scanner_health || {}).fourx_tiling_ongoing != undefined &&
        ((prevProps.device || {}).scanner_health || {}).fourx_tiling_ongoing != ((this.props.device || {}).scanner_health || {}).fourx_tiling_ongoing) {
            if (!((this.props.device || {}).scanner_health || {}).fourx_tiling_ongoing && this.props.livemode.scheduledPreviews.length == 0) {
                if (this.props.adminState.previewCamera == previewCameraType.FOUR_X) {
                    this.onloadRunner(this.props.adminState.slotID, loadedSlidesLiveMode[this.props.adminState.slotID]);
                } else {
                    this.image.src = '/dev-ssd/last_preview/uncropped.jpg?time=' + Date.now();
                }
            }
        }

        if ((((prevProps.device || {}).scanner_health || {}).scanner_response || {}).takingAllPreviewsLiveMode != undefined && 
        (((this.props.device || {}).scanner_health || {}).scanner_response || {}).takingAllPreviewsLiveMode != undefined && 
        (((prevProps.device || {}).scanner_health || {}).scanner_response || {}).takingAllPreviewsLiveMode != (((this.props.device || {}).scanner_health || {}).scanner_response || {}).takingAllPreviewsLiveMode) {
            if (!(((this.props.device || {}).scanner_health || {}).scanner_response || {}).takingAllPreviewsLiveMode) {
                if (this.props.adminState.previewCamera == previewCameraType.FOUR_X) {
                    this.props.dispatch(updateSlotID(0));
                    this.onloadRunner(0, loadedSlidesLiveMode[0]);
                } else {
                    this.image.src = '/dev-ssd/last_preview/uncropped.jpg?time=' + Date.now();
                }
            }
        }

        // if (prevLoadedSlidesLiveMode != undefined && JSON.stringify(prevLoadedSlidesLiveMode) !== JSON.stringify(loadedSlidesLiveMode)) {
        //     console.log("Entered")
        //     if (this.props.adminState.slotID >= 0 && loadedSlidesLiveMode[this.props.adminState.slotID] != "") {
        //         if (this.props.adminState.previewCamera == previewCameraType.FOUR_X) {
        //             this.onloadRunner(this.props.adminState.slotID, loadedSlidesLiveMode[this.props.adminState.slotID]);
        //         } else {
        //             this.image.src = '/dev-ssd/last_preview/uncropped.jpg?time=' + Date.now();
        //         }
        //     }
        // }

        if (prevState.imageShape != this.state.imageShape) {
            this.props.dispatch(updatePreviewImageExtent([0, 0, this.state.imageShape[0], this.state.imageShape[1]]));
        }

        if ((prevState.vectorLayer == undefined && this.state.vectorLayer != undefined) || 
        (prevState.vectorLayer != undefined && this.state.vectorLayer != undefined && prevState.vectorLayer.values_.layerId != this.state.vectorLayer.values_.layerId)) {
            this.props.dispatch(updateLiveModePreviewVectorLayer(this.state.vectorLayer));
            if (this.props.livemode.lastBound[this.props.adminState.slotID] != undefined) {
                // this.props.dispatch(markArea(this.props.livemode.lastBound[this.props.adminState.slotID], this.props.adminState.areaVisited, this.state.vectorLayer));
                this.state.slidemap.getView().setCenter(this.getLastPoint(this.props.livemode.lastBound[this.props.adminState.slotID]));
                this.state.slidemap.getView().setZoom(this.state.slidemap.getView().getMaxZoom());
            }
        }

        if ((prevState.tileCenters == undefined && this.state.tileCenters != undefined) || 
        (JSON.stringify(prevState.tileCenters) != JSON.stringify(this.state.tileCenters))) {
            console.log(this.props.livemode);
            let newTileCenters = Object.assign({}, this.props.livemode.tileCenters);
            newTileCenters[this.props.adminState.slotID] = this.state.tileCenters;
            this.props.dispatch(updateTileCenters(newTileCenters));
        }

        if ((prevState.slide_data == undefined && this.state.slide_data != undefined) || 
        (JSON.stringify(prevState.slide_data) != JSON.stringify(this.state.slide_data))) {
            this.props.dispatch(updateSlideData(this.state.slide_data));
            console.log(this.previewStartTime)
            console.log(Date.now() - this.previewStartTime)
            console.log("Total Preview Time: " + (Date.now() - this.previewStartTime));
        }

        if(prevState.tileCenters != this.state.tileCenters){
            this.syncVisitedTilesFromBackend();
        }
        if(prevProps.livemode.syncVisitedTilesTrigger==false && this.props.livemode.syncVisitedTilesTrigger==true){
            console.log("trigger--")
            this.syncVisitedTilesFromBackend();
            this.props.dispatch(syncVisitedTilesTrigger(false));
        }

        if(!prevProps.livemode.visitedTiles[this.props.adminState.slotID] && this.props.livemode.visitedTiles[this.props.adminState.slotID]){
            this.drawTilesOnMap();
        }
        let prevTiles = prevProps.livemode.visitedTiles[this.props.adminState.slotID]
        let tiles = this.props.livemode.visitedTiles[this.props.adminState.slotID];
        console.log("draw--",(prevTiles||{}).length, (tiles||{}).length)

        if(prevTiles && prevTiles.length != tiles.length){
            console.log("drawOnMap")
            this.drawTilesOnMap();
        }

        if(prevProps.livemode.deviceId != this.props.livemode.deviceId){
            this.getObjectiveRatio();
        }
    }

    getLastPoint = (bound) => {
        let minX = Number.MAX_VALUE;
        let maxX = Number.MIN_VALUE;
        let minY = Number.MAX_VALUE;
        let maxY = Number.MIN_VALUE;
        for (let i = 0; i < bound.length; i++) {
            let point = bound[i];
            if (point[0] < minX) {
                minX = point[0];
            }
            if (point[0] > maxX) {
                maxX = point[0];
            }
            if (point[1] < minY) {
                minY = point[1];
            }
            if (point[1] > maxY) {
                maxY = point[1];
            }
        }
        return [Math.floor(minX + ((maxX - minX) / 2)), Math.floor(minY + ((maxY - minY) / 2))];
    }

    getLivemodeConfig = () => {
        this.props.dispatch(updateLiveModeAction(true, 'Loading...'));
        let partsOfUrl = "api~settings~get_livemode_settings";
        let url = `/server/scano_text/` + this.props.livemode.deviceId + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getNumberOfSlots();
                }
                else {
                    console.log(response);
                    message.error("Not able to set livemode. Please contact admin!!", 2.5);
                    this.props.dispatch(updateLiveModeAction(false, 'Loading...'));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set livemode. Please contact admin!!", 2.5);
                if (!this.props.adminState.useStreakFocus) {
                    this.props.dispatch(updateLiveModeAction(false, 'Loading...'));
                }
            })
    }

    mainLightOn = (e) => {
        if (!this.props.adminState.useStreakFocus) {
            this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.MAIN_LIGHT_ON));
        }
        let partsOfUrl = "api~stage~switch_on_main_light";
        let url = `/server/scano/` + this.props.livemode.deviceId + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                }
                else {
                    console.log(response);
                    message.error("Not able to switch main light on!!", 2.5);
                }
                if (!this.props.adminState.useStreakFocus) {
                    this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.MAIN_LIGHT_ON));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to switch main light on!!", 2.5);
                if (!this.props.adminState.useStreakFocus) {
                    this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.MAIN_LIGHT_ON));
                }
            })
    }

    getZoomLevels = (highestZLevel) => {
        let zLevels = [];
        for (let i = 0; i <= highestZLevel; i++) {
            zLevels.push(i);
        }
        return zLevels;
    }

    getResolutions = (zoomLevels, uperpixel) => {
        let resolutions = [];
        (zoomLevels).forEach((level, index) => {
            resolutions.push(uperpixel * Math.pow(2, parseInt(level)));
        });

        resolutions = resolutions.reverse();
        return resolutions;
    }

    getImageShape = (uperpixel, tileWidth, x_fields, tileHeight, y_fields) => {
        return [uperpixel * tileWidth * x_fields, uperpixel * tileHeight * y_fields];
    }

    getProjection = (code, units, extent) => {
        let projection = new Projection({
            code: code,
            units: units,
            extent: extent,
            metersPerUnit: 0.000001,
            global: true,
            getPointResolution: (resolution, point) => resolution,
        });
        return projection;
    }

    getView = (projection, center, zoom, maxZoom, rotation, hasMaxResolution, maxResolution, minZoom) => {
        if (hasMaxResolution) {
            return new View({
                projection: projection,
                extent: projection.getExtent(),
                center: center,
                zoom: zoom,
                maxResolution: maxResolution,
                maxZoom: maxZoom,
                rotation: rotation
            });
        } else {
            return new View({
                projection: projection,
                extent: projection.getExtent(),
                center: center,
                zoom: zoom,
                maxZoom: maxZoom,
                minZoom: minZoom,
                rotation: rotation
            })
        }
    }

    getTileLayer = (projection, renderMode, resolutions, tileSize, url, wrapX, crossOrigin) => {
        return new TileLayer({
            extent: projection.getExtent(),
            renderMode: renderMode,
            source: new TileImage({
                tileGrid: new TileGrid({
                    extent: projection.getExtent(),
                    origin: [0, projection.getExtent()[3]],
                    resolutions: resolutions,
                    tileSize: tileSize,
                }),
                projection: projection,
                url: url,
                wrapX: wrapX,
                crossOrigin: crossOrigin
            }),
        });
    }

    getImageLayer = (previewUrl, projection, extent) => {
        return new ImageLayer({
            source: new Static({
                url: previewUrl,
                projection: projection,
                imageExtent: extent,
            }),
        });
    }

    getVectorLayer = () => {
        let stroke = new Stroke({ color: '#7CFC00', width: 2 });
        let fill = new Fill({ color: 'red' });

        let iconStyle = new Style({
            image: new RegularShape({
                fill: fill,
                stroke: stroke,
                points: 4,
                radius: 10,
                radius2: 0,
                angle: 0,
            }),
        });

        let iconFeature = new Feature({
            geometry: new Point([])
        });

        iconFeature.setStyle(iconStyle);

        let vectorSource = new VectorSource({
            features: [iconFeature]
        });

        let vectorLayer = new VectorLayer({
            source: vectorSource
        });
        return vectorLayer;
    }

    syncVisitedTilesFromBackend = () => {
        console.log("visited--", this.props.livemode.visitedTiles)
        let url = "/server/devices/" + this.props.livemode.deviceId + "/settings/preview_tuning/fetch_visited_points?slot_id="+this.props.adminState.slotID;
        let visitedTiles = [];  
        axios.get(url).then(res => {
            let coords = JSON.parse(res.data['coords']);
            this.drawTilesOnMap(JSON.parse(res.data['coords']))
            console.log("res--", coords)
            for(let i=0;i<coords.length; i++){
                let nearestTile = getNearestTile(coords[i]['x'], coords[i]['y'], this.state.tileCenters);
                console.log("nearestTile--", nearestTile)
                visitedTiles.push(nearestTile);
            }
        }).then(res => {
            console.log("sync", visitedTiles)
            // then add it to redux and change there
            this.props.dispatch(updateVisitedTiles([visitedTiles, this.props.adminState.slotID]))
            // this.drawTilesOnMap(visitedTiles);
            
        })
    }

    styleFunction = (feature, resolution) => {
        let baseStyleWithText = baseStyle;
        return [
            otherStyle, baseStyleWithText
        ]
    }

    drawTilesOnMap = (coords) => {
        // let tiles_to_color = visitedTiles;
        console.log("slideData--", coords)
        // let colored = {};
        // let tile_data = this.state.tileCenters
        let all_features = []
        if(this.state.slide_data==undefined){
            return;
        }
        
        // code to mark centers of all tiles
        // let tileCenters = this.state.tileCenters;
        // for (let tile in tileCenters){
        //     let centers = tileCenters[tile];
        //     let tile_height = 100;
        //     let tile_width = 100;
        //     let xl = parseFloat(centers[0]-(tile_width/2));
        //     let xr = parseFloat(centers[0]+(tile_width/2));
        //     let yt = parseFloat(centers[1]-(tile_height/2));
        //     let yb = parseFloat(centers[1]+(tile_height/2));
        //     let bounds = [[[xl,yt],[xr,yt],[xr,yb],[xl,yb],[xl,yt]]]
        //     let feature = new Feature({
        //         geometry: new Polygon(bounds),
        //         id: Math.random(),
        //         name: "seen",
        //     });
        //     all_features.push(feature);
        // }
        
        let format = new GeoJSON();

        let mergedFeature = undefined;

        for(let i=0;i<coords.length; i++){
            // if(colored[tiles_to_color[i]]==true){
            //     continue;
            // }
            let tile_height = (this.state.slide_data.tile_height*this.state.slide_data.uperpixel)/this.state.objectiveRatio;
            let tile_width = (this.state.slide_data.tile_width*this.state.slide_data.uperpixel)/this.state.objectiveRatio;
            console.log("tile--", tile_height, tile_width, this.state.slide_data.tile_height, this.state.slide_data.tile_width, this.state.objectiveRatio)
            // colored[tiles_to_color[i]]=true;

            // let tile_height = 1000;
            // let tile_width = 1000;

            let centers = [coords[i]['x'],coords[i]['y']];
            console.log("center", centers)
            let xl = parseFloat(centers[0]-(tile_width/2));
            let xr = parseFloat(centers[0]+(tile_width/2));
            let yt = parseFloat(centers[1]-(tile_height/2));
            let yb = parseFloat(centers[1]+(tile_height/2));
            let bounds = [[[xl,yt],[xr,yt],[xr,yb],[xl,yb],[xl,yt]]]
            // let bounds = [[[627.4560000000056,31205.478399999993],[9328.179199999999,31205.478399999993],[9328.179199999999,36559.76959999999],[627.4560000000056,36559.76959999999],[627.4560000000056,31205.478399999993]]]   
            console.log("bounds--", bounds)
            let feature = new Feature({
                geometry: new Polygon(bounds),
                id: Math.random(),
                name: "seen",
            });
            if(mergedFeature == undefined){
                mergedFeature = feature;
            }else{
                mergedFeature = format.readFeature(turf.union(format.writeFeatureObject(mergedFeature), format.writeFeatureObject(feature)));
            }
            console.log("feature", feature)
            all_features.push(feature);
        }

        // this.props.slidemap.addLayer(this.vector);
        if(this.props.adminState.liveModePreviewVectorLayer!=undefined && mergedFeature != undefined){
            let zIndex = (this.state.slide_data.x_fields * 10000) + this.state.slide_data.y_fields + 1000000000;
            this.props.adminState.liveModePreviewVectorLayer.setZIndex(zIndex);
            console.log("setSource", all_features)
            this.props.adminState.liveModePreviewVectorLayer.setSource(
                new VectorSource({
                    // features: all_features,
                    features: [mergedFeature],
                    wrapX: false,
                })
            )
            this.props.adminState.liveModePreviewVectorLayer.setStyle(this.styleFunction);
        }
    }

    moveToPixelAndFocus = (evt) => {
        console.log("--- move to pixel and focus");
        let nearestTile = getNearestTile(evt.coordinate[0], evt.coordinate[1], this.state.tileCenters);
        let tile_x = nearestTile.split('_')[0];
        let tile_y = nearestTile.split('_')[1];
        let global_x = evt.coordinate[0];
        let global_y = evt.coordinate[1];
        let tile_center_coords = this.state.tileCentersTopOrigin[nearestTile]
        let x_dist_from_center = global_x - tile_center_coords[0];
        let global_y_ = this.state.baseCanvasY - global_y
        let y_dist_from_center = global_y_ - tile_center_coords[1];
        
        x_dist_from_center = x_dist_from_center/this.state.slide_data.uperpixel;
        y_dist_from_center = y_dist_from_center/this.state.slide_data.uperpixel;

        this.props.dispatch(updateGlobalCoordinates([global_x, global_y]));
        let nearestTileNew = "x"+tile_x+"y"+tile_y;
        console.log("astileCentres coords ", tile_center_coords, this.state.slide_data.uperpixel)
        console.log("astileCenters",tile_x, tile_y, nearestTileNew);
        if (!this.props.livemode.imageFetching && !this.props.adminState.live_mode_action && this.props.livemode.accessToLiveMode && !this.props.livemode.useLiveModeInViewMode && !this.state.isDrawing) {
            if (this.props.adminState.main_light) {
                console.log("move to pixel ", evt);
                let xDistance;
                let yDistance;
                if (this.props.adminState.numberOfSlots > 1) {
                    xDistance = ((this.state.slide_data.x_fields - 1) - parseInt(nearestTile.split('_')[0])) * this.state.slide_data.x_step;
                    yDistance = ((this.state.slide_data.y_fields - 1) - parseInt(nearestTile.split('_')[1])) * this.state.slide_data.y_step;
                    console.log("steps ", nearestTile, xDistance, yDistance, this.state.slide_data.x_fields, this.state.slide_data.x_step);
                } else {
                    xDistance = parseInt(nearestTile.split('_')[0]) * this.state.slide_data.x_step;
                    yDistance = parseInt(nearestTile.split('_')[1]) * this.state.slide_data.y_step;
                }
                let selectedlong;
                let selectedlat;
                if (this.props.adminState.numberOfSlots > 1) {
                    selectedlong = yDistance + evt.coordinate[1] - this.state.tileCenters[nearestTile][1];
                    selectedlat = xDistance + this.state.tileCenters[nearestTile][0] - evt.coordinate[0];

                    console.log("deltas y ", evt.coordinate[1] - this.state.tileCenters[nearestTile][1]);
                    console.log("deltas x ", this.state.tileCenters[nearestTile][0] - evt.coordinate[0]);

                    selectedlong = yDistance;
                    selectedlat = xDistance;

                } else {
                    selectedlong = yDistance + this.state.tileCenters[nearestTile][1] - evt.coordinate[1];
                    selectedlat = xDistance + evt.coordinate[0] - this.state.tileCenters[nearestTile][0];
                }
                console.log("Check if this is being called ", nearestTile)
                let url = "/server/devices/" + this.props.livemode.deviceId + "/settings/preview_tuning/move_to_pixel_and_focus?slot_id=" +
                    this.props.adminState.slotID + "&x=" + Math.trunc(selectedlat) + "&y=" + Math.trunc(selectedlong) +
                    "&doDrop=" + this.props.adminState.doDropDistanceAF + "&doAF=" + this.props.adminState.doAutoFocus +
                    "&zStackLevels=" + (this.props.livemode.zStackLevels * 2) + "&zStackStep=" + this.props.livemode.stepSizeZStack + 
                    "&accessCode=" + cookie.loadAll().livemode_access_code + "&nearestCellKey=" + nearestTileNew + "&global_pixelX=" + global_x
                    + "&global_pixelY=" + global_y;

                // this.props.dispatch(updateCurrentMapPosition({x: Math.trunc(selectedlat), y: Math.trunc(selectedlong)}));
                let newCurrentMapPosition = Object.assign({}, this.props.livemode.currentMapPosition);
                newCurrentMapPosition[this.props.adminState.slotID] = {x: Math.trunc(evt.coordinate[0]), y: Math.trunc(evt.coordinate[1])};
                
                let errorMessage = '';
                if (this.props.adminState.doDropDistanceAF) {
                    errorMessage = liveModeLoadingMessages.DROP_DISTANCE_AF;
                } else if (this.props.adminState.doAutoFocus) {
                    errorMessage = liveModeLoadingMessages.AUTO_FOCUS;
                } else {
                    errorMessage = liveModeLoadingMessages.MOVE;
                }

                // let singleImageUrl = '/server/devices/' + this.props.livemode.deviceId + '/get_live_updated_image?scale=2.1&zoom=' + this.props.liveView.zoom +
                //     '&x=' + this.props.liveView.x + '&y=' + this.props.liveView.y + '&liveMode=true' + '&blur=false' + "&accessCode=" + cookie.loadAll().livemode_access_code;

                let partsOfUrl = "api~stage~move_and_focus";
                let singleImageUrl = `/server/scano_text/` + this.props.livemode.deviceId + `/` + partsOfUrl;
                if (this.props.adminState.slotID != -1) {
                    singleImageUrl += '?slotID=' + this.props.adminState.slotID;
                } else {
                    singleImageUrl += '?slotID=';
                }
                singleImageUrl += "&x=0&y=0&z=0&doDrop=" + this.props.adminState.doDropDistanceAF +
                    "&doAF=" + this.props.adminState.doAutoFocus + "&numZStack=" + (this.props.livemode.zStackLevels * 2) + 
                    "&zStackStep=" + this.props.livemode.stepSizeZStack + 
                    "&timestamp=" + Date.now();
                this.props.dispatch(getLiveImageMoveToPixelAndFocus(evt, url, singleImageUrl, errorMessage, this.props.adminState.takeZStack, 
                    this.props.adminState.doDropDistanceAF, this.props.adminState.previewImageExtent, this.props.adminState.areaVisited, 
                    this.props.adminState.liveModePreviewVectorLayer, this.props.adminState.slotID, this.props.livemode.deviceId, 
                    this.props.adminState.previewCamera, this.props.adminState.numberOfSlots, selectedlat, selectedlong, 
                    parseInt(nearestTile.split('_')[1]), xDistance, yDistance, this.state.tileCenters[nearestTile], this.props.livemode.lastBound, newCurrentMapPosition));
            } else {
                message.error("Please switch on the main light.", 2.5);
            }
        } else {
            // message.error("Action ongoing...");
        }
    }

    updateLiveModeAction = (value, message) => {
        this.props.dispatch(updateLiveModeAction(value, message));
    }

    updateLayers = () => {
        recreateMapLayers(this.state.slidemap, this.state.viewLevelsInfo, this.state.slide_data, this.state.imageInfo, this.state.projection, this.state.resolutions, this.state.image_pos_data, this.state.tileSize, (obj) => {
            this.setState(obj)
        });
    }

    onloadRunner = (slotID, morphleID) => {
        console.log("err-morphle_id", morphleID)
        if(morphleID == undefined) return;
        this.mainLightOn();

        if (this.props.adminState.previewCamera == previewCameraType.FOUR_X) {
            console.log("err-if");
            this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.TAKING_PREVIEW));
            getSlideAndInitialiseMapState(morphleID, (obj) => {
                this.setState(obj)
              }, this.updateLiveModeAction, this.moveToPixelAndFocus, this.state.slidemap, this.updateLayers);
        } else {
            console.log("err-else");
            let imageShape = [this.image.width, this.image.height];
            let extent = [0, 0, this.image.width, this.image.height];
            this.props.dispatch(updatePreviewImageExtent(extent));
            let projection = this.getProjection('preview-image', 'pixels', extent);
            let vectorLayer = this.getVectorLayer();
            this.props.dispatch(updateLiveModePreviewVectorLayer(vectorLayer));
            let previewUrl = '/dev-ssd/last_preview/uncropped.jpg?time=' + Date.now();
            let layer = this.getImageLayer(previewUrl, projection, extent);
            let view = this.getView(projection, getCenter(extent), 2, 8, ((180 * Math.PI) / 180), false, null, 1);

            if (this.state.slidemap instanceof Map) {
                const layers = [...this.state.slidemap.getLayers().getArray()];
                layers.forEach((layer) => this.state.slidemap.removeLayer(layer));
                this.state.slidemap.setLayerGroup(new LayerGroup({
                    layers: [layer, vectorLayer],
                }));
                this.state.slidemap.setView(view);
            } else {
                let slidemap = new Map({
                    controls: [],
                    layers: [layer, vectorLayer],
                    target: 'map',
                    view: view,
                });
                let dblClickInteraction;
                slidemap.getInteractions().getArray().forEach(function (interaction) {
                    if (interaction instanceof DoubleClickZoom) {
                        dblClickInteraction = interaction;
                    }
                });
                slidemap.removeInteraction(dblClickInteraction);
                slidemap.on('click', (evt) => this.moveToPixelAndFocus(evt, imageShape));
                this.setState({
                    slidemap,
                });
            }
            if (this.props.adminState.useStreakFocus) {
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.TAKING_PREVIEW));
            }
        }
    }

    getNumberOfSlots = () => {
        this.setState({
            initError: false,
            errorMessage: '',
        });
        this.props.dispatch(updateLiveModeAction(true, 'Loading...'));
        let url = "/server/devices/" + this.props.livemode.deviceId + "/manualmode/device_details";
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    let numberOfSlots = response.data.number_of_slots;
                    if (response.data.number_of_slots == 2) {
                        numberOfSlots = 1;
                    }
                    // if (response.data.number_of_slots > 0) {
                    //     numberOfSlots = 1;
                    // }
                    this.setState({
                        deviceIP: response.data.device_ip,
                        numberOfSlots,
                    });
                    this.props.dispatch(updateDeviceIP(response.data.device_ip));
                    this.props.dispatch(updateNumberOfSlots(numberOfSlots));
                    if (this.props.livemode.takePreviewInLiveMode && !this.props.livemode.useLiveModeInViewMode) {
                        this.getPreview(this.props.adminState.slotID);
                    }
                    // if(numberOfSlots == 1 || numberOfSlots == 2) {
                    //     this.props.dispatch(updateSlotID(0));
                    //     this.getPreview(0);
                    // } else {
                    //     this.props.dispatch(updateLiveModeAction(false, 'Loading...'));
                    // }
                    this.props.dispatch(updateLiveModeAction(false, 'Loading...'));
                }
                else {
                    console.log(response);
                    message.error("Not able to get number of slots!!", 2.5);
                    this.setState({
                        initError: true,
                        errorMessage: 'Not able to get number of slots!!',
                    });
                    this.props.dispatch(updateLiveModeAction(false, 'Loading...'));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to get number of slots!!", 2.5);
                this.setState({
                    initError: true,
                    errorMessage: 'Not able to get number of slots!!',
                });
                this.props.dispatch(updateLiveModeAction(false, 'Loading...'));
            })
    }

    changeSlot = (e) => {
        if (e.nativeEvent.type === 'click' && e.nativeEvent.clientX !== 0 && e.nativeEvent.clientY !== 0) {
            this.props.dispatch(updateSlotID(e.target.value));
        }

    }

    changeSlotClickEvent = (e, slot) => {
        if (e.nativeEvent.type === 'click' && e.nativeEvent.clientX !== 0 && e.nativeEvent.clientY !== 0) {
            this.props.dispatch(updateSlotID(slot));
            this.getPreview(slot);
        }

    }

    getPreview = (slot) => {
        if (slot >= 0) {
            this.previewStartTime = Date.now();
            this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.TAKING_PREVIEW));
            this.props.dispatch(updateDoDropDistanceAF(true));
            this.props.dispatch(updateVisitedArea([]));
            this.props.dispatch(updateLiveView(undefined));
            this.props.dispatch(updateLiveViewUrl(undefined));
            this.setState({
                isDrawing: false,
            });
            if(this.state.slidemap instanceof Map) {
                this.state.slidemap.removeInteraction(this.state.draw);
                this.state.slidemap.removeInteraction(this.state.snap);
                const layers = [...this.state.slidemap.getLayers().getArray()];
                layers.forEach((layer) => this.state.slidemap.removeLayer(layer));
            }
            let partsOfUrl = "api~preview~uncropped_livemode";
            let url = `/server/scano/` + this.props.livemode.deviceId + `/` + partsOfUrl + '/?slot_id=' + slot + '&time=' + Date.now() + `&accessCode=` + cookie.loadAll().livemode_access_code;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    console.log("err-here200")
                    if (this.props.adminState.previewCamera == previewCameraType.FOUR_X) {
                        // this.onloadRunner();
                    }
                    // } else {
                    //     this.image.src = '/dev-ssd/last_preview/uncropped.jpg?time=' + Date.now();
                    // }
                }
                else if (response.status === 204) {
                    console.log(response);
                    // message.error("Access Revoked!!", 2.5);
                    this.props.dispatch(updateAccessRevoked(true));
                    this.props.dispatch(updateAccessToLiveMode(false));
                    this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.TAKING_PREVIEW));
                }
                else if (response.status === 206) {
                    if (this.props.adminState.previewCamera == previewCameraType.FOUR_X) {
                        let loaded_slides_live_mode = ((this.props.device || {}).scanner_health || {}).loaded_slides_live_mode;
                        console.log("err-loaded", loaded_slides_live_mode[slot])
                        this.onloadRunner(slot, loaded_slides_live_mode[slot]);
                    } else {
                        this.image.src = '/dev-ssd/last_preview/uncropped.jpg?time=' + Date.now();
                    }
                    let partsOfUrl = "api~health~livemode";
                    let url = `/server/scano_text/` + this.props.livemode.deviceId + `/` + partsOfUrl + `?update_code=` + this.props.livemode.latestLiveModeDataTimestamp + `&time=` + Date.now();
                    axios.get(url, { headers: { Authorization: AuthHeader() } })
                        .then(res => {
                            if (res.status === 200) {
                                this.props.dispatch(updateLiveModeStatus(res.data, slot, this.props.adminState.liveModePreviewVectorLayer, this.props.livemode.useLiveModeInViewMode, true));
                            }
                            else {
                                console.log(response);
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        })
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to take preview!!", 2.5);
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.TAKING_PREVIEW));
            })
        }
    }

    takeAllPreviews = () => {
        if (!this.props.adminState.live_mode_action) {
            this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.TAKING_PREVIEW));
            this.props.dispatch(updateDoDropDistanceAF(true));
            this.props.dispatch(updateVisitedArea([]));
            let partsOfUrl = "api~preview~get_all_previews_livemode";
            let url = `/server/scano/` + this.props.livemode.deviceId + `/` + partsOfUrl + '/?time=' + Date.now() + `&accessCode=` + cookie.loadAll().livemode_access_code;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                }
                else if (response.status === 204) {
                    console.log(response);
                    // message.error("Access Revoked!!", 2.5);
                    this.props.dispatch(updateAccessRevoked(true));
                    this.props.dispatch(updateAccessToLiveMode(false));
                    this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.TAKING_PREVIEW));
                }
                else if (response.status === 206) {
                    this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.TAKING_PREVIEW));
                    message.info("All previews already taken.", 2.5);
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to take preview!!", 2.5);
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.TAKING_PREVIEW));
            })
        } else {
            message.error("Action ongoing...");
        }
    }

    ejectSlide = () => {
        if (!this.props.adminState.live_mode_action) {
            this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.EJECTING_SLIDE));
            let partsOfUrl = "api~scan~eject_livemode";
            let url = `/server/scano/` + this.props.livemode.deviceId + `/` + partsOfUrl + '?background=false' + `&accessCode=` + cookie.loadAll().livemode_access_code;
            if (this.props.adminState.previewCamera == previewCameraType.FOUR_X) {
                partsOfUrl = "api~stage~eject_slide_live_mode";
                url = `/server/scano/` + this.props.livemode.deviceId + `/` + partsOfUrl + '?&accessCode=' + cookie.loadAll().livemode_access_code;
            }
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    if (response.status === 200) {
                        this.props.dispatch(updateSlotID(-1));
                        this.props.dispatch(updateLiveView(undefined));
                        this.props.dispatch(updateLiveViewUrl(undefined));
                        this.props.dispatch(updateTakeZStack(true));
                        this.props.dispatch(updateDoAutoFocus(false));
                        this.props.dispatch(updateAtLeastOneImageFetched(false));
                        this.props.dispatch(updateUseCoverSlipObjective(true));
                        this.props.dispatch(updateCurrentMapPosition({}));
                        this.props.dispatch(updateTileCenters({}));
                        this.setState({
                            loaded: false,
                        });
                        const layers = [...this.state.slidemap.getLayers().getArray()];
                        layers.forEach((layer) => this.state.slidemap.removeLayer(layer));
                        message.success("Successfully ejected slide.", 2.5);
                    }
                    else {
                        console.log(response);
                        // message.error("Access Revoked!!", 2.5);
                        this.props.dispatch(updateAccessRevoked(true));
                        this.props.dispatch(updateAccessToLiveMode(false));
                    }
                    this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.EJECTING_SLIDE));
                })
                .catch(err => {
                    console.log(err);
                    message.error("Failed to eject slide!!", 2.5);
                    this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.EJECTING_SLIDE));
                })
        } else {
            message.error("Action ongoing...");
        }
    }

    loadSlide = (slot) => {
        if (!this.props.adminState.live_mode_action) {
            this.props.dispatch(updateTakePreviewInLiveMode(true));
            this.props.dispatch(updateSlotID(slot));
            // this.getPreview(slot);
            this.setState({
                loaded: true,
            });
        } else {
            if(this.props.livemode.currentPreview != -1) {
                let partsOfUrl = "api~preview~queue_preview";
                let url = `/server/scano/` + this.props.livemode.deviceId + `/` + partsOfUrl + '/?time=' + Date.now() + `&slot_id=` + slot +
                `&accessCode=` + cookie.loadAll().livemode_access_code;
                axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    if (response.status === 200) {

                    }
                    else {
                        console.log(response);
                        // message.error("Access Revoked!!", 2.5);
                        this.props.dispatch(updateAccessRevoked(true));
                        this.props.dispatch(updateAccessToLiveMode(false));
                    }
                })
                .catch(err => {
                    console.log(err);
                    message.error("Not able to schedule preview!!", 2.5);
                })
            } else {
                message.error("Action ongoing...");
            }
        }
    }

    resetVisitedArea = () => {
        if (!this.props.adminState.live_mode_action) {
            let partsOfUrl = "api~settings~reset_visited_area";
            let url = `/server/scano/` + this.props.livemode.deviceId + `/` + partsOfUrl + '/?time=' + Date.now() + `&slotID=` + this.props.adminState.slotID +
            `&accessCode=` + cookie.loadAll().livemode_access_code;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.props.adminState.liveModePreviewVectorLayer.setSource(new VectorSource({
                        features: [],
                        wrapX: false
                    }));
                    this.props.dispatch(updateVisitedArea([]));
                }
                else {
                    console.log(response);
                    // message.error("Access Revoked!!", 2.5);
                    this.props.dispatch(updateAccessRevoked(true));
                    this.props.dispatch(updateAccessToLiveMode(false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to take preview!!", 2.5);
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.TAKING_PREVIEW));
            })
        } else {
            message.error("Action ongoing...");
        }
    }

    toggleDrawFeature = () => {
        if (this.state.slidemap instanceof Map) {
            if (this.state.isDrawing) {
                this.state.slidemap.removeInteraction(this.state.draw);
                this.state.slidemap.removeInteraction(this.state.snap);
            } else {
                this.state.slidemap.addInteraction(this.state.draw);
                this.state.slidemap.addInteraction(this.state.snap);
            }
            this.setState({
                isDrawing: !this.state.isDrawing,
            });
        } else {
            message.error("Please take a preview first...", 2.5);
        }
    }

    toggleAnnoLayer = () => {
        if (this.state.slidemap instanceof Map) {
            if (this.state.showAnnoLayer) {
                this.state.annotationLayer.setVisible(false);
            } else {
                this.state.annotationLayer.setVisible(true);
            }
            this.setState({
                showAnnoLayer: !this.state.showAnnoLayer,
            });
        } else {
            message.error("Please take a preview first...", 2.5);
        }
    }

    getCameraAppButton = (text, onClickMethod) => {
        return (
            <Button size="small" type="primary" onClick={onClickMethod}>
                {text}
            </Button>
        )
    }

    getLoadAndEjectComponent = () => {
        return (
            <div>
                <Row>
                    <Col span={24} style={{textAlign: 'center'}}>
                        <Row style={{margin: 4}}>
                            {this.getSlotButtons()}
                        </Row>
                        {this.state.numberOfSlots > 1 ?
                            <Row>
                                <Button onClick={this.takeAllPreviews} className="eject-button unselected-style">
                                    <Row style={{fontSize: 10}}>
                                        TAKE ALL PREVIEWS
                                    </Row>
                                </Button> 
                            </Row> : null
                        }
                        {(cookie.loadAll().superuser == 'true' || cookie.loadAll().staff == 'true') ?
                            <Row>
                                <Button onClick={this.ejectSlide} className="eject-button unselected-style">
                                    <Row style={{fontSize: 10}}>
                                        EJECT CASSETTE
                                    </Row>
                                </Button> 
                            </Row> : null
                        }
                    </Col>
                </Row>
            </div>
        );
    }

    getSlotOptions = () => {
        let options = [];
        for (let i = 0; i < this.state.numberOfSlots; i++) {
            options.push(
                <Option style={{ fontSize: '1.3em', color: 'white', background: '#3a3a3a' }}
                    value={i}
                >
                    {"Load Slide " + (i + 1)}
                </Option>
            )
        }
        return options;
    }


    
    getSlotButtons = () => {
        let buttons = [];
        let buttonsGroup = [];
        let commonStyle = {width: '2.5em', height: '2.5em', borderRadius: '0em'};
        let borderRadius = '0.2em';
        let stylepointerEvent = !this.props.livemode.accessToLiveMode || this.props.livemode.useLiveModeInViewMode ? {pointerEvents: 'none'} : {};
        if (this.state.numberOfSlots == 1) {
            buttonsGroup.push(
                <Button onClick={() => this.loadSlide(0)} 
                    style={!this.props.livemode.accessToLiveMode || this.props.livemode.useLiveModeInViewMode ?
                        { pointerEvents: 'none'} : {}}
                    className={this.props.adminState.slotID == 0 ? "load-button selected-style" : "eject-button unselected-style"}
                >
                    <Row style={{fontSize: 10}}>
                        LOAD SLIDE
                    </Row>
                </Button>
            );
        } else {
            for (let i = 0; i < this.state.numberOfSlots; i++) {
                let style = Object.assign({}, commonStyle);
                
                if (i == this.state.numberOfSlots / 2) {
                    buttonsGroup.push(<Row>{buttons}</Row>);
                    buttons = [];
                }
                if (i == 0) {
                    style.borderTopLeftRadius = borderRadius;
                } else if (i + 1 == this.state.numberOfSlots / 2) {
                    style.borderTopRightRadius = borderRadius; 
                } else if (i == this.state.numberOfSlots / 2) {
                    style.borderBottomLeftRadius = borderRadius;  
                } else if (i + 1 == this.state.numberOfSlots) {
                    style.borderBottomRightRadius = borderRadius;
                }

                let className = "";
                if (this.props.livemode.currentPreview == -1 && this.props.livemode.scheduledPreviews.length == 0 && this.props.livemode.previewsDone.includes(i) && this.props.adminState.slotID == i) {
                    className = "selected-slot-style side-panel-icon side-panel-icon-open selected-style";
                } else if (i == this.props.livemode.currentPreview) {
                    className = "side-panel-icon currently-ongoing-preview selected-style";
                } else if (this.props.livemode.currentPreview != -1 && this.props.livemode.scheduledPreviews.length != 0 && this.props.livemode.scheduledPreviews.includes(i)) {
                    className = "side-panel-icon scheduled-preview selected-style";
                } else if (this.props.livemode.previewsDone.includes(i)) {
                    className = "side-panel-icon side-panel-icon-open selected-style";
                } else {
                    className = "side-panel-icon unselected-style";
                }

                buttons.push(
                    // <Tooltip title={"Load Slide " + (i + 1)}>
                        <Button onClick={() => this.loadSlide(i)} className={className}
                            style={style}>
                            <Row style={{fontSize: 10}}>
                                {i + 1}
                            </Row>
                        </Button>
                    // </Tooltip>
                )
            }
            buttonsGroup.push(buttons);
            buttons = [];
        }
        return buttonsGroup;
    }


    render() {
        console.log("visited--", this.props.adminState)
        let slotsButton = [];

        for (let i = 0; i < this.state.numberOfSlots; i++) {
            slotsButton.push(
                <Radio.Button onClick={(e) => this.changeSlotClickEvent(e, i)} value={i}>{"Load Slide " + (i + 1)}</Radio.Button>
            )
        }

        return (
            <div className="full-height">
                {/* {(cookie.loadAll().superuser == 'true' || cookie.loadAll().staff == 'true') ? */}
                {!(this.props.livemode.useLiveModeInViewMode) ?
                    <Row style={{ width: '100%', height: (window.visualViewport.height * 0.25) }}>
                        <Col span={22} offset={1}>
                            <Row style={{marginTop: '2em'}}>
                                <Col span={24} style={{textAlign: 'center'}}>
                                    <span style={{color: '#d9d9d9', fontStyle: 'italic'}}>{this.state.numberOfSlots > 1 ? "click to select slot" : ""}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    {this.getLoadAndEjectComponent()}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    : 
                    <Row style={{ width: '100%', height: (window.visualViewport.height * 0.1) }}></Row>
                }
                <Row style={!(this.props.livemode.useLiveModeInViewMode) ? { width: '100%', height: (window.visualViewport.height * 0.55) } : { width: '100%', height: (window.visualViewport.height * 0.6) }}>
                    <Col span={24} style={{height: '100%'}}>
                        <div id="map" style={{height: '100%'}}></div>
                    </Col>
                </Row>
                {this.state.slide_data != undefined && this.props.livemode.currentPreview == -1 ?
                    <Row style={{ width: '100%', height: (window.visualViewport.height * 0.1) }}>
                        <Col span={24} style={{height: '100%', textAlign: 'center', marginTop: '-8%'}}>
                            {getLabelComponentRotated(this.state.slide_data, false, false)}
                        </Col>
                    </Row> : null
                }
                {this.props.adminState.slotID >= 0 ?
                    <Row key="reset_visited_area" style={(cookie.loadAll().superuser == 'true' || cookie.loadAll().staff == 'true') ? { width: '100%', height: (window.visualViewport.height * 0.05) } : { width: '100%', height: (window.visualViewport.height * 0.15) }}>
                        <Col span={9} offset={1} style={{textAlign: 'center'}}>
                            <br />
                            <Button onClick={this.resetVisitedArea} style={!this.props.livemode.accessToLiveMode || this.props.livemode.useLiveModeInViewMode ?
                                    {width: '100%', pointerEvents: 'none'} : {width: '100%'}}  className="reset-visited-area-button unselected-style">
                                <Row style={{fontSize: 10}}>
                                    RESET VISITED AREA
                                </Row>
                            </Button>
                        </Col>
                        <Col span={9} offset={1} style={{textAlign: 'center'}}>
                            <br />
                            <Button onClick={this.toggleAnnoLayer} style={!this.props.livemode.accessToLiveMode || this.props.livemode.useLiveModeInViewMode ?
                                    {width: '100%', pointerEvents: 'none'} : {width: '100%'}}  className="reset-visited-area-button unselected-style">
                                <Row style={{fontSize: 10}}>
                                    {this.state.showAnnoLayer ? "HIDE ANNOTATIONS" : "SHOW ANNOTATIONS"}
                                </Row>
                            </Button>
                        </Col>
                        <Col span={2} offset={1} style={{textAlign: 'center'}}>
                            <br />
                            <Button onClick={this.toggleDrawFeature} style={!this.props.livemode.accessToLiveMode || this.props.livemode.useLiveModeInViewMode ?
                                    {width: '100%', pointerEvents: 'none'} : {width: '100%'}}  className={this.state.isDrawing ? "reset-visited-area-button side-panel-icon-open selected-style" : "reset-visited-area-button unselected-style"}>
                                <Row style={{fontSize: 10, marginTop: 4, marginLeft: 0}}>
                                    <FaPencilAlt />
                                </Row>
                            </Button>
                        </Col>
                    </Row> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        adminState: state.adminUrlReducer,
        liveView: state.liveViewReducer,
        livemode: state.livemodeReducer,
        device: state.deviceStatusReducer[state.livemodeReducer.deviceId]
    }
}

export default connect(mapStateToProps)(PreviewLiveMode);
