import * as enhancementKeys from '../component/neoviewer/apps/settings/enhancement_keys'
import cookie from "react-cookies";
import {globalUrlPrefix, specimenType, viewerType} from "./const";
import {historyMP} from "../helper/history";

export function sleep(time) {
	return new Promise((resolve) => setTimeout(resolve, time));
}

export const checkIfMobile = () => {
	return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
		|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(navigator.userAgent.substr(0, 4));

}


export function getFormattedLength(value) {
	let suffix;
	if (value > 1000) {
		suffix = 'mm';
		value /= 1000;
	} else if (value > 1000000) {
		suffix = 'm';
		value /= 1000000;
	} else {
		suffix = "um";
	}
	return (value.toFixed(2) + "" + suffix)
}

export function getFormattedArea(value) {
	let suffix;
	if (value > 10000) {
		suffix = 'sq.mm';
		value *= 0.000001;
	} else if (value > 1000000000000) {
		suffix = 'sq.m';
		value /= 1000000000000;
	} else {
		suffix = "sq.um";
	}
	return (value.toFixed(3) + "" + suffix)
}


export function randomstring(L) {
	var s = '';
	var randomchar = function () {
		var n = Math.floor(Math.random() * 62);
		if (n < 10) return n; //1-10
		if (n < 36) return String.fromCharCode(n + 55); //A-Z
		return String.fromCharCode(n + 61); //a-z
	}
	while (s.length < L) s += randomchar();
	return s;
}


export function otpGenerator(L) {
	var otp = '';
	var randomint = function () {
		return Math.floor(Math.random() * 10);
	}
	while (otp.length < L) otp += randomint();
	return otp;
}

export function copyStringToClipboard(str) {
	// Create new element
	var el = document.createElement('textarea');
	// Set value (string to be copied)
	el.value = str;
	// Set non-editable to avoid focus and move outside of view
	el.setAttribute('readonly', '');
	el.style = { position: 'absolute', left: '-9999px' };
	document.body.appendChild(el);
	// Select text inside element
	el.select();
	// Copy text to clipboard
	document.execCommand('copy');
	// Remove temporary element
	document.body.removeChild(el);
}

export function convertSettingsToFilter(settings) {
	if (!settings) return "";
	let cssStyle = ""
	for (let key in enhancementKeys) {
		let enhancementKey = enhancementKeys[key];
		if (enhancementKey.id in settings)
			cssStyle += enhancementKey.cssKey + "(" +
				(settings[enhancementKey.id] * enhancementKey.multiplier / enhancementKey.max) + enhancementKey.unit + ") ";
	}
	return cssStyle;
}


export function getPrefixedUrl(url, slide) {
	let prefix;
	if (cookie.loadAll().deployment_mode === 'cloud') {
		prefix = "https://" + slide.bucket_name + ".s3." + slide.region_name + ".amazonaws.com/"
	} else if (slide.status === 12) {
		prefix = "/scan-hdd/" + slide.scandrive_id + "/" + slide.bucket_name + "/";
	} else {
		prefix = "/scan-ssd/";
	}
	return prefix + url;
}

export const getSuffixUrl = (slide_data, isZoomForTiled, zStackLevel) => {
  let urlSuffix;
  if (isZoomForTiled)
    if (zStackLevel == null || zStackLevel === 0) {
      urlSuffix = `${slide_data.path}tiled`
    }  else {
      urlSuffix = `${slide_data.path}stack/S${zStackLevel}`
    }
  else if (slide_data.specimen_type === "blood"){
    urlSuffix = `${slide_data.path}tiled/{z}/x{x}y{y}.` + slide_data.img_type;
  }
  else{
    urlSuffix = `${slide_data.path}stitched/{z}/x{x}y{y}.` + slide_data.img_type;
  }
  return urlSuffix
}

export function getPreviewUrl(url, slide) {
	let prefix;
	if (slide.status === 12) {
		prefix = "/scan-hdd/" + slide.scandrive_id + "/";
	}
	else {
		prefix = "/scan-ssd/";
	}
	return prefix + url;
}

export function generateLiveModePrefixCode () {
	return Math.floor(Math.random() * 9000) + 1000;
}

export function generateLiveModeSuffixCode() {
	let randomNames = ["Alpha", "Beta", "Gamma", "Delta", "Phi", "Tilda", "Epsilon", "Kappa", "Lambda"];
	let randInt = parseInt((Math.random() * randomNames.length));
	let randInt2 = parseInt((Math.random() * randomNames.length * 20));
	return randomNames[randInt] +  Date.now() + randInt2;
}

export function fullColorHex(r, g, b) {
	var red = rgbToHex(r);
	var green = rgbToHex(g);
	var blue = rgbToHex(b);
	return "#" + red + green + blue;
}

export function rgbToHex(rgb) {
	var hex = Number(rgb).toString(16);
	if (hex.length < 2) {
		hex = "0" + hex;
	}
	return hex;
}

export function hsvToRgb(h, s, v) {
	let r, g, b, i, f, p, q, t;
	if (arguments.length === 1) {
		s = h.s; v = h.v; h = h.h;
	}
	i = Math.floor(h * 6);
	f = h * 6 - i;
	p = v * (1 - s);
	q = v * (1 - f * s);
	t = v * (1 - (1 - f) * s);
	switch (i % 6) {
		case 0: r = v; g = t; b = p; break;
		case 1: r = q; g = v; b = p; break;
		case 2: r = p; g = v; b = t; break;
		case 3: r = p; g = q; b = v; break;
		case 4: r = t; g = p; b = v; break;
		case 5: r = v; g = p; b = q; break;
		default: r = 0; g = 0; b = 0; break;
	}
	return {
		r: Math.round(r * 255),
		g: Math.round(g * 255),
		b: Math.round(b * 255)
	};
}

export function getPosString(pos) {
	return "x" + pos["xPosition"] + " y" + pos['yPosition'] + " z" + pos['zPosition'];
}

export const linkViewer = (slide) => {
	if ((slide.specimen_type === "bone_marrow" || slide.specimen_type === specimenType.BLOOD.toLowerCase()) &&
		slide.viewer_type !== viewerType.TISSUE) {
		window.location.href = "/" + globalUrlPrefix + "/blood-viewer/" + slide.id;
	}
	else if (slide.only_tiling) {
		window.location.href = "/server/v3/tissue/" + slide.morphle_id + "/tiling/";
	} else {
		window.location.href = `/${globalUrlPrefix}/` +
			`${(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).viewer}/${slide.id}`;
	}
}

export const linkViewerNewTab = (slide) => {
	if ((slide.specimen_type === "bone_marrow" || slide.specimen_type === specimenType.BLOOD.toLowerCase()) &&
		slide.viewer_type !== viewerType.TISSUE){
		openInNewTab("/" + globalUrlPrefix + "/blood-viewer/" + slide.id)
	}
	else if (slide.only_tiling) {
		openInNewTab("/server/v3/tissue/" + slide.morphle_id + "/tiling/");
	} else {
		openInNewTab(`/${globalUrlPrefix}/${(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).viewer}/` +
			slide.id);
	}
};

export const openInNewTab = (url) => window.open(url, '_blank')

export const openEndPoint = (endpoint, newTab) =>
	newTab ? openInNewTab(`/${globalUrlPrefix}${endpoint}`) :
		historyMP.push(`/${globalUrlPrefix}${endpoint}`);

export const URLify = (string) => string.trim().replace(/\s/g, '%20');

export const limitString = (string, maxCharacters) =>
	`${string.slice(0, maxCharacters)}${string.length > maxCharacters - 3 ? '...' : ''}`
