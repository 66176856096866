import React, { Component } from 'react';
import { connect } from "react-redux";
import Annotation from "react-image-annotation";
import axios from 'axios';
import {Link} from 'react-router-dom';
import cookie from "react-cookies";
import { Spin,  Progress, Row, Col , Button, Tooltip} from 'antd';
import { bloodClass, bloodImageProperties } from '../utils/const';
import {
    LeftCircleOutlined,
    RightCircleOutlined
} from '@ant-design/icons';
import { Empty } from 'antd';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
    ZoomOutOutlined,
    ZoomInOutlined,
    CompressOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined,
    LoadingOutlined,
} from '@ant-design/icons';

var querystring = require('querystring');


export class Field100xView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currZStackIndex:'',
            prevZstackIndex:'',
            zStacks: [],
            zStackStep: '',
            resetOnFirstLoad: true,
            loadingImage: false,
            annotations: [],
            pageRefresh: false,
        }
        this.imageHolder = React.createRef();
        document.addEventListener('keydown', this.pressShiftUp)
        document.addEventListener('keydown', this.pressShiftDown)
    }

    pressShiftUp = (event) => {
        if(event.shiftKey && event.keyCode === 38 ) {
            this.higherZstack();
        }
    }
    pressShiftDown = (event) => {
        if(event.shiftKey && event.keyCode === 40) {
            this.lowerZstack();
        }
    }

    async reposition () {
        if(this.props.mode === bloodClass.WBC || this.props.mode === bloodClass.RBC || this.props.mode === bloodClass.PLT){
            this.positionAndZoom();
            return;
        }
        if(this.props.mode === bloodClass.FIELD){
            this.resetPosition();
            return;
        }
    }

    resetPosition = () => {
        this.setState({
            image_updated: false,
        })
        if (this.imageHolder && this.imageHolder.current) {
            let x = ((2 * (document.documentElement.clientWidth))) / 100
            let y = document.documentElement.clientHeight / 8
            this.imageHolder.current.setTransform(x, y, document.documentElement.clientWidth * 0.00016)
            setTimeout(()=>{this.setState({pageRefresh:!this.state.page})},1000) //used to update zoom level display on top of image
        }
    }

    positionAndZoom = () => {
        
        // CHANGE MAGNIFICATION OF IMAGE ONLY THROUGH 'MAG' VARIABLE!!
        let MAG=0.334; //Value taken so that initial Load of WBC shows 100x

        if (this.imageHolder && this.imageHolder.current && this.props.selected.meta['initPos']) {
            let xoffset = (16 * document.documentElement.clientWidth)/100
            let yoffset = (43 * document.documentElement.clientHeight)/100
            this.imageHolder.current.setTransform((-(this.props.selected.meta['initPos'][0])*MAG)+xoffset , (-(this.props.selected.meta['initPos'][1])*MAG)+yoffset , MAG)
        }
        this.setState({
            image_updated: false,
        })
        setTimeout(()=>{this.setState({pageRefresh:!this.state.page})},1000) //used to update zoom level display on top of image
    }

    componentDidMount = () => {
        this.timer = setTimeout(() => {
            this.setState({ resetOnFirstLoad: true });
        }, 2000);

        if (this.state.resetOnFirstLoad === true) {
            this.reposition();
            this.setState({ resetOnFirstLoad: false });
        }
    }

    componentDidUpdate = (prevProps, prevState) => {

        if(this.state.prevZstackIndex != this.state.currZStackIndex){
            this.setState({
                loadingImage: true
            });
            this.setState({prevZstackIndex:this.state.currZStackIndex})
        }

        if (prevProps.meta === undefined && this.props.meta !== undefined) {
            this.timer = setTimeout(() => {
                this.setState({ resetOnFirstLoad: true });
            }, 1000);

        }
        if (this.state.resetOnFirstLoad === true) {
            this.reposition();
            this.setState({ resetOnFirstLoad: false });
        }
        if (prevProps.selected != this.props.selected) {
            this.reposition();
            if (prevProps.selected.image != this.props.selected.image) {
                this.setState({
                    loadingImage: true
                });
            }
            else if (this.props.blood_class === bloodClass.WBC) {
                this.setState({
                    loadingImage: true
                });
            }
            if (this.props.selected!="" && this.props.selected.meta['initZStack'] != undefined) {
                this.setState({
                    currZStackIndex: this.props.selected.meta['ZStacks'].indexOf(this.props.selected.meta['initZStack']),
                    zStacks: this.props.selected.meta['ZStacks'],
                    ZStackStep: this.props.selected.meta['ZStackStep']
                })
            }
            if(this.props.mode != bloodClass.FIELD && this.props.selected.meta){
                this.setState({
                annotations: 
                    [{
                        "geometry": {
                            "type": "RECTANGLE",
                            "x": (this.props.selected.meta['initPos'][0]/bloodImageProperties.WIDTH*100)-12,
                            "y": (this.props.selected.meta['initPos'][1]/bloodImageProperties.HEIGHT*100)-12,
                            "width": 24,
                            "height": 24
                        },
                        "data": {
                            "text": <span style={{color:"black", fontSize:"2.5vw"}}>{this.props.selected.class}</span>,
                            "id": Math.random()
                        }
                    }]
                });
            }
        }
        if (prevProps.selected.id != this.props.selected.id) {
            this.setState({
                image_updated: true,
            })
        }
    }

    higherZstack = () => {
        let zStacks = ((this.props.selected||{}).meta||{})['ZStacks']
        let idx = this.state.currZStackIndex
        if (zStacks === undefined || idx === zStacks.length - 1) {
            return;
        }
        this.setState({
            currZStackIndex: idx + 1,
        })
    }

    lowerZstack = () => {
        let zStacks = ((this.props.selected||{}).meta||{})['ZStacks']
        let idx = this.state.currZStackIndex
        if (idx === 0 || zStacks === undefined) {
            return;
        }
        this.setState({
            currZStackIndex: idx - 1,
        })
    }

    onWheel = (ref, event) => {
        event.preventDefault();
        if (event.shiftKey != false && event.shiftKey != 'false') {
            if (event.wheelDelta > 0) {
                this.higherZstack();
            } else {
                this.lowerZstack();
            }
        } else {
            if (event.wheelDelta > 0) {
                this.imageHolder.current.zoomIn(0.3);
            }
            else {
                this.imageHolder.current.zoomOut(0.3);
            }
        }
    }

    updateZStackURL = (url_) => {
        let url = url_;
        url = url.split('/')
        let currZStackIndex = this.state.currZStackIndex
        let zStack;
        currZStackIndex = parseInt(currZStackIndex)
        if (parseInt(currZStackIndex) === '') {
            zStack = 0;
        } else {
            zStack = this.state.zStacks[currZStackIndex]
        }

        if (zStack === 0) {
            let idx = url.indexOf('stack');
            if (idx === -1) {
                url = url.join('/')
                return url;
            }
            url[idx] = 'tiled'
            url.splice(idx + 1, 1);
        }
        else {
            let idx = url.indexOf('tiled')
            if (idx === -1) {
                let idx = url.indexOf('stack');
                url[idx + 1] = `S${zStack}`
            } else {
                url[idx] = `stack/S${zStack}`
            }
        }
        url = url.join('/')
        return url
    }

    handleImageLoaded() {
        this.setState({
            loadingImage: false
        })
    }

    getView = (url, antIcon) => {
        let view;
        if(this.props.mode === bloodClass.FIELD){
            view=<React.Fragment>
                    <Spin indicator={antIcon} spinning={this.state.loadingImage} >
                        <img
                        src={url}
                        style={{filter: `brightness(${(this.props.cssfilter||{}).brightness}%) contrast(${(this.props.cssfilter||{}).contrast}%)`}}
                        onLoad={this.handleImageLoaded.bind(this)}
                        />
                    </Spin>
                </React.Fragment>
        } else {
            view=<React.Fragment>
                <Spin indicator={antIcon} spinning={this.state.loadingImage} >
                <div style={{filter: `brightness(${(this.props.cssfilter||{}).brightness}%) contrast(${(this.props.cssfilter||{}).contrast}%)`}}>
                    <Annotation
                        handleImageLoaded={this.handleImageLoaded.bind(this)}
                        src={url}
                        annotations={this.state.annotations}
                        type={this.state.type}
                        value={this.state.annotation}
                        disableAnnotation
                        style={{height:bloodImageProperties.HEIGHT, width:bloodImageProperties.WIDTH}}
                        disableOverlay
                        disableEditor
                        disableSelector
                        allowTouch
                    />
                </div>
                </Spin>
            </React.Fragment>
        }
        return view;                              
    }

    render() {
        let zoomLevel_ = (((this.imageHolder||{}).current||{}).state||{}).scale
        if (zoomLevel_ === undefined){
            this.props.mode === bloodClass.FIELD ? zoomLevel_ = 0.3 : zoomLevel_ = 1;
        }

        let url = ''
        if ((this.props.selected || {}).image) {
            url = this.updateZStackURL(this.props.selected.image)
        }

        if (this.props.selected === '') {
            return <div style={{ textAlign: 'center' }}>
                <Empty
                    style={this.props.view_mode === 1 ? { margin: '2vw', marginTop: '20vh', height: '20vh', width: '42vw' } : { margin: '210px', height: '683px', width: '816.3px' }}
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                        height: 100,
                    }}
                    description={
                        <span style={{ color: 'white' }}>
                            Select an Image to view at 100x
                    </span>
                    }
                >
                </Empty>
            </div>
        }

        let zoomLevelDisplay = (zoom) => {
            return (
                <div style={{position: 'absolute', fontSize: '2vw', bottom: '4vh', right: '3vw', backgroundColor: 'rgba(255,255,255,0.5)', borderRadius:'10px', color:'#3a4241', padding: '0px 5px'}}>
                                    {(zoom*300).toFixed(0)} X
                                </div>

            )
        } 
        let zStackMicrons = (this.state.zStacks[this.state.currZStackIndex] * this.props.selected.meta['ZStackStep']).toFixed(2)
        let antIcon = <LoadingOutlined style={{ fontSize: 0 }} spin />;
        let divwidth = (39.8)*(document.documentElement.clientWidth/100)
        if(!(this.props.differential) || (this.props.mode === bloodClass.FIELD)){
            if(window.innerWidth<1201){
                divwidth = (43)*(document.documentElement.clientWidth/100)
            }else{
                divwidth = (45)*(document.documentElement.clientWidth/100)
            }
        }
        let viewchooser=this.getView(url, antIcon);
        return (
            <div style={{ textAlign: 'center' }} >
                {this.props.view_mode === 1 ?
                    <TransformWrapper
                        id="img-wrapper"
                        ref={this.imageHolder}
                        centerZoomedOut={this.props.mode === bloodClass.FIELD}
                        centerOnInit={this.props.mode === bloodClass.FIELD}
                        minScale={0.2}
                        maxScale={4}
                        limitToBounds={true}
                        initialPositionX={(document.documentElement.clientWidth * 7.2) / (100)}
                        initialPositionY={130}
                        initialPositionX={this.props.selected.meta[0]}
                        onInit={this.state.image_updated ? this.reposition() : null}
                        onWheelStart={this.onWheel}
                        wheel={{ 'step': 0 }}
                        onZoom = {()=>{this.setState({pageRefresh: !this.state.pageRefresh}) }} //used to update zoom level display on top of image
                    >
                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                            <React.Fragment>
                                <TransformComponent wrapperStyle={{ margin: '1.5vw',marginTop:'0', height: '86.5vh', width: divwidth, borderStyle: 'solid', borderWidth: '1px', borderColor: '#03112c', backgroundColor: '#03112c' }}>
                                    {viewchooser}
                                </TransformComponent>
                            </React.Fragment>
                        )}
                    </TransformWrapper>
                    :
                    <TransformWrapper
                        ref={this.imageHolder}
                        centerZoomedOut={this.props.mode === bloodClass.FIELD}
                        centerOnInit={this.props.mode === bloodClass.FIELD}
                        minScale={0.2}
                        maxScale={3}
                        initialPositionX={119}
                        initialPositionY={20}
                    >
                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                            <React.Fragment>
                                <div style={{ position: 'fixed', bottom: '2vh', borderWidth: '0px', borderColor: 'rgba(0,0,0,0)' }}>
                                    <Button style={{ height: 'auto', textAlign: 'center', backgroundColor: '', borderColor: 'rgba(0,0,0,0)', borderWidth: '0px' }} onClick={() => zoomIn()}><ZoomInOutlined /></Button>
                                    <Button style={{ height: 'auto', textAlign: 'center', backgroundColor: '', borderColor: 'rgba(0,0,0,0)', borderWidth: '0px' }} onClick={() => zoomOut()}><ZoomOutOutlined /></Button>
                                    <Button style={{ height: 'auto', textAlign: 'center', backgroundColor: '', borderColor: 'rgba(0,0,0,0)', borderWidth: '0px' }} onClick={() => resetTransform()}><CompressOutlined /></Button>
                                </div>
                                <TransformComponent wrapperStyle={{ margin: '70px', marginTop: '-6px', height: '700px', width: '1100px', borderStyle: 'solid', borderWidth: '1px', borderColor: '#03112c', backgroundColor: '#03112c' }}
                                >
                                    <img
                                        src={this.props.selected.image}
                                    />
                                </TransformComponent>
                            </React.Fragment>
                        )}
                    </TransformWrapper>
                }
                
                    <Row style={ this.props.view_mode === 1 ? {position: 'fixed', align:'center', marginLeft: '1.5vw', bottom: '5vh', width:divwidth } : {}}>
                        <Progress strokeWidth={this.state.loadingImage ? "0.45vh" : "0vh"} status={this.state.loadingImage ? "active" : {}} percent={this.state.loadingImage ? 25 : 100} showInfo={false}/>
                    </Row>
                
                
                <Row style={this.props.view_mode === 1 ? { position: 'fixed', bottom: '1vh' } : {}}>
                    <Col style={this.props.view_mode === 1 ? { color: 'white', marginLeft: '4.3vw', fontSize: '1vw' } : {}}>
                        <div>
                            <span style={{ marginLeft: '-2.5vw' }}>
                                <span>ZSTACK:</span>
                                <Button style={{ height: 'auto', textAlign: 'center', backgroundColor: '#03112c', borderColor: 'rgba(0,0,0,0)', borderWidth: '0px' }} onClick={this.higherZstack}><PlusCircleOutlined style={{ color: 'cyan', fontSize: '1vw' }} /></Button>
                                <Tooltip title={<p>Use Shift + &#129053; &amp; &#129055; to toggle</p>}>
                                    <span>{zStackMicrons >= 0 ? "+" + zStackMicrons : zStackMicrons} microns</span>
                                </Tooltip>
                                <Button style={{ height: 'auto', textAlign: 'center', backgroundColor: '#03112c', borderColor: 'rgba(0,0,0,0)', borderWidth: '0px' }} onClick={this.lowerZstack}><MinusCircleOutlined style={{ color: 'cyan', fontSize: '1vw' }} /></Button>
                            </span>

                            <span style={cookie.loadAll().isMobile === 'true'?{ marginLeft: '2.5vw' }:{ marginLeft: '4.5vw' }}>
                                <Button style={{ height: 'auto', backgroundColor: '#03112c', borderColor: 'rgba(0,0,0,0)', borderWidth: '0px' }} onClick={() => this.props.loadPrev(0)}><LeftCircleOutlined style={{ color: 'cyan', fontSize: '1vw' }}></LeftCircleOutlined></Button>
                                <Tooltip title={<p>Use &#129052; &amp; &#129054; to toggle</p>}><span>#{this.props.selected.overall_count}</span></Tooltip>
                                <Button style={{ height: 'auto', backgroundColor: '#03112c', borderColor: 'rgba(0,0,0,0)', borderWidth: '0px' }} onClick={() => this.props.loadNext(0)}><RightCircleOutlined style={{ color: 'cyan', fontSize: '1vw' }}></RightCircleOutlined></Button>
                            </span>
                            <span style={(this.props.mode === bloodClass.FIELD || !(this.props.differential))?{ position: 'fixed', right: '34.5vw' }:{ position: 'fixed', right: '17vw' }}>
                                <Button style={{ height: 'auto', textAlign: 'center', backgroundColor: '#03112c', borderColor: 'rgba(0,0,0,0)', borderWidth: '0px' }} onClick={() => {this.reposition();setTimeout(()=>{this.setState({pageRefresh:!this.state.page})},1000)}}><CompressOutlined style={{ color: 'cyan', fontSize: '1vw' }} /></Button>
                                <Button style={{ height: 'auto', textAlign: 'center', backgroundColor: '#03112c', borderColor: 'rgba(0,0,0,0)', borderWidth: '0px' }} onClick={() => {this.imageHolder.current.zoomOut();setTimeout(()=>{this.setState({pageRefresh:!this.state.page})},1000)}}><ZoomOutOutlined style={{ color: 'cyan', fontSize: '1vw' }} /></Button>
                                <Button style={{ height: 'auto', textAlign: 'center', backgroundColor: '#03112c', borderColor: 'rgba(0,0,0,0)', borderWidth: '0px' }} onClick={() => {this.imageHolder.current.zoomIn();setTimeout(()=>{this.setState({pageRefresh:!this.state.page})},1000)}}><ZoomInOutlined style={{ color: 'cyan', fontSize: '1vw' }} /></Button>
                            </span>
                        </div>
                    </Col>
                </Row>
                {zoomLevelDisplay(zoomLevel_)}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
};

export default connect(mapStateToProps)(Field100xView);

