import React, { Component } from 'react';
import cookie from "react-cookies";
import { FolderOpenOutlined, FolderOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Col,
  Spin,
  Divider,
  Table,
  Input,
  Button,
  Row,
  Tooltip,
  Empty,
  Pagination,
  Tag,
  Select,
  Popconfirm,
} from 'antd';
import { globalUrlPrefix, openInNewTabType } from '../utils/const';
import { updateUrlStateInReducer, updateCaseSearch, changePage, clearAllFilters } from '../action/search.case.action';
import { caseUpdateAlert, assignUsersUpdateAlert, removeUserUpdateAlert, markCaseUnread, markCaseAndSlidesRead } from '../action/cases.action'
import { makeMenuVisible } from "../action/context.menu.action";
import { connect } from "react-redux";
import { openDrawer } from "../action/dashboard.action";
import { historyMP } from '../helper/history';
import FilterComp from "../component/case/filter";
import '../asset/style/custom_antd_table_style.css';
const { Search } = Input;
const ButtonGroup = Button.Group;
const Option = Select.Option;


const pageSize = 15;

class CasesView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      search: this.props.urlState.search,
      initializationDone: false,
      case_status: -1,
      selected_users: [],
    }

    this.props.dispatch(updateUrlStateInReducer(this.props.urlState));
  }

  componentWillMount() {
    this.props.dispatch(clearAllFilters());
    this.props.dispatch(openDrawer());
  }

  getCases = (cases) => {
    let newData = []
    Object.keys(cases).sort(
      function (a, b) {
        return cases[a].displayOrder - cases[b].displayOrder;
      }
    ).map((key) => {
      let curr = cases[key];
      curr.key = curr.id;
      curr.case = this.getCaseDesign(curr);
      curr.type = openInNewTabType.CASE;
      newData.push(curr);
    });
    return newData;
  }

  getCaseDesign = (caseInfo) => {
    console.log("ci",caseInfo)
    const isLongCaseID = caseInfo.case_id.length > 15;
    const caseIdElem = (
      caseInfo.unread ?
        <b>{isLongCaseID ? `${caseInfo.case_id.slice(0, 15)}...` : caseInfo.case_id}</b> :
        <span>{isLongCaseID ? `${caseInfo.case_id.slice(0, 15)}...` : caseInfo.case_id}</span>
    );
    const finalCaseIdElem = isLongCaseID ? <Tooltip title={caseInfo.case_id} key={caseInfo.case_id}>{caseIdElem}</Tooltip> : caseIdElem;

    const isLongName = caseInfo.patient_name.length > 25;
    const nameElem = (
      caseInfo.unread ?
        <b>{isLongName ? `${caseInfo.patient_name.slice(0, 25)}...` : caseInfo.patient_name}</b> :
        <span>{isLongName ? `${caseInfo.patient_name.slice(0, 25)}...` : caseInfo.patient_name}</span>
    );
    const finalNameElem = isLongName ? <Tooltip title={caseInfo.patient_name} key={caseInfo.patient_name}>{nameElem}</Tooltip> : nameElem;

    const isLongStatus = caseInfo.case_status.length > 35;
    const statusElem = (
      <Tag key={caseInfo.case_id} color="#3b7199" onClick={(event) => event.stopPropagation()}>
        {isLongStatus ? `${caseInfo.case_status.slice(0, 35)}...` : caseInfo.case_status}
      </Tag>
    );
    const finalStatusElem = isLongStatus ? <Tooltip title={caseInfo.case_status} key={caseInfo.case_status}>{statusElem}</Tooltip> : statusElem;

    const assigned_names = this.getAssignedNamesComponent(caseInfo);
    return <Row>
      <Col span={3} offset={1}>
        {finalCaseIdElem}
      </Col>
      <Col span={5}>
        {finalNameElem}
      </Col>
      <Col span={5}>
        {finalStatusElem}
      </Col>
      <Col span={10}>
        {assigned_names}
      </Col>
    </Row>
  }

  getAssignedNamesComponent = (caseInfo) => {
    return caseInfo.assigned_users.map((user, index) => {
      const full_name = user.first_name + ' ' + user.last_name;
      const isLongUserName = full_name.length > 20;
      let color = 'blue';
      const userNameElem = (
        <Tag key={user.id} color={color} onClick={(event) => event.stopPropagation()} style={{ cursor: 'pointer' }}>
          {isLongUserName ? `${full_name.slice(0, 20)}...` : full_name} 
        </Tag>
      );
      const finalUserTag = isLongUserName ? <Tooltip title={full_name} key={user.id}>{userNameElem}</Tooltip> : userNameElem;
      return cookie.loadAll().is_staff === "true" || cookie.loadAll().superuser === "true"  ?
      <Popconfirm
        title={"Remove user " + full_name + " from this case?"}
        onConfirm={(event) => this.handleCancelAssignedUser(event, caseInfo, user.id)}
        okText="Yes"
        palcement="topRight"
        style={{ padding: 20 }}
        cancelText="No"
        onCancel={(event) => {
          event.stopPropagation();
        }}>
        {finalUserTag}
      </Popconfirm> : finalUserTag;
    });
  }

  handleCancelAssignedUser = (event, currCase, user_id) => {
    event.stopPropagation();
    let val = {
      id: currCase.id,
      case_id: currCase.case_id,
      displayOrder: currCase.displayOrder,
      user_id: user_id
    };
    this.props.dispatch(removeUserUpdateAlert(currCase.id, val, this.props.urlState));
  }

  generateReport = () => {
    let url = `/api/get_pdf/?case_id=${this.state.selectedCase.case_id}`;
    window.open(url);
  }

  initializeSearch = () => {
    if (!this.state.initializationDone && this.props.urlState.search.length > 0) {
      this.setState({
        search: this.props.urlState.search,
        initializationDone: true,
      })
    }
  }

  onCaseSearch = (value) => {
    this.setState({
      selectedRowKeys: [],
    })
    this.props.dispatch(updateCaseSearch(value, this.props.urlState));
  }

  onInputChange = (e) => {
    if (e.target.value == '') {
      this.onSeachReset();
    }
    this.setState({
      search: e.target.value,
    });
  }

  onSeachReset = () => {
    if (this.state.search != '') {
      let search = '';
      this.props.dispatch(updateCaseSearch(search, this.props.urlState));
      this.setState({
        search,
        selectedRowKeys: [],
      });
    }
  }

  getStatusOptions = () => {
    let status_options = Object.keys(this.props.allCaseStatus).map((key) => {
      let value = this.props.allCaseStatus[key];
      return <Option key={value.id} value={value.id}>{value.status}</Option>
    });
    status_options.unshift(<Option value={-1} key={-1}>Select Status</Option>);
    return status_options;
  }

  getUserOptions = () => {
    return Object.keys(this.props.allUsers).map((key) => {
      let value = this.props.allUsers[key];
      return <Option key={value.id} value={value.id}>{value.first_name + ' ' + value.last_name}</Option>
    });
  }

  allUnreadCases = (selectedCases) => {
    for (let i = 0; i < selectedCases.length; i++) {
      if (this.props.allCases[selectedCases[i]].unread) {
        return false;
      }
    }
    return true;
  }

  makeCasesUnread = () => {
    this.state.selectedRowKeys.map((value, index) => {
      let curr_case = this.props.allCases[value];
      this.props.dispatch(markCaseUnread(curr_case));
    });
  }

  makeCasesRead = () => {
    this.state.selectedRowKeys.map((value, index) => {
      let curr_case = this.props.allCases[value];
      this.props.dispatch(markCaseAndSlidesRead(curr_case));
    });
  }

  getRowActionsIfAtleastOneRowSelected = () => {

    let unreadIcon = <Button onClick={this.makeCasesUnread}>
      <FolderOutlined /> Mark as unread
                      </Button>

    let readIcon = <Button onClick={this.makeCasesRead}>
      <FolderOpenOutlined /> Mark as read
                    </Button>

    return this.state.selectedRowKeys.length > 0 ?
      <Row>
        <Col span={4}>
          <ButtonGroup>
            <Button onClick={() => { this.setState({ selectedRowKeys: Object.keys(this.props.allCases).map((value) => parseInt(value)) }) }}>
              Select All Cases
            </Button>
            <Button onClick={() => { this.setState({ selectedRowKeys: [] }) }}>
              Cancel Selection
            </Button>
          </ButtonGroup>
        </Col>
        <Col span={2} offset={1}>
          {this.allUnreadCases(this.state.selectedRowKeys) ?
            unreadIcon : readIcon
          }
        </Col>
        <Col span={4} offset={1}>
          <Select
            value={this.state.case_status}
            placeholder="Select Status"
            onChange={this.handleTypeChange}
            style={{ width: 220, marginLeft: 8 }}
          >
            {this.getStatusOptions()}
          </Select>
        </Col>
        <Col span={7} offset={1}>
          <Select
            mode="multiple"
            value={this.state.selected_users}
            placeholder="Select Users"
            onChange={this.handleUserChange}
            filterOption={this.filterOptionUsers}
            style={{ width: 500, marginLeft: 8 }}
          >
            {this.getUserOptions()}
          </Select>
        </Col>
        <Col span={3} offset={1}>
          <Button onClick={this.sumitUsersAndStatus} disabled={this.state.selected_users.length <= 0 && this.state.case_status === -1}>
            Submit
          </Button>
        </Col>
      </Row>
      :
      <Button onClick={() => { this.setState({ selectedRowKeys: Object.keys(this.props.allCases).map((value) => parseInt(value)) }) }}>
        Select All Cases
      </Button>
  }

  handleTypeChange = (status) => {
    this.setState({
      case_status: status,
    });
  }

  handleUserChange = (value) => {
    this.setState({
      selected_users: value,
    })
  }

  filterOptionUsers = (inputValue, option) => {
    return option.props.children.toLowerCase().includes(inputValue.toLowerCase())
  }

  sumitUsersAndStatus = () => {
    // if (this.state.selected_users.length > 0) {
    //   console.log(this.props.allCases)
    //   this.state.selectedRowKeys.map((value, index) => {
    //     this.handleChangeAssignUsers(this.props.allCases[value], this.state.selected_users, this.state.selectedRowKeys);
    //   });
    // }

    if (this.state.selected_users.length > 0) {
      console.log(this.props.allCases)
      let all_cases = []
      this.state.selectedRowKeys.map((value, index) => {
        all_cases.push(this.props.allCases[value])
      })
      this.handleChangeAssignUsers(all_cases, this.state.selected_users, this.state.selectedRowKeys)
    }

    if (this.state.case_status !== -1) {
      this.state.selectedRowKeys.map((value, index) => {
        this.handleChangeStatus(this.props.allCases[value], this.state.case_status, this.state.selectedRowKeys);
      });
    }

    this.setState({
      selected_users: [],
      case_status: -1,
    })
  }

  handleChangeStatus = (currCase, input, selectedRows) => {
    let val = {
      case_id: currCase.case_id,
      displayOrder: currCase.displayOrder,
      case_status: input
    };
    this.props.dispatch(caseUpdateAlert(currCase.id, val, selectedRows));
  };

  // handleChangeAssignUsers = (currCase, input, selectedRows) => {
  //   let val = {
  //     id: currCase.id,
  //     case_id: currCase.case_id,
  //     displayOrder: currCase.displayOrder,
  //     assigned_users: input
  //   };
  //   this.props.dispatch(assignUsersUpdateAlert(currCase.id, val, selectedRows));
  // };

  handleChangeAssignUsers = (currCases, input, selectedRows) => {
    let values = []
    let ids = []
    console.log("input--", input)
    for (let i = 0; i<currCases.length; i++){
      let val = {
        id: currCases[i].id,
        case_id: currCases[i].case_id,
        displayOrder: currCases[i].displayOrder,
        assigned_users: input
      };
      values.push(val)
      ids.push(currCases[i].id)
    }
    
    this.props.dispatch(assignUsersUpdateAlert(ids, values, input, selectedRows));
  };

  getFilters = () => {
    return this.state.selectedRowKeys.length > 0 ? undefined : <FilterComp />;
  }

  getRowActions = () => {
    return <Row>
      <Col span={this.state.selectedRowKeys.length > 0 ? 24 : 4}>
        <span style={{ marginRight: "10px" }}>
          {this.state.selectedRowKeys.length > 0 ? "What do you want to do with the selected cases ? " : ""}
        </span>
        {this.getRowActionsIfAtleastOneRowSelected()}
      </Col>
      <Col span={this.state.selectedRowKeys.length > 0 ? 0 : 20}>{this.getFilters()}</Col>
    </Row>
  }

  getPaginationDiv = () => {
    return <Pagination
      total={this.props.total}
      showTotal={(total, range) =>
        this.isMobile ? `` : `Showing ${range[0]}-${range[1]} out of ${total} total cases`
      }
      current={this.props.urlState.page}
      pageSize={pageSize}
      defaultCurrent={1}
      onChange={this.updatePage}
      size="small"
      className="pagination-mp"
    />
  }

  updatePage = (pageNumber) => {
    historyMP.push('/' + globalUrlPrefix + '/cases?page=' + pageNumber);
    this.props.dispatch(changePage(pageNumber, this.props.urlState));
  }

  linkCase = (id) => {
    window.location.href = "/" + globalUrlPrefix + "/case/" + id;
  };

  openAddNewCase = () => {
    window.location.href = "/" + globalUrlPrefix + "/addcase/";
  }

  render() {

    this.initializeSearch();
    let data = this.getCases(this.props.allCases);
    let columns = [
      {
        title: () => {
          return <div>
            <Row>
              <Col span={3} offset={1}>
                <b >FOLDER ID</b>
              </Col>
              <Col span={5}>
                <b >PATIENT'S NAME</b>
              </Col>
              <Col span={5}>
                <b >STATUS</b>
              </Col>
              <Col span={10}>
                <b>ASSIGNED USERS</b>
              </Col>
            </Row>
          </div>
        },
        dataIndex: "case",
        key: "case",
      }
    ]

    const onRow = (record, rowIndex) => {
      return {
        onClick: event => {
          this.linkCase(record.id);
        },
        onContextMenu: (event) => {
          event.preventDefault();
          this.props.dispatch(makeMenuVisible({ visible: true, x: event.clientX, y: event.clientY, record: record, height: this.props.height }));
        }
      };
    }

    let rowActions = [
      <Divider />,
      <Row className="row-actions">
        {this.getRowActions()}
      </Row>
    ];

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRowKeys: selectedRowKeys
        })
      },
      selectedRowKeys: this.state.selectedRowKeys,
      columnWidth: '10px',

    };

    return (
      <div>
        <Spin tip="Loading..." spinning={this.props.isFetching} delay={500}>
          {this.props.isFetching === true ? <div></div> :
            <div>
              <Row>
                <Col span={24} offset={0} className="all-slides">
                  <br />
                  <Row>
                    <Col span={5}>
                      {cookie.loadAll().is_staff === "true" ?
                        <Button onClick={this.openAddNewCase} className="custom-button-style add-case-button-position custom-button-background">
                          Create New Folder
                                        <PlusOutlined style={{ fontSize: "13px" }} />
                        </Button> : undefined}
                    </Col>
                    <Col span={8} offset={1}>
                      <Search
                        value={this.state.search || ''}
                        onChange={this.onInputChange}
                        placeholder="Search by Case ID"
                        onSearch={this.onCaseSearch}
                        enterButton
                      />
                    </Col>
                    <Col span={6}>
                      <Button type="danger" onClick={this.onSeachReset} style={{ marginLeft: 10 }}>
                        Reset
                      </Button>
                    </Col>
                    <br />
                    <br />
                  </Row>
                  <Row>
                    {this.getPaginationDiv()}
                  </Row>
                  {rowActions}
                  <Divider />
                  <Row>
                    <Table
                      className="custom-hover-table-case custom-table-height custom-table-selection custom-table-header"
                      rowClassName={(record, index) => (record.unread ? 'slide-table-rows custom-hover-table-box unread' : 'slide-table-rows custom-hover-table-box read')}
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      onRow={onRow}
                      locale={{ emptyText: (<Empty description="No Cases Found" />) }}
                      rowSelection={rowSelection}
                      size="small"
                    />
                  </Row>
                  <Row>
                    {this.getPaginationDiv()}
                  </Row>
                </Col>
              </Row>
            </div>

          }
        </Spin>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginStatus: state.loginAlerts.loginStatus,
    allCases: state.casesReducer,
    allCaseStatus: state.caseStatusReducer.case_status,
    allUsers: state.caseStatusReducer.users,
    numPages: state.caseStatusReducer.numPages,
    pageNumber: state.caseStatusReducer.pageNumber,
    total: state.caseStatusReducer.total,
    urlState: state.searchCaseReducer,
    isFetching: state.searchCaseReducer.isFetching
  };
};

export default connect(mapStateToProps)(CasesView);