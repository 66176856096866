import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { AuthHeader } from '../helper/auth.token';
import { Spin, Statistic, Progress, Row, PageHeader, Divider, Col , Table, Button, Tooltip} from 'antd';
import { reset, bloodClassChanged, bloodTagChanged,  updateSummary} from "../action/bloodGrid.action";
import { updateWBCSummary } from '../action/wbcview.action'
import { updateWBCTag } from '../action/wbcview.action'
import { CheckOutlined , ClearOutlined} from '@ant-design/icons';
import { update } from 'plotly.js';
import { updateTags } from '../action/search.action';

export class BloodSummary extends Component {

    constructor(props) {
        super(props);

        this.state = {
            table_data:'',
        }

        this.columns = [
          {
            title: <span style={{color:'cyan', }}>Name</span>,
            dataIndex: 'name',
            render: name => {  
                if(this.props.tag == name || (this.props.tag == '' && name=='All')){
                  return <div>
                            <span style={{color:'green'}}>
                              {name}
                            </span>
                          </div>
                } 
                else{
                  return <div>
                            <a onClick={name=="All"?()=>{this.changeTag('');this.clearFilters();}:()=>this.changeTag(name)} style={{color:'cyan'}}>
                              {name}
                            </a>
                          </div>
                }    
            },
          },
          {
            title:<span style={{color:'cyan', fontSize: '1vw'}}>Count</span>,
            dataIndex: 'count',
            render: count => {
              return <span style={{fontSize: '1vw',}}>{count}</span>
            }
          },
          {
            title:<span style={{color:'cyan', fontSize: '1vw'}}>%</span>,
            dataIndex:'percentage',
            render:( percentage, name )=> {
              console.log("percentage", percentage,name)
              if(name.name == "Nucleated RBC"|| name.name== "Inconsistent"|| name.name == "Other" || name.name=="Smudge Cell")
                return <span style={{fontSize: '1vw',}}>{"--"}</span>
              return <span style={{fontSize: '1vw',}}>{percentage.toFixed(2)}</span>
            }
          },
          // {
          //   title: <span style={{color:'cyan'}}>Seen</span>,
          //   dataIndex: 'seen',
          //   render: seen => {
          //     if(seen){
          //       return <CheckOutlined style={{marginLeft:'10px'}}/>
          //     }
          //     else{
          //       return <div></div>
          //     }
          //   }
          // }
          ,
        ];

    }

    changeTag = (tag) => {
      // console.log("action",keyword)
      this.props.dispatch(updateWBCTag(tag))
      this.getSummary();
    }

    clearFilters = () => {
      this.props.dispatch(updateWBCTag(''));
      this.getSummary();
    }

    getSummary = () => {
      let url = `/api/retrieve_summary/?slide_id=${this.props.slide_id}&class=${this.props.class}`;
      axios.get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
          .then(response => {
              if (response.status === 200) {
                let resp=response.data['data'];
                let tot_count = 0;
                Object.keys(resp).map((val) => {
                  console.log("td-1", resp[val].count)
                  tot_count+=resp[val].count;
                });
                let size = Object.keys(resp).length;
                // tot_count-=resp[size-1].count;
                // resp.unshift({
                //   name:"All",
                //   count: tot_count,
                //   percentage: 100,
                //   seen: true,
                //   class: ' '
                // });
                // resp.push(respUnclassified[0])
                console.log("td", resp);
                  this.setState({
                    table_data: resp,
                  })
                  this.props.dispatch(updateSummary(resp))
              }
              else {
                  
              }
          })
          .catch(err => {
              console.log("response",err)
          })
    }

    componentDidMount = () => {
      this.props.dispatch(reset());
      this.getSummary();
    }

    componentDidUpdate = (prevProps) => {
        // if(prevProps.bloodGridData != this.props.bloodGridData){
        //   console.log("updatelist")
        //   this.getSummary();
        //   this.props.dispatch(updateList(false));
        // }
        if(this.props.wbcGridData.update_summary==true){
            console.log("list", this.props.update_list)
            this.getSummary();
            this.props.dispatch(updateWBCSummary(false));
        }
    }


    componentWillUnmount = () => {

    }

    render() {  
      return (
          <div style={{height:"35vh", marginLeft:'0.4vw'}}>
              <Row>
                  <Table
                      className="custom-hover-table-case custom-table-height custom-table-selection custom-table-header-blood"
                      // style = {{marginTop:'3vh'}}
                      dataSource={this.state.table_data}
                      columns={this.columns}
                      // pagination= {{pageSize:'4', simple:true}}
                      pagination={false}
                      loading={{ indicator:<Spin />, spinning:!this.state.table_data}}
                      // scroll={{ y: 250 }}
                  />
              </Row>
          </div>
      )
    }
}

const mapStateToProps = (state) => {
    return {
      bloodGridData: state.bloodGridReducer,
    }
};

export default connect(mapStateToProps)(BloodSummary);
