import { LoginAlertConstant } from '../actionTypes/login.alert.constant';
import axios from 'axios';
import cookie from 'react-cookies';
import { historyMP } from '../helper/history';
import { message } from 'antd';
import { AuthHeader } from '../helper/auth.token';
import { setUIBusy, setUIIdle } from "../action/utils.action";

var querystring = require('querystring');

export const loginRequest = () => ({
    type: LoginAlertConstant.LOGIN_REQUEST
});

export const loginSuccess = (success) => ({
    type: LoginAlertConstant.LOGIN_SUCCESS,
    response: success
});

export const loginError = (error) => ({
    type: LoginAlertConstant.LOGIN_ERROR,
    response: error
});

export const logoutAlert = () => ({
    type: LoginAlertConstant.LOGOUT
});


export const csrfToken = (csrfValue) => ({
    type: LoginAlertConstant.CSRF_TOKEN_SUCCESS,
    response: csrfValue
});


export const proxyLogin = (proxyLogin) => ({
    type: LoginAlertConstant.PROXY_LOGIN_SUCCESS,
    response: proxyLogin
});


export const logout = (displayMessage = null, resolution = null) => (dispatch) => {
    dispatch(setUIBusy("Logging Out"));
    dispatch(logoutAlert());
    cookie.remove('token', { path: '/' });

    let url = '/accounts/logout';
    let queryParams = "";

    // logout with custom message
    if (displayMessage && resolution) {
        queryParams = "?message=" + encodeURIComponent(displayMessage);
        queryParams += "&resolution=" + encodeURIComponent(resolution);
    }

    setTimeout(() => {
        historyMP.push('/login' + queryParams);
        axios.get(url, {
            headers: { Authorization: AuthHeader(),
            } })
            .then(response => {
                dispatch(setUIIdle());
            })
            .catch(err => {
                dispatch(setUIIdle());
                console.log("Django logout error", err)

            });
        clearCookies();
        localStorage.clear();
    }, 10);   // [Priyanshu] changed this time from 1000 -> 10 , I don't know what it does

}

export const login = (userCredentials, proxyLoginStatus=false) => (dispatch) => {
    dispatch(setUIBusy("Logging In"));
    dispatch(loginRequest());
    let url = '/server/auth';
    axios.post(url, userCredentials, {
            timeout: 10000 // unit is milliseconds
        })
        .then(response => {
            if (response.status === 200) {
                
                const isIOS = () => {
                    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
                      return true;
                    } else if(navigator.maxTouchPoints &&
                        navigator.maxTouchPoints > 2 &&
                        /MacIntel/.test(navigator.platform)){
                      return true;
                    }
                    return false;
                  }
                
                var isMobile = false; //initiate as false
                // device detection
                if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
                    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(navigator.userAgent.substr(0,4))) { 
                    isMobile = true;
                 
                }
                //iPad OS is also considered mobile os for our use case
                //Test iPad OS --
                if(navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)){
                    isMobile = true;
                }

                var iOS = isIOS();
                let adminSetting = {};
                adminSetting.id = response.data.user.admin_setting.id;
                adminSetting.app_state = response.data.user.admin_setting.app_state;
                cookie.save('token', response.data.token, { path: '/' });
                cookie.save('first_name', response.data.user.first_name, { path: '/' });
                cookie.save('username', response.data.user.username, { path: '/' });
                cookie.save('superuser', response.data.user.is_superuser, { path: '/' });
                cookie.save('is_staff', response.data.user.is_staff, { path: '/' });
                cookie.save('deployment_mode', response.data.deployment_mode, { path: '/' });
                cookie.save('user_id', response.data.user.id, { path: '/'});
                cookie.save('groups', response.data.groups, { path: '/'});
                cookie.save('isMobile', isMobile, { path: '/'})
                cookie.save('iOS', iOS, { path: '/'});
                delete response.data.user.morpheus_setting.tile_viewer_app_state;
                localStorage.setItem('morpheus_setting', JSON.stringify(response.data.user.morpheus_setting));
                cookie.save('admin_setting', adminSetting, {path : '/'});
                cookie.save('proxyLoginStatus', proxyLoginStatus, { path: '/'});
                cookie.save('notify_assigned_users', response.data.user.notify_assigned_users, {path: '/'});
                cookie.save('screen_supported', false, { path: '/'});
                let global_configuration = JSON.parse(response.data.global_configuration)
                let clia_screen_check = "false";
                for(let i=0; i<global_configuration.length;i++){
                    if(global_configuration[i].key == "clia_screen_check"){
                        clia_screen_check = global_configuration[i].value
                    }
                }
                cookie.save('clia_screen_check', clia_screen_check, { path: '/'})
                console.log('hellologin');
                dispatch(loginDjango(userCredentials));
                dispatch(proxyLogin(proxyLoginStatus));
                dispatch(loginSuccess(response));
                dispatch(setUIIdle());
            }
            else {
                // TODO: handle cases where response status is in 2xx range but not 200
                message.error('Unhandled Error: 2xx but not 200. Please report to Morphle Labs', 2.5);
                dispatch(loginError(response));
                dispatch(setUIIdle());
            }
        })
        .catch(err => {
            if(err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // 400 => wrong credentials
                // 401 => authentication is possible, but lacks valid authentication credentials
                // 403 => user isn't authorized to do this
                // 502 => invalid response from the upstream server
                // 504 => did not get a response from server in time
                let errStatusCodes = [400, 401, 403, 502, 504];
                if(errStatusCodes.indexOf(err.response.status) > -1) {
                    dispatch(loginError(err.response));
                    dispatch(setUIIdle());
                } else {
                    // TODO: handle more cases here
                    message.error(['Unhandled Error: out of 2xx range and not ',
                                    [errStatusCodes].join(","),
                                    '. Please report to Morphle Labs'].join(), 2.5);
                    dispatch(loginError(err.response));
                    dispatch(setUIIdle());
                }
            }
            else if (err.request) {
              // The request was made but no response was received
              // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              if(err.code == "ECONNABORTED") {
                    message.error(['Request timed out: code is ECONNABORTED',
                                    '. Please report to Morphle Labs'].join(), 2.5);
                    dispatch(setUIIdle());
              }
              console.log(err.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', err.message);
              message.config({
                    maxCount: 1
                });
                message.error('Invalid Username or Password', 2);
                dispatch(loginError(err));
                dispatch(setUIIdle());
            }
        });
}

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}


export const loginDjango = (userCredentials) => (dispatch) => {
    dispatch(loginRequest());
    let url = '/accounts/login/'
    axios.post(url, querystring.stringify(userCredentials), config)
        .then(response => {
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                // dispatch(loginSuccess(response));
            }
            else if (response.status === 403 || response.status === 400) {
                dispatch(loginError(response));
                message.error('Wrong Credentials', 2.5)
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(loginError(err));
        })
}


export const getCsrfToken = () => (dispatch) => {
    let url = '/server/csrf/'
    axios.get(url)
        .then(response => {
            if (response.status === 200) {
                dispatch(csrfToken(response.data.csrfToken));
                cookie.save('csrftoken', response.data.csrfToken, {path : '/'});
            }
        })
}

export const clearCookies = () => {
    cookie.remove('username');
    cookie.remove('superuser');
    cookie.remove('is_staff');
    cookie.remove('token');
    cookie.remove('first_name');
    cookie.remove('deployment_mode');
    cookie.remove('user_id');
    cookie.remove('groups');
    cookie.remove('isMobile');
    cookie.remove('iOS');
    cookie.remove('proxyLoginStatus');
}
