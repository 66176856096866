import React, { Component } from 'react';
import { Row, Col, Icon, Button, Tooltip, message } from 'antd';
import { connect } from "react-redux";
import axios from 'axios';
import { AuthHeader } from "../../helper/auth.token";
import cookie from "react-cookies";
import LiveModeSettings from "./settings_live_mode";
import Chat from "./livemode_chat";
import { historyMP } from '../../helper/history';
import { globalUrlPrefix, liveModeLoadingMessages, objectiveTypeLiveMode } from '../../utils/const';
import { updateDoAutoFocus, updateTakeZStack, updateLiveModeAction } from "../../action/admin.state.action";

import logo_square from "../../asset/img/logo_square_live_mode.png";
import logo_square_new from "../../asset/img/logo_square_live_mode_new.png";
import { getLiveImageMoveAndFocus, toggleShowChat, getNearestTile, updateUseCoverSlipObjective, updateAccessRevoked, updateAccessToLiveMode, updateExposure, updateGamma, getLiveImageMoveAndFocusDropDistance } from '../../action/livemode.action';

class LiveModeSidePanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSettings: false,
        }
    }

    goToDashboard = () => {
        if (cookie.loadAll().is_staff === 'true' || cookie.loadAll().superuser === 'true' ||
            (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).collapse_menu.scans || (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience) {
            historyMP.push('/' + globalUrlPrefix + '/dashboard');
        } else {
            historyMP.push('/' + globalUrlPrefix + '/cases');
        }
    }

    toggleAFMode = () => {
        if (!this.props.adminState.live_mode_action) {
            if (!this.props.adminState.doAutoFocus) {
                this.props.dispatch(updateTakeZStack(false));
                this.props.dispatch(updateDoAutoFocus(true));
                if (this.props.liveView.url != undefined && this.props.livemode.atLeastOneImageFetched && this.props.livemode.currentMapPosition[this.props.adminState.slotID] != undefined) {
                    this.takeStreak(true);
                }
            }
        } else {
            message.error("Action ongoing...");
        }
    }

    toggleZStackMode = () => {
        if (!this.props.adminState.live_mode_action) {
            if (!this.props.adminState.takeZStack) {
                this.props.dispatch(updateDoAutoFocus(false));
                this.props.dispatch(updateTakeZStack(true));
                if (this.props.liveView.url != undefined && this.props.livemode.atLeastOneImageFetched && this.props.livemode.currentMapPosition[this.props.adminState.slotID] != undefined) {
                    this.takeStreak(false);
                }
            }
        } else {
            message.error("Action ongoing...");
        }
    }
    
    toggleChat = () => {
        this.props.dispatch(toggleShowChat(!this.props.livemode.showChat));
    }

    takeStreak = (takeAF) => {
        let errorMessage = '';
        if (this.props.adminState.doDropDistanceAF) {
            errorMessage = liveModeLoadingMessages.DROP_DISTANCE_AF;
        } else if (this.props.adminState.doAutoFocus) {
            errorMessage = liveModeLoadingMessages.AUTO_FOCUS;
        } else {
            errorMessage = liveModeLoadingMessages.MOVE;
        }

        let partsOfUrl = "api~stage~move_and_focus";
        let url = `/server/scano_text/` + this.props.livemode.deviceId + `/` + partsOfUrl;

        if (this.props.adminState.slotID != -1) {
            url += '?slotID=' + this.props.adminState.slotID;
        } else {
            url += '?slotID=';
        }

        url += '&x=0'
        url += '&y=0'
        url += '&z=0'

        url += "&doDrop=false" + "&doAF=" + takeAF + "&numZStack=" + (this.props.livemode.zStackLevels * 2) +
            "&zStackStep=" + this.props.livemode.stepSizeZStack + "&timestamp=" + Date.now();

        let nearestTile = getNearestTile(this.props.livemode.currentMapPosition[this.props.adminState.slotID].x, this.props.livemode.currentMapPosition[this.props.adminState.slotID].y, this.props.livemode.tileCenters[this.props.adminState.slotID]);
        let nearestTileY = parseInt(nearestTile.split('_')[1]);

        url += `&nearestTileY=` + nearestTileY;

        this.props.dispatch(getLiveImageMoveAndFocus(url, errorMessage, this.props.adminState.slotID, 
            !takeAF, [], false, this.props.adminState.areaVisited, this.props.adminState.liveModePreviewVectorLayer, this.props.livemode.currentMapPosition));
    }

    doDropAndTakeStreak = () => {
        let partsOfUrl = "api~stage~move_and_focus_drop_distance";
        let url = `/server/scano_text/` + this.props.livemode.deviceId + `/` + partsOfUrl;
        if (this.props.adminState.slotID != -1) {
            url += '?slotID=' + this.props.adminState.slotID;
        } else {
            url += '?slotID=';
        }
        url += "&x=0&y=0&z=0&doDrop=true" +
            "&doAF=" + this.props.adminState.doAutoFocus + "&numZStack=" + (this.props.livemode.zStackLevels * 2) + 
            "&zStackStep=" + this.props.livemode.stepSizeZStack + "&timestamp=" + Date.now();

        let nearestTile = getNearestTile(this.props.livemode.currentMapPosition[this.props.adminState.slotID].x, this.props.livemode.currentMapPosition[this.props.adminState.slotID].y, this.props.livemode.tileCenters[this.props.adminState.slotID]);
        let nearestTileY = parseInt(nearestTile.split('_')[1]);

        url += `&nearestTileY=` + nearestTileY;

        url += `&doWB=false`;

        let errorMessage = '';
        if (this.props.adminState.doDropDistanceAF) {
            errorMessage = liveModeLoadingMessages.DROP_DISTANCE_AF;
        } else if (this.props.adminState.doAutoFocus) {
            errorMessage = liveModeLoadingMessages.AUTO_FOCUS;
        } else {
            errorMessage = liveModeLoadingMessages.MOVE;
        }

        this.props.dispatch(getLiveImageMoveAndFocusDropDistance(url, errorMessage, this.props.adminState.slotID, this.props.adminState.takeZStack, [], false, 
            this.props.adminState.areaVisited, this.props.adminState.liveModePreviewVectorLayer, this.props.livemode.currentMapPosition));
    }

    toggleSettings = () => {
        this.setState({
            showSettings: !this.state.showSettings,
        });
    }

    saveImages = () => {
        if (!this.props.adminState.live_mode_action) {
            if (this.props.liveView.url != undefined && this.props.livemode.atLeastOneImageFetched && this.props.livemode.currentMapPosition[this.props.adminState.slotID] != undefined) {
                this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.DOWNLOADING_IMAGES))
                let url = `/api/save_live_mode_images/?slotID=${this.props.adminState.slotID}&zstack=${this.props.adminState.takeZStack}&time=${Date.now()}`;
                axios
                    .get(url, { headers: { Authorization: AuthHeader() } })
                    .then(response => {
                        if (response.status == 200) {
                            let timeStamp = Date.now();
                            for(let image in response.data.images) {
                                this._download(response.data.images[image], image, timeStamp);
                            }
                        } else {
                            message.error("Could not download images. Contact Admin.");
                            console.log(response);
                        }
                        this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.DOWNLOADING_IMAGES));
                    })
                    .catch(err => {
                        message.error("Could not download images. Contact Admin.");
                        console.log(err);
                        this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.DOWNLOADING_IMAGES));
                    });
            } else {
                message.error("No images to download...");
            }
        } else {
            message.error("Action ongoing...");
        }
    }

    _download = (base64Image, fullName, timeStamp) => {
        let extension = fullName.split('.')[1];
        let name =  'image_at_level_' + fullName.split('.')[0]  + "_" + timeStamp + "." + extension;
        base64Image = 'data:image/' + extension + ';base64,' + base64Image;
        var anchor = document.createElement('a');
        anchor.href = base64Image;
        anchor.style.display = 'none';
        anchor.setAttribute('download', name);
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }
    
    toggleCoverSlipObjectiveUse = () => {
        if (!this.props.adminState.live_mode_action) {
            this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.SWITCHING_OBJECTIVE));
            let objectiveType = this.props.livemode.useCoverSlipObjective ? objectiveTypeLiveMode.NO_COVER_SLIP : objectiveTypeLiveMode.COVER_SLIP;
            let partsOfUrl = "api~settings~switch_objective_live_mode";
            let url = `/server/scano/` + this.props.livemode.deviceId + `/` + partsOfUrl + '/?time=' + Date.now() + `&objectiveType=` + objectiveType + `&slotID=` + this.props.adminState.slotID + `&accessCode=` + cookie.loadAll().livemode_access_code;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(updateExposure(response.data.exposureTime));
                    this.props.dispatch(updateGamma(response.data.gamma));
                    this.props.dispatch(updateUseCoverSlipObjective(!this.props.livemode.useCoverSlipObjective));
                    if (this.props.liveView.url != undefined && this.props.livemode.atLeastOneImageFetched && this.props.livemode.currentMapPosition[this.props.adminState.slotID] != undefined) {
                        this.doDropAndTakeStreak();
                    } else {
                        this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.SWITCHING_OBJECTIVE));
                    }
                }
                else {
                    console.log(response);
                    // message.error("Access Revoked!!", 2.5);
                    this.props.dispatch(updateAccessRevoked(true));
                    this.props.dispatch(updateAccessToLiveMode(false));
                    this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.SWITCHING_OBJECTIVE));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to switch objective!!", 2.5);
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.SWITCHING_OBJECTIVE));
            })
        } else {
            message.error("Action ongoing...");
        }
    }

    render() {

        return (
            <Row>
                <Col span={24} className="full-height relative-position align-center">
                    <br />
                    <Row>
                        <Col>
                            <Tooltip title="Go to Dashboard" placement="rightTop">
                                <img className="logo-square" src={logo_square_new} onClick={this.goToDashboard} />
                            </Tooltip>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <br />
                    <Row>
                        <Col>
                            <Tooltip title="Use continous Fine Focus" placement="rightTop">
                                <Button onClick={this.toggleAFMode}
                                    className={this.props.adminState.doAutoFocus ?
                                        "side-panel-icon side-panel-icon-open selected-style" : "side-panel-icon unselected-style"}
                                    style={!this.props.livemode.accessToLiveMode || this.props.livemode.useLiveModeInViewMode ?
                                        { borderBottomLeftRadius: 0, borderBottomRightRadius: 0, pointerEvents: 'none' } : { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                                >
                                    AF
                                    <Row className="livemode-focus-control-button-text">
                                        Autofocus
                                    </Row>
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Tooltip title="Use continous Z Stack" placement="rightTop">
                                <Button onClick={this.toggleZStackMode}
                                    className={this.props.adminState.takeZStack ?
                                        "side-panel-icon side-panel-icon-open selected-style" : "side-panel-icon unselected-style"}
                                    style={!this.props.livemode.accessToLiveMode || this.props.livemode.useLiveModeInViewMode ?
                                        { borderTopLeftRadius: 0, borderTopRightRadius: 0, pointerEvents: 'none' } : { borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                                >
                                    {/* <Icon type="database" /> */}
                                    Z
                                    <Row className="livemode-focus-control-button-text">
                                        Z Stack
                                    </Row>
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                    <br />
                    {/* <Row>
                        <Col>
                            <Tooltip title="Use cover slip objective" placement="rightTop">
                                <Button onClick={this.toggleCoverSlipObjectiveUse}
                                    className={this.props.livemode.useCoverSlipObjective ?
                                        "side-panel-icon side-panel-icon-open selected-style" : "side-panel-icon unselected-style"}
                                    style={!this.props.livemode.accessToLiveMode || this.props.livemode.useLiveModeInViewMode ?
                                        { borderBottomLeftRadius: 0, borderBottomRightRadius: 0, pointerEvents: 'none' } : { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                                >
                                    CS
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Tooltip title="Use no cover slip objective" placement="rightTop">
                                <Button onClick={this.toggleCoverSlipObjectiveUse}
                                    className={!this.props.livemode.useCoverSlipObjective ?
                                        "side-panel-icon side-panel-icon-open selected-style" : "side-panel-icon unselected-style"}
                                    style={!this.props.livemode.accessToLiveMode || this.props.livemode.useLiveModeInViewMode ?
                                        { borderTopLeftRadius: 0, borderTopRightRadius: 0, pointerEvents: 'none' } : { borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                                >
                                    NCS
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row> */}
                    <br />
                    <Row>
                        <Col>
                            <Tooltip title="Chat" placement="rightTop">
                                <Button onClick={this.toggleChat}
                                    className={this.props.livemode.showChat ?
                                        "side-panel-icon side-panel-icon-open selected-style" : "side-panel-icon unselected-style"}
                                >
                                    {/* <Icon type="database" /> */}
                                    <Icon type="wechat" />
                                    <Row className="livemode-focus-control-button-text">
                                        Chat
                                    </Row>
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Tooltip title={this.props.adminState.takeZStack ? "Download Stack" : "Download Image"} placement="rightTop">
                                <Button onClick={this.saveImages}
                                    className={"side-panel-icon unselected-style"}
                                >
                                    <Icon type="download" />
                                    <Row className="livemode-focus-control-button-text">
                                        Download
                                    </Row>
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row className="place-bottom" style={{ width: '100%' }}>
                        <Col>
                            <Tooltip title="Settings" placement="rightTop">
                                <Button onClick={this.toggleSettings} className={this.state.showSettings ? "side-panel-icon side-panel-icon-open selected-style" : "side-panel-icon unselected-style"} style={{ marginBottom: '0.6em' }}>
                                    <Icon type="setting" />
                                    <Row className="livemode-focus-control-button-text">
                                        Settings
                                    </Row>
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                    {this.props.livemode.accessToLiveMode || this.props.livemode.useLiveModeInViewMode ?
                        <Row className="mode-style" style={{ width: '100%' }}>
                            <Col>
                                <Button className="side-panel-icon unselected-style" style={{ marginBottom: '0.6em' }}>
                                    <Row className="livemode-focus-control-button-text overflow-wrap-text">
                                        {this.props.livemode.accessToLiveMode ? "Controller \n Mode" : "Observer \n Mode"}
                                    </Row>
                                </Button>
                            </Col>
                        </Row> : null
                    }
                    {this.state.showSettings ?
                        <Row className="settings-box-livemode">
                            <Col>
                                <LiveModeSettings toggleSettings={this.toggleSettings} />
                            </Col>
                        </Row> :
                        null
                    }
                    {this.props.livemode.showChat ?
                        <Row className="chat-box-livemode" style={{height: (window.visualViewport.height * 0.54) }}>
                            <Col style={{height: '100%'}}>
                                <Chat toggleChat={this.toggleChat}  />
                            </Col>
                        </Row> :
                        null
                    }
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        liveView: state.liveViewReducer,
        adminState: state.adminUrlReducer,
        livemode: state.livemodeReducer,
    };
};

export default connect(mapStateToProps)(LiveModeSidePanel);
