import axios from "axios";
import {AuthHeader} from "../../../helper/auth.token";
import {message} from 'antd';
import {Map, View,} from "ol";
import {Projection} from 'ol/proj.js'
import TileLayer from "ol/layer/Tile";
import ImageLayer from "ol/layer/Image";
import TileImage from 'ol/source/TileImage';
import TileGrid from 'ol/tilegrid/TileGrid';
import {ScaleLine} from 'ol/control.js';
import {defaults, MouseWheelZoom} from 'ol/interaction';
import {getPrefixedUrl, getSuffixUrl} from '../../../utils/utils';
import * as keys from '../../neoviewer/apps/app_keys';
import Static from "ol/source/ImageStatic";
import {mapLayerTypes} from "../../../utils/const";
import {store} from "../../../helper/store";
import {updateInvert, updateSlideState} from "../../../action/maps.state.action";

const screenSizeString = window.screen.width + 'x' + window.screen.height;

export const getSlideAndInitialiseMapState = (value, setState, activeMap, syncViews, mapId, mapState, urlState,
                                              updateMapState, updateLayers, onMapRenderingComplete,
                                              scrollActionOnMap) => {
    setState({
        isFetching: true
    });
    
    let txtyInfoUrl = `/api/get_txty_info/?id=${value}`;
    let slideUrl = `/api/slide/${value}/`;
    let backgroundColorUrl = `/api/get_background_color/?id=${value}`

    let headers = {
        headers: {
            Authorization: AuthHeader()
        }
    };

    let slideDataRequest = axios.get(slideUrl, headers);
    let txtyInfoRequest = axios.get(txtyInfoUrl, headers);
    let backgroundColorRequest = axios.get(backgroundColorUrl, headers);

    axios.all([
          slideDataRequest,
          txtyInfoRequest, 
          backgroundColorRequest
      ])
      .then(axios.spread((slideDataResponse, txtyInfoResponse,
                          backgroundColorResponse) => {

        if (backgroundColorResponse.status === 200) {
            let backgroundColor = backgroundColorResponse.data['background_color'];
            setState({
                backgroundColor: 'rgb(' + backgroundColor[0] + ',' + backgroundColor[1] + ',' + backgroundColor[2] + ')'
            })
        }

        if (slideDataResponse.status === 200 && txtyInfoResponse.status === 200) {
            let slide_data = slideDataResponse.data;
            axios.get(getPrefixedUrl(`${slide_data.path}meta/slide_meta.json`,
                slide_data))
                .then(response => loadMap(slide_data, response.data, setState, activeMap, syncViews, mapId, mapState,
                    urlState, updateMapState, txtyInfoResponse.data, updateLayers, onMapRenderingComplete,
                    scrollActionOnMap))
                .catch(error => {
                    console.warn("Slide Meta was not found", error);
                    loadMap(slide_data, {}, setState, activeMap, syncViews, mapId, mapState, urlState,
                        updateMapState, txtyInfoResponse.data, updateLayers, onMapRenderingComplete, scrollActionOnMap)
                })
        } else {
           setState({
               isErrored: true,
               isFetching: false, 
               errMessage: slideDataResponse.data
           });
         }
      }))
      .catch((err) => {
        console.log("Error occured")
        message.error("Slide could not be found. Contact Admin.");
        console.log(err)
        setState({
            isErrored: true,
            isFetching: false, 
            errMessage: err,
        });
      });
}

export const loadMap = (slideData, slideMeta, setState, activeMap, syncViews, mapId, mapState, urlState, updateMapState,
                        txtyInfo, updateLayers, onMapRenderingComplete, scrollActionOnMap) => {
    let imageInfo = {};
    if(JSON.stringify(txtyInfo) !== JSON.stringify({})) {
        try {
            imageInfo = getImageInfo(slideData, txtyInfo);
        } catch (e) {
            imageInfo = {}
        }
    }
    initialiseMap(slideData, slideMeta, setState, activeMap, syncViews, mapId, mapState, urlState, updateMapState,
        imageInfo, txtyInfo, updateLayers, onMapRenderingComplete, scrollActionOnMap);
}

export const recreateMapLayers = (slidemap, viewLevelsInfo, slide_data, imageInfo, projection, resolutions, txtyInfo, 
    tileSize, updateMapState, setState, zStackLevel, minZLevel, maxZLevel, takeZStack) => {
    let nextZoom = slidemap.getView().getZoom();
    let urlSuffix = getSuffixUrl(slide_data, isZoomForTiled(nextZoom, viewLevelsInfo), zStackLevel);
    let url = getPrefixedUrl(urlSuffix, slide_data);
    let viewport = slidemap.getView().calculateExtent(slidemap.getSize());
    let newLayers;

    // console.log('called', viewport);

    if (isZoomForTiled(nextZoom, viewLevelsInfo)) {
        let tiledZoom = nextZoom - (viewLevelsInfo.lastStitchedLevel - viewLevelsInfo.lastTiledLevel);
        newLayers = getImageLayers(url, tiledZoom, imageInfo, slide_data, txtyInfo, viewport,
            viewLevelsInfo.lastTiledLevel, zStackLevel, minZLevel, maxZLevel, takeZStack);
        let allOldLayers = slidemap.getLayers().getArray();

        // console.log('new layers -');

        // for (let ni in newLayers) {
        //     console.log(newLayers[ni].values_.posI + "_" + newLayers[ni].values_.posJ + "_" + newLayers[ni].values_.posZ);
        // }

        // console.log('old layers -', allOldLayers.length);

        let layersToRemove = [];

        for (let oi in allOldLayers) {

            // console.log(allOldLayers[oi]);
            let foundInNewLayers = false;
            let layerFoundAtIndex = -1;
            let oldLayer = allOldLayers[oi];
            
            for (let i = 0; i < newLayers.length; i++) {
                let newLayer = newLayers[i];
                if (oldLayer.values_.posI === newLayer.values_.posI && 
                    oldLayer.values_.posJ === newLayer.values_.posJ) {
                    if (oldLayer.values_.posZ !== newLayer.values_.posZ) {
                        let oldUrlParts = oldLayer.getSource().getUrl().split('/');
                        oldUrlParts[oldUrlParts.length - 2] = newLayer.values_.posZ;
                        oldLayer.setSource(new Static({
                            imageExtent: oldLayer.getSource().getImageExtent(),
                            url: oldUrlParts.join('/'),
                            imageSize: imageInfo.imageSizes[newLayer.values_.posZ],
                        }))
                        oldLayer.values_.posZ = newLayer.values_.posZ;
                    }
                    foundInNewLayers = true;
                    layerFoundAtIndex = i;
                }
            }
            
            // console.log(allOldLayers[oi]);
            // console.log(foundInNewLayers + "_" + layerFoundAtIndex + "_" + oi + "_" + oldLayer.values_.posI + "_" + oldLayer.values_.posJ + "_" + oldLayer.values_.posZ);

            if (foundInNewLayers) {
                newLayers.splice(layerFoundAtIndex, 1);
            } else if (oldLayer.values_.name !== mapLayerTypes.STITCHED_LAYER_NAME &&
                oldLayer.values_.name !== mapLayerTypes.ANNOTATION_LAYER_NAME) {
                layersToRemove.push(oi);
            } else if (oldLayer.values_.name === mapLayerTypes.ANNOTATION_LAYER_NAME) {
                oldLayer.setVisible(true);
            } else {
                oldLayer.setVisible(false);
            }
        }

        layersToRemove.reverse();
        
        // console.log('deleting -', layersToRemove);

        for (let oi in layersToRemove) {
            let oldLayer = allOldLayers[layersToRemove[oi]];
            // if (oldLayer.values_.posI != undefined) {
            //     console.log(oldLayer.values_.posI + "_" + oldLayer.values_.posJ + "_" + oldLayer.values_.posZ);
            // }
            slidemap.removeLayer(oldLayer);
        }

        // console.log('remaining old layers -')
        // allOldLayers = slidemap.getLayers().getArray();

        // for (let oi in allOldLayers) {
        //     let oldLayer = allOldLayers[oi];
        //     console.log(oldLayer.values_.posI + "_" + oldLayer.values_.posJ + "_" + oldLayer.values_.posZ);
        // }

        // console.log('remaining new layers - ')

        // for (let ni in newLayers) {
        //     console.log(newLayers[ni].values_.posI + "_" + newLayers[ni].values_.posJ + "_" + newLayers[ni].values_.posZ);
        // }
        
        for(let index in newLayers) {
            slidemap.addLayer(newLayers[index]);
        }   
    } else {
        let allOldLayers = slidemap.getLayers().getArray();
        let stitchedLayerExists = false;
        let layersToRemove = [];
        
        for (let oi in allOldLayers) {
            let oldLayer = allOldLayers[oi];
            if (oldLayer.values_.name === mapLayerTypes.STITCHED_LAYER_NAME) {
                stitchedLayerExists = true;
                oldLayer.setVisible(true);
            } else if (oldLayer.values_.name === mapLayerTypes.ANNOTATION_LAYER_NAME) {
                oldLayer.setVisible(true);
            } else if (oldLayer.values_.name === mapLayerTypes.TILED_LAYER_NAME) {
                layersToRemove.push(oi);
            }
        }

        layersToRemove.reverse();
        
        for (let oi in layersToRemove) {
            let oldLayer = allOldLayers[layersToRemove[oi]];
            slidemap.removeLayer(oldLayer);
        }

        if (!stitchedLayerExists) {
            newLayers = getLayer(tileSize, projection, resolutions, url);
            
            for(let index in newLayers) {
                slidemap.addLayer(newLayers[index]);
            }   
        } else {

        }
    }
    
    setState({
        layer: newLayers,
    }); 

    updateMapState();

    return newLayers;
}

const initialiseMap = (slide_data, slide_meta, setState, activeMap, syncViews, mapId, mapState, urlState,
                       updateMapState, imageInfo, txtyInfo, updateLayers, onMapRenderingComplete, scrollActionOnMap) =>
{
    let tileSize = getTileSize(slide_data, false);
    let zoomLevels = getZoomLevels(slide_data);
    let resolutions = getResolutions(slide_data, zoomLevels);
    let maxZoom = zoomLevels.length - 1;

    let imageShape = getImageShape(slide_data);
    let projection = getProjection(imageShape);
    let view = getView(projection, resolutions, mapState, urlState, imageShape, slide_data.viewer_rotation);
    view.on(['change:center', 'change:resolution','change:rotation'], syncViews);

    let zoom = mapState.z;
    
    let splitStitchedLevels = slide_data.z_levels.split(',');
    let lastStitchedLevel = parseInt(splitStitchedLevels[splitStitchedLevels.length - 1]);
    let tiled_levels = [];
    for (let i = 0; i < lastStitchedLevel - 2; i++) {
        tiled_levels.push(i);
    }
    let splitTiledLevels = slide_data.tiling_z_levels != null ? slide_data.tiling_z_levels.split(',') : tiled_levels;
    let lastTiledLevel = parseInt(splitTiledLevels[splitTiledLevels.length - 1]);
    let numberOfTiledLevels = lastTiledLevel - slide_data.smallest_tiled_level + 1;

    let viewLevelsInfo = {
        lastStitchedLevel,
        numberOfTiledLevels,
        lastTiledLevel,
        smallestTiledLevel: slide_data.smallest_tiled_level
    }

    let urlSuffix = getSuffixUrl(slide_data, isZoomForTiled(zoom, viewLevelsInfo), 0);
    let url = getPrefixedUrl(urlSuffix, slide_data);
    
    let layer;
    if (isZoomForTiled(zoom, viewLevelsInfo))
        layer = getImageLayers(url, zoom, imageInfo, slide_data, txtyInfo, [0,0,0,0], lastTiledLevel,
            0, -5, 5);
    else
        layer = getLayer(tileSize, projection, resolutions, url);

    let slidemap = getMap(view, layer, true, scrollActionOnMap);
    let zoomScale =  getZoomScale(slide_data, maxZoom, urlState);
    let desiredZoomScale = getDesiredZoomScale(slide_data, maxZoom);
    let ZValues =  getZValue(zoomScale, desiredZoomScale);

    slidemap.addControl(
        new ScaleLine({
            units: "metric",
            minWidth: 70,
        })
    );

    slidemap.addEventListener('moveend', () => {
        updateLayers();
    }, false);

    slidemap.on('pointerdown', activeMap);

    slidemap.on('rendercomplete', () => {
        onMapRenderingComplete();
    });

    // Keyboard Events
    slidemap.on('keydown', (event) => {
        if (!store.getState().gammaStateReducer.keyboardShortcutsEnable ||
            event.originalEvent.target.tagName === 'INPUT' || event.originalEvent.shiftKey ||
            event.originalEvent.ctrlKey || event.originalEvent.altKey) return;
        switch (event.originalEvent.code) {
            case 'KeyZ':
                view.setZoom(view.getZoom() * 2 > view.getMaxZoom() ? view.getMinZoom() + 1 : view.getMaxZoom());
                break;
            case 'KeyI':
                store.dispatch(updateInvert(mapId, slide_data.viewer_settings.id,
                    slide_data.viewer_settings.invert > 50 ? 0 : 100));
                break;
            case 'KeyR':
                view.setRotation((Math.floor(view.getRotation() * 2 / Math.PI) + 1) % 4 * Math.PI / 2);
                break;
            default:
                break;
        }
    });

    store.dispatch(updateSlideState(mapId, {
        slide_data,
        slide_meta,
        isFetching: false,
        maxZoom,
        zoomLevels,
        zoomScale,
        view,
        layer,
        slidemap,
        ZValues,
        desiredZoomScale,
        imageShape,
        extent: projection.getExtent(),
        viewLevelsInfo,
        imageInfo,
        projection,
        txtyInfo,
        tileSize,
        resolutions,
    }));
    // TODO: Move the complete slideState to reducer only one by one, access these variable from reducer to avoid replacement of other component updates
    setState({
            isFetching: false,
            maxZoom,
            zoomLevels,
            zoomScale, 
            view, 
            layer, 
            slidemap, 
            ZValues, 
            desiredZoomScale,
            imageShape,
            extent: projection.getExtent(),
            viewLevelsInfo,
            imageInfo,
            projection,
            txtyInfo,
            tileSize,
            resolutions,
        });
}

const getTileSize = (slide_data, stackMode) => {
    let tileSize;
    if (slide_data.specimen_type === "blood" || stackMode) {
        tileSize = [slide_data.tile_width, slide_data.tile_height];
    } else {
        tileSize = [slide_data.stitched_tile_width, slide_data.stitched_tile_height];
    }
    return tileSize;
}

const getImageShape = (slide_data) => {
    let imageWidth, imageHeight;
    if (slide_data.specimen_type === "blood") {
        imageWidth = slide_data.uperpixel * slide_data.tile_width * slide_data.x_fields;
        imageHeight = slide_data.uperpixel * slide_data.tile_height * slide_data.y_fields;
    } else if (slide_data.specimen_type === "preview") {
        imageWidth = slide_data.x_step * slide_data.x_fields;
        imageHeight = slide_data.y_step * slide_data.y_fields;
    } else {
        imageWidth = slide_data.uperpixel * slide_data.stitched_tile_width * slide_data.stitched_x_max;
        imageHeight = slide_data.uperpixel * slide_data.stitched_tile_height * slide_data.stitched_y_max;
    }
    return [imageWidth, imageHeight];
}

export const getZValue = (zoomScale, desiredZoomScale) => {

    let ZValues = [];
    for(let i = 0; i < desiredZoomScale.length; i++) {
        if(desiredZoomScale[i] === zoomScale[i]) {
            ZValues[i] = i;
        } else {
            ZValues[i] = i + ((desiredZoomScale[i] - zoomScale[i]) / (zoomScale[i + 1] - zoomScale[i]));
        }
    }

    return ZValues;
}

const getImageInfo = (slide_data) => {
    let imageSizes = [[slide_data.tile_width, slide_data.tile_height]];
    let splitTiledLevels = slide_data.tiling_z_levels.split(',');
    let lastTiledLevel = parseInt(splitTiledLevels[splitTiledLevels.length - 1]);
    let imageInfo = {
        xcount: slide_data.x_fields,
        ycount: slide_data.y_fields,
        zcount: lastTiledLevel + 1,
        totalImages: slide_data.total_images
    }
    for( let i = 1; i < imageInfo.zcount; i++) {
        imageSizes.push([
            Math.floor(imageSizes[i-1][0]/2),
            Math.floor(imageSizes[i-1][1]/2),
        ]);
    }
    imageSizes.reverse();
    imageInfo.imageSizes = imageSizes;

    return imageInfo;
}

export const isZoomForTiled = (zoom, viewLevelsInfo) => {
    if (viewLevelsInfo.smallestTiledLevel === -1) {
        return false;
    }
    return zoom > viewLevelsInfo.lastStitchedLevel - viewLevelsInfo.numberOfTiledLevels;
}

export const getImageLayers = (urlSuffix, zoom, imageInfo, slideData, txtyInfo, viewport, maxZoom, zStackLevel,
                               minZLevel, maxZLevel, takeZStack) => {
    let imageType = slideData.img_type;
    let uperpixel  =slideData.uperpixel;
    let posList = {};
    let mstCountMap = {};
    let layers = [];

    let imageShape = getImageShape(slideData)
    let baseCanvasY = imageShape[1];
    let minY = baseCanvasY;

    let xKey = 'X';
    let yKey = 'Y';
    let mstCountKey = 'displayOrder';
    
    for(let image in txtyInfo) {
        let xi = txtyInfo[image]["x_index"];
        let yi = txtyInfo[image]["y_index"];

        let posInfo = txtyInfo[image]["pos"];

        let xleft = posInfo[xKey] * uperpixel;
        let xright = (posInfo[xKey] + imageInfo.imageSizes[maxZoom][0]) * uperpixel;
        let yleft = baseCanvasY - (posInfo[yKey] + imageInfo.imageSizes[maxZoom][1]) * uperpixel;
        let yright = baseCanvasY - (posInfo[yKey]) * uperpixel;
        if (!isNaN(xleft)) {
            if (xright > viewport[0] && xleft < viewport[2] && yright > viewport[1] && yleft < viewport[3]) {
                posList[xi + "|" + yi] = [ xleft, yleft, xright, yright];
                mstCountMap[xi + "|" + yi] = posInfo[mstCountKey];
                if (minY > yleft)
                    minY = yleft;
            }
        }
    }

    for(let pos in posList) {
        let posI = pos.split("|")[0];
        let posJ = pos.split("|")[1];
        if (takeZStack) {
            for(let i = minZLevel; i <= maxZLevel; i++) {
                if(i !== 0) {
                    urlSuffix = getSuffixUrl(slideData, true, i);
                    urlSuffix = getPrefixedUrl(urlSuffix, slideData);
                }
                const imageLayer = new ImageLayer({
                    source: new Static({
                        imageExtent: posList[pos],
                        url: `${urlSuffix}/${zoom}/x${posI}y${posJ}.${imageType}`,
                        imageSize: imageInfo.imageSizes[zoom],
                    }),
                    posI: posI,
                    posJ: posJ,
                    posZ: zoom, 
                    posS: i,
                    zIndex: mstCountMap[pos]*100, 
                    name: mapLayerTypes.TILED_LAYER_NAME,
                    visible: i === zStackLevel,
                });
        
                layers.push(imageLayer);
            }
        } else {
	    console.log("urlSuffix--", urlSuffix);
            const imageLayer = new ImageLayer({
                source: new Static({
                    imageExtent: posList[pos],
                    url: `${urlSuffix}/${zoom}/x${posI}y${posJ}.${imageType}`,
                    imageSize: imageInfo.imageSizes[zoom],
                }),
                posI: posI,
                posJ: posJ,
                posZ: zoom, 
                posS: 0,
                zIndex: mstCountMap[pos]*100, 
                name: mapLayerTypes.TILED_LAYER_NAME,
            });
        
            layers.push(imageLayer);
        }
    }
    
    return layers;
}

export const getProjection = (imageShape) => {

    return new Projection({
        code: 'MORPHLE',
        units: 'microns',
        extent: [0, 0, imageShape[0], imageShape[1]],
        metersPerUnit: 0.000001,
        global: true,
        getPointResolution: (resolution, point) => resolution,
    });
}

export const getView = (projection, resolutions, mapState, urlState, imageShape, viewer_rotation) => {

    let zoomRatio = (((urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {})[screenSizeString]
        || 1;

    if(zoomRatio < 1 || (urlState || {}).presentCode !== undefined) {
        zoomRatio = 1;
    }

    let maxZoom = mapState.digitalZoomStatus ? (resolutions.length - 1) : (resolutions.length - 2);

    return new View({
        projection: projection,
        extent: projection.getExtent(),
        center: mapState.x === -1 ? [imageShape] : [mapState.x, mapState.y],
        zoom: mapState.z,
        maxResolution: resolutions[0],
        maxZoom: zoomRatio > 1 ? maxZoom + 1 : maxZoom,
        rotation: mapState.r !== -1 ? mapState.r : ((viewer_rotation * Math.PI) / 180)
    });
}

export const getLayer = (tileSize, projection, resolutions, url) => {
    console.log("extent", projection.getExtent())
    let layer = new TileLayer({
        extent: projection.getExtent(),
        source: new TileImage({
            tileGrid: new TileGrid({
                extent: projection.getExtent(),
                origin: [0, projection.getExtent()[3]],
                resolutions: resolutions,
                tileSize: tileSize,
            }),
            projection: projection,
            url: url,
            wrapX: false,
            crossOrigin: 'anonymous'
        }),
        name: mapLayerTypes.STITCHED_LAYER_NAME
    });

    return [layer];
}

export const getMap = (view, layers, setKeyboardInteractions, scrollActionOnMap) => {

    return new Map({
        controls: [],
        interactions: defaults({mouseWheelZoom: false, pinchZoom: false}).extend([
            new MouseWheelZoom({
                constrainResolution: true, // force zooming to a integer zoom
                condition: scrollActionOnMap
            })
        ]),
        // interactions: defaults({mouseWheelZoom: false, pinchZoom: false}),
        target: null,
        layers,
        view: view,
        keyboardEventTarget: setKeyboardInteractions ? document : null,
        loadTilesWhileAnimating: true,
        loadTilesWhileInteracting: true
    });
}

const getZoomLevels = (slide_data) => slide_data.z_levels.split(",");

export const getResolutions = (slide_data, zoomLevels) => {

    let resolutions = [];
    (zoomLevels).forEach((level) => {
        resolutions.push(slide_data.uperpixel * Math.pow(2, parseInt(level)));
    });

    resolutions = resolutions.reverse();

    return resolutions;
}

export const getZoomScale = (slide_data, max_zoom, urlState) => {
    var scale = [];
    let zoomRatio = (((urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {})[screenSizeString]
        || 1;
    if(zoomRatio < 1 || (urlState || {}).presentCode !== undefined) {
        zoomRatio = 1;
    }
    let max = (0) + parseInt(max_zoom);
    let i = max - 1;
    if (slide_data.objective_type === "hundred_x") {
	    scale[max] = 200;
    } else if (slide_data.objective_type === "twenty_x") {
	    scale[max] = 40;
    } else if (slide_data.objective_type === "sixty_x") {
	    scale[max] = 120;
    } else {
      scale[max] = 80;
    }
    for (i; i > -1; i--) {
      scale[i] = parseFloat((scale[i + 1] / 2).toFixed(1));
    }
    for(i = 0; i < scale.length; i++) {
        scale[i] = parseFloat((scale[i] / zoomRatio).toFixed(1));
    }
    if(zoomRatio !== 1) {
        if (slide_data.objective_type === "hundred_x") {
            scale.push(parseFloat((400 / zoomRatio).toFixed(1)));
        } else if (slide_data.objective_type === "twenty_x") {
            scale.push(parseFloat((80 / zoomRatio).toFixed(1)));
        } else if (slide_data.objective_type === "sixty_x") {
            scale.push(parseFloat((240 / zoomRatio).toFixed(1)));
        } else {
            scale.push(parseFloat((160 / zoomRatio).toFixed(1)));
        }
    }
    return scale;
}

export const get2XZoomScale = (slide_data, max_zoom, urlState) => {
    var scale = [];
    let zoomRatio = (((urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {})[screenSizeString]
        || 1;
    if(zoomRatio < 1 || (urlState || {}).presentCode !== undefined) {
        zoomRatio = 1;
    }
    let max = (0) + parseInt(max_zoom);
    let i = max - 1;
    if (slide_data.objective_type === "hundred_x") {
	    scale[max] = 400;
    } else if (slide_data.objective_type === "twenty_x") {
	    scale[max] = 80;
    } else if (slide_data.objective_type === "sixty_x") {
	    scale[max] = 240;
    } else {
      scale[max] = 160;
    }
    for (i; i > -1; i--) {
      scale[i] = parseFloat((scale[i + 1] / 2).toFixed(1));
    }
    for(i = 0; i < scale.length; i++) {
        scale[i] = parseFloat((scale[i] / zoomRatio).toFixed(1));
    }
    if(zoomRatio !== 1) {
        if (slide_data.objective_type === "hundred_x") {
            scale.push(parseFloat((400 / zoomRatio).toFixed(1)));
        } else if (slide_data.objective_type === "twenty_x") {
            scale.push(parseFloat((80 / zoomRatio).toFixed(1)));
        } else if (slide_data.objective_type === "sixty_x") {
            scale.push(parseFloat((240 / zoomRatio).toFixed(1)));
        } else {
            scale.push(parseFloat((160 / zoomRatio).toFixed(1)));
        }
    }
    console.log(scale)
    return scale;
}

export const getDesiredZoomScale = (slide_data, max_zoom) => {
    var scale = [];
    let max = (0) + parseInt(max_zoom);
    let i = max - 1;
    if (slide_data.objective_type === "hundred_x") {
	    scale[max] = 200;
    } else if (slide_data.objective_type === "twenty_x") {
	    scale[max] = 40;
    } else if (slide_data.objective_type === "sixty_x") {
	    scale[max] = 120;
    } else
    {
      scale[max] = 80;
    }
    for (i; i > -1; i--) {
      scale[i] = parseFloat((scale[i + 1] / 2).toFixed(1));
    }
    return scale;
}

