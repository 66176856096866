import React, { Component } from 'react';
import { connect } from "react-redux";
import _, { map } from 'lodash';
import { debounce, throttle } from 'lodash';
import { Col, Row, Button, Icon, message, Statistic } from 'antd';
import { updateUrlStateInReducer, updateMapParams, updateOnStitchedLayer, updateZStackLevel } from "../../action/morpheus.state.action"
import { getSlideAndInitialiseMapState, getQuadrant, getTileNumber, getTiledImageSizeInMicrons, recreateMapLayers, isZoomForTiled } from './map_utils'
import { loadMorpheusSettings, updateMorphleID, updateAppClosedStatus, updateTileCoordinate, updateQuadrant } from "../../action/morpheus.state.action"
import { ExclamationOutlined } from '@ant-design/icons';
import AppBoard from './appboard'
import logo from "../../asset/img/logo-mp.png"
import "../../asset/style/neoviewer/slidemap.css"
import cookie from 'react-cookies';
import { getErrorComponent } from '../dashboard/slidelist_utils';
import { AuthHeader } from "../../helper/auth.token";
import axios from "axios";
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style.js';
import * as keys from '../neoviewer/apps/app_keys'
import { globalUrlPrefix, mapLayerTypes } from '../../utils/const'
import { getPrefixedUrl, convertSettingsToFilter, checkIfMobile } from '../../utils/utils';
import { slideViewerType } from "../../utils/const";
import { MouseWheelZoom } from 'ol/interaction';
import ImageLayer from 'ol/layer/Image';
import { urls } from '../../helper/backend.urls';
import { Vector as VectorLayer } from 'ol/layer.js';
import {AllAnnotationsConstants} from '../../consts/anno.const'
import {click} from 'ol/events/condition.js';
import Select from 'ol/interaction/Select.js';
import  DragPan  from 'ol/interaction/DragPan.js';
import { feature } from '@turf/turf';
import View from 'ol/View';
import {addAnnotation, removeAnnotations, saveAnnotations, updateLoadingState, updateTotalArea, updateSavedCount, updateSlideState, updateClusterId, getWholeCluster} from '../../action/brainAnno.action'
import { brainAnnoGridReducer } from '../../reducer/brainAnno.reducer';
import { annoUpdate } from '../../reducer/anno.reducer';
import { getFormattedArea, } from '../../utils/utils';
// import Select from 'ol/interaction/Select';

var querystring = require('querystring');


const selectedStyle = new Style({
  stroke: new Stroke({
      color: "green", 
      width: 1
  }),
    fill: new Fill({
      color: 'rgba(165, 252, 3, 0.2)'
  })
})

const otherStyle = new Style({
  stroke: new Stroke({
      color: "yellow", 
      width: 2
  }),
    fill: new Fill({
      color: 'rgba(255, 0, 0, 0.0)'
  })
})

const styleFunction = (feature) => {
  // if (feature.getProperties().color != undefined && feature.getProperties().color != "") {
  //     baseStyleWithText.getStroke().setColor(feature.getProperties().color);
  //     baseText.getFill().setColor(feature.getProperties().color);
  // }
  // baseStyleWithText.setText(baseText);
  if (feature.get('selected')=="true") {
      //console.log("stylechange1")
      return [
          selectedStyle
      ]
  } else {
      return [
          otherStyle
      ]
  }
}

class SlideMapBrain extends Component {
  
  constructor(props) {
    super(props);

    this.screenSizeString = window.screen.width + 'x' + window.screen.height;

    this.state = {
      slide: -1,
      isFetching: true,
      isErrored: false,
      loadedMap: false, 
      mapId: this.props.map_id,
      stackMode: 0,
      modalVisible: false,
      backgroundColor: "#FFFFFF",
      shiftScrollCount: 0,
      mapRenderComplete: false,
      x:'',
      y:'',
      z:'',
      r:'',
      cluster:'',
      l_x:'',
      l_y:'',
      l_z:'',
      l_r:'',
      l_cluster:'',
      l_fetch_all:'',
      selectedAnnotations:[],
      oldView:'',
      last_retrieved_url_state:'',
      ai_annotations:[],
      locked_view: false,
      fetching:false,
    };

    this.vector = new VectorLayer({
        source: new VectorSource({}),
        name: mapLayerTypes.BRAIN_META_VIEWER_ANNOTATION_LAYER_NAME,
      });
  
    this.vector.set('annotationLayer', true);

    this.selectHover = new Select({
      condition: click, 
      layers: [this.vector], 
      wrapX: false,
      hitTolerance: 5,
      style: new Style({
          stroke: new Stroke({
              color: 'rgba(0, 0, 0, 0.0)',
          }),
          fill: new Fill({
              color: 'rgba(0, 0, 0, 0.0)'
          })
      })
    });
    this.lastCachingCall = -1;
    this.upperZLevelsCached = 0;
    this.lowerZLevelsCached = 0;
    
    this.previewMapRef = null;
    this.props.dispatch(loadMorpheusSettings());
    this.props.dispatch(updateUrlStateInReducer(this.props.urlState));
    this.imageDownloadTimer = null;

    this.cacheZStackLayers = debounce(this.cacheZStackLayers.bind(this), 1000);
    this.checkSlideViewChange = debounce(this.checkSlideViewChange.bind(this), 400);
    
    if (cookie.loadAll().isMobile === 'true') {
      window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }
    // source = axios.CancelToken.source();
    this.cancel_req_source = axios.CancelToken.source();
  }

  

  drawAnnotations = (anno) => {
        let vector = this.vector
        // assuming some annos came from ai_backend
        // let annotations = [{
        //   anno_drawer: 0,
        //   answer: null,
        //   area: 0,
        //   center: "",
        //   color: "#7CFC00",
        //   created_z_level: "",
        //   creator: 1,
        //   id: 4030,
        //   meta: {},
        //   new_bounds: "[[[3730.2511999999997,2059.9488],[4057.2271999999994,1662.1280000000002],[4585.8384,2114.4448],[4416.900799999999,2784.7456],[3866.4911999999995,2790.1952000000006],[3730.2511999999997,2059.9488]]]",
        //   num_tiles_height: 0,
        //   num_tiles_width: 0,
        //   perimeter: 0,
        //   question: null,
        //   shape: "polygon",
        //   slide: 3066,
        //   status: 1,
        //   tile_height: 0,
        //   tile_width: 0,
        //   title: "R1 0.672sq.mm",
        //   tool_type: "Polygon",
        //   z_levels: "",
        //   selected: "false",
        // }]
        let annotations = anno;
        //console.log("anno",anno,annotations)

        let showAnno = true;
        let allFeatures = [];
        //console.log("annos",annotations)
        if(annotations==null || annotations==undefined){
          return;
        }
        annotations.map((annotation, index) => {
            let isSelected = "false";
            for(let i=0;i<this.props.brainAnno.selected_annotations.length;i++){
              if(this.props.brainAnno.selected_annotations[i].id == annotation.id){
                isSelected="true";
              }
            }
            let feature;
            if (annotation.new_bounds !== null) {
                if (annotation.shape === AllAnnotationsConstants.POLYGON) {
                    feature = new Feature({
                        geometry: new Polygon(JSON.parse(annotation.new_bounds)),
                        id: annotation.id,
                        name: annotation.shape,
                        title: annotation.title,
                  });
                }
                // //console.log("areaxxx " + feature.getGeometry().getArea());
                // //console.log("asdddd " + annotation.area);
                feature.setId(annotation.id);
                feature.set('creator', annotation.anno_drawer);
                // feature.set('selected', annotation.id == selectedAnnotationId);
                feature.set('color', annotation.color);
                feature.set('new_bounds', annotation.new_bounds);
                feature.set('slide', annotation.slide);
                feature.set('title', "BM "+getFormattedArea(feature.getGeometry().getArea()));
                feature.set('tool_type', annotation.tool_type);
                feature.set('selected', isSelected);
                feature.set('area', feature.getGeometry().getArea())
                allFeatures.push(feature);
            }
            return feature;
        });
        //console.log("features",annotations,allFeatures);
        vector.setSource(new VectorSource({
            features: allFeatures,
            wrapX: false
        }))
    
        vector.setStyle(styleFunction);
  }

  addSelectInteraction = () => {
    this.state.slidemap.addInteraction(this.selectHover);
    this.selectHover.on("select", e=>{
      // //console.log("selected",e.selected,e.selected[0].get('selected'))
      // //console.log("title",e.selected[0].get('title'))
      //console.log("on all click", e.selected.length)
      // if(e.selected.length == 0){
      //   message.info("Please save or cancel selected annotations to unlock the screen")
      // }
      let selectedAnnos=[];
      let selectedAnnotations = this.props.brainAnno.selected_annotations;
      for(let i=0; i<e.selected.length; i++){
        let feature = e.selected[i];
        let to_remove=false;
        // //console.log("feature", feature.get('selected'))
        if(feature.get('selected').toString()=="true"){
          feature.set("selected", "false")
          to_remove=true;
        }
        // //console.log("feature",feature.get('id'), selectedAnnotations,to_remove)
        let annotation = {
          anno_drawer: 2,//yet to be thought about
          answer: null,
          area: feature.get('area'),
          center: "",
          color: feature.get('color'),
          created_z_level: "",
          creator: cookie.loadAll().user_id,
          id: feature.getId(),
          meta: {},
          new_bounds: feature.get('new_bounds'),
          num_tiles_height: 0,
          num_tiles_width: 0,
          perimeter: 0,
          question: null,
          shape: "polygon",
          slide: feature.get('slide'),
          status: 1,
          tile_height: 0,
          tile_width: 0,
          title: feature.get('title'),
          tool_type: "Polygon",
          z_levels: "",
          selected: "true",
        }
        if(to_remove){
          for(let i=0;i<selectedAnnotations.length;i++){
            if(selectedAnnotations[i].id == feature.get('id')){
              //console.log("got_selected_annos--")
              selectedAnnotations.splice(i,1);
              break;
            }
          }
        }else{
          selectedAnnotations.push(annotation);
        }
      }
      
      // let combinedAnnos = selectedAnnos.concat(selectedAnnotations);
      //console.log("got_selected_annos", selectedAnnotations)
      // for(let i=0;i<selectedAnnos.length; i++){
      //   let found = false;
      //   for(let j=0; j<selectedAnnotations.length; j++){
      //       if(selectedAnnotations[j].id == selectedAnnos[i].id){
      //         found=true;
      //         this.drawAnnotations(this.state.ai_annotations);
      //         return;
      //       }
      //   }
      //   if(!found){
      //     selectedAnnotations.push(selectedAnnos[i]);CASEations))
      if(selectedAnnotations.length == 0){
        this.setState({locked_view: false,})
      //   this.state.slidemap.getInteractions().forEach(x => {
      //       x.setActive(true);
      // })
    }
      //console.log("got_selected_annos2",this.props.brainAnno.selected_annotations)
      if(this.props.brainAnno.selected_annotations.length==1){
        //console.log("change");
        this.setState({
          // oldView: this.state.slidemap.getView(),
          locked_view: true,
        })
      //   var newView = new View({
      //     projection: this.state.slidemap.getView().getProjection(),
      //     extent: this.vector.getSource().getExtent(),
      //     // showFullExtent: true,
      //     center: this.state.slidemap.getView().getCenter(),
      //     zoom: this.state.slidemap.getView().getZoom(),
      //     maxZoom: this.state.slidemap.getView().getZoom(),
      //     minZoom: this.state.slidemap.getView().getZoom(),
      //     rotation: this.state.slidemap.getView().getRotation(),
      //  });
      //  this.state.slidemap.getInteractions().forEach(x => {
      //   if (x instanceof Select) {
      //     //console.log("active",x)
      //     x.setActive(true);
      //   }else{
      //     x.setActive(false);
      //   }
      //   });
      //  this.state.slidemap.setView(newView);
      }
      this.setState({
        selectedAnnotations: selectedAnnotations,
      })
      this.drawAnnotations(this.state.ai_annotations);
    })
  }

  addPreviewMapControl = (previewMapRef) => this.previewMapRef = previewMapRef;

  fetchAIResult = () => {
    // //console.log("fetch ai results",this.props.urlState, this.state)
    if(this.props.brainAnno.selected_annotations.length!=0 && this.state.l_cluster == this.props.brainAnno.cluster_id && this.state.l_fetch_all ==this.props.brainAnno.get_whole_cluster){
      return;
    }
    if((this.state.l_x==this.state.x && this.state.l_y==this.state.y &&this.state.l_z==this.state.z && this.state.l_r==this.state.r && this.state.l_cluster == this.props.brainAnno.cluster_id && this.state.l_fetch_all ==this.props.brainAnno.get_whole_cluster)){// || this.state.locked_view){
      //console.log("xxxx")
      return;
    }
    let urlState = this.props.urlState
    if(this.state.x == urlState.x && this.state.y == urlState.y && this.state.z == urlState.z && this.state.r == urlState.r){
      //send request to ai backend
        //make an axios request here
        // 
     //console.log("yaha1")
      let url = `/api/get_brain_polygons/`
     this.setState({
       fetching: true,
     })
     let config = { cancelToken: this.cancel_req_source.token}
     this.props.dispatch(updateLoadingState(true));
      axios.post(url,querystring.stringify({'extent':this.state.slidemap.getView().calculateExtent(this.state.slidemap.getSize()), 'slide':this.props.slide_id, 'z':this.props.urlState.z,'cluster_id':this.props.brainAnno.cluster_id,'fetch_all':this.props.brainAnno.get_whole_cluster,
    'user_id': cookie.loadAll().user_id}), { headers: { Authorization: AuthHeader() }}).then(res => {
          //console.log("res",res)
          this.setState({
            ai_annotations:res.data,
            l_x:this.state.x,
            l_y:this.state.y,
            l_z:this.state.z,
            l_r:this.state.r,
            l_cluster: this.state.cluster,
            fetching:false,
            l_fetch_all: this.props.brainAnno.get_whole_cluster,
          })
          this.props.dispatch(updateLoadingState(false));
          // this.state.slidemap.getLayers().forEach(layer => {
          //   if(layer.get('name') && layer.get('name') == mapLayerTypes.BRAIN_META_VIEWER_ANNOTATION_LAYER_NAME){
          //     this.state.slidemap.removeLayer(layer);
          //   }
          // });
          //console.log("show_annos",res.data)
          this.drawAnnotations(res.data);//send res.data instead of ai_annotations
          return;
      }).catch(err => {
        this.props.dispatch(updateLoadingState(false));
      })
      // this.state.slidemap.getLayers().forEach(layer => {
      //   if(layer.get('name') && layer.get('name') == mapLayerTypes.BRAIN_META_VIEWER_ANNOTATION_LAYER_NAME){
      //     this.state.slidemap.removeLayer(layer);
      //   }
      // });
      // //console.log("show_annos")
      // this.state.slidemap.addLayer(this.vector);  
      // this.addSelectInteraction();
      // this.drawAnnotations(this.state.ai_annotations);
      // //console.log("making an axios request")
      
      // //console.log("now send req to backend")
    }
  }

  checkSlideViewChange = () => {
    if(this.props.brainAnno.selected_annotations.length!=0){
      return;
    }
    // //console.log("xxx")
    let urlState = this.props.urlState
    // //console.log("url_state", this.props.urlState,this.state)
    if(this.state.x == urlState.x && this.state.y == urlState.y && this.state.z == urlState.z && this.state.r == urlState.r && !this.state.fetching) {//&& !this.state.locked_view){
      //send request to ai backend
      setTimeout(this.fetchAIResult,500)
      //console.log("now send req to backend")
    }
    else{
      this.vector.setSource(new VectorSource({
        features: [],
        wrapX: false
    }))
    }
    // else if(this.state.locked_view){
      // this.drawAnnotations(1);//here give ai fetched annos
    // }
    this.setState({
      x:urlState.x,
      y:urlState.y,
      z:urlState.z,
      r:urlState.r,
    })
  }
          
  updateUrlState = () => {
    let tiledLayer = isZoomForTiled(this.state.view.getZoom(), this.state.viewLevelsInfo);
    if (!tiledLayer) {
      this.props.dispatch(updateZStackLevel(0));
    }
    this.props.dispatch(updateOnStitchedLayer(!tiledLayer));
    let zoomRatio = (((this.props.urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {})[this.screenSizeString] || 1;
    if(zoomRatio > 1 && Number.isInteger(this.state.view.getZoom()) && this.state.view != undefined && (this.props.urlState || {}).presentCode == undefined) {
      if(this.state.view.getZoom() <= this.state.view.getMaxZoom() - 1) {
        this.state.view.setZoom(this.state.ZValues[this.state.view.getZoom()]);
      } else if(this.state.view.getZoom() <= this.state.view.getMaxZoom()) {
        this.state.view.setZoom(this.state.ZValues[this.state.view.getZoom() - 1]);
      }
    }

    this.props.dispatch(updateMapParams(
      this.props.urlState,
      this.state.view.getCenter()[0],
      this.state.view.getCenter()[1],
      this.state.view.getZoom(),
      this.state.view.getRotation(),
    ));
  };

  updateLayers = () => {
    this.setState({
      mapRenderComplete: false,
    });

    let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels) / 2 : (this.props.urlState.numZLevels);
    let minZLevel = this.props.urlState.takeBidirectionalZStack ?  (-1 * ((this.props.urlState.numZLevels) / 2)) : 0;
    
    minZLevel = Math.floor(minZLevel);
    maxZLevel = Math.floor(maxZLevel);

    recreateMapLayers(this.state.slidemap, this.state.viewLevelsInfo, this.state.slide_data, this.state.imageInfo, this.state.projection, 
      this.state.resolutions, this.state.txtyInfo, this.state.tileSize, this.updateUrlState, (obj) => {
        obj.addPreviewMapControl = this.addPreviewMapControl;
        obj.goHome = this.goHome;
        this.setState(obj)}, this.props.urlState.zStackLevel, minZLevel, maxZLevel, this.props.urlState.takeZStack);
  }

  onMapRenderingComplete = () => {
    this.setState({
      mapRenderComplete: true,
    });
  }

  cacheZStackLayers = () => {
    //console.log("Caching started")
    this.lastCachingCall = Date.now();
    let lastCachingCall = this.lastCachingCall;
    this.upperZLevelsCached = 0;
    this.lowerZLevelsCached = 0;
    let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels - 1) / 2 : (this.props.urlState.numZLevels - 1);
    let timerInterval  = 1000;
    let timer = 0;
    for(let i = 1; i <= maxZLevel + 1; i++) {
      let layers = this.state.slidemap.getLayers().getArray();
      setTimeout(() => this.cacheLayer(layers, i, lastCachingCall), timer);
      timer += timerInterval;
    }
  }

  cacheLayer = (layers, zLevel, lastCachingCall) => {
    if (lastCachingCall == this.lastCachingCall) {
      for(let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        if (layer.values_.posS == (-1 * zLevel) || layer.values_.posS == zLevel) {
          layer.setVisible(true);
        }
      }
      setTimeout(() => this.layerVisibilityOff(layers), 10);
      this.upperZLevelsCached = this.upperZLevelsCached + 1;
      this.lowerZLevelsCached = this.lowerZLevelsCached - 1;
    }
  }

  layerVisibilityOff = (layers) => {
    for(let i = 0; i < layers.length; i++) {
      let layer = layers[i];
      if(layer.values_.posS == this.props.urlState.zStackLevel || layer.values_.name == mapLayerTypes.ANNOTATION_LAYER_NAME || layer.values_.name == mapLayerTypes.STITCHED_LAYER_NAME || layer.values_.name == mapLayerTypes.BRAIN_META_VIEWER_ANNOTATION_LAYER_NAME) {
        layer.setVisible(true);
      } else {
        layer.setVisible(false);
      }
    }
  }

  cacheZoomLevels = (upper, layers) => {
    let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels - 1) / 2 : (this.props.urlState.numZLevels - 1);
    if (upper && this.upperZLevelsCached < maxZLevel) {
      for(let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        if (layer.values_.posS == (this.upperZLevelsCached + 1) || layer.values_.posS == (this.upperZLevelsCached + 2)) {
          layer.setVisible(true);
        }
      }
      setTimeout(() => this.layerVisibilityOff(layers), 10);
      this.upperZLevelsCached = this.upperZLevelsCached + 2;
    } else if(!upper && this.lowerZLevelsCached > (-1 * maxZLevel)) {
      for(let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        if (layer.values_.posS == (this.lowerZLevelsCached - 1) || layer.values_.posS == (this.lowerZLevelsCached - 2)) {
          layer.setVisible(true);
        }
      }
      setTimeout(() => this.layerVisibilityOff(layers), 10);
      this.upperZLevelsCached = this.lowerZLevelsCached - 2;
    }
  }

  scrollActionOnMap = (e) => {
    if (e.type !== "wheel") {
      return true;
    } else {
      let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels - 1) / 2 : (this.props.urlState.numZLevels - 1);
      let minZLevel = this.props.urlState.takeBidirectionalZStack ?  (-1 * ((this.props.urlState.numZLevels - 1) / 2)) : 0;

      if (this.props.urlState.takeZStack && e.originalEvent.shiftKey) {
        if(this.props.urlState.onStitchedLayer) {
          message.error("Z Stack only allowed on higher zoom levels.", 2.5);
        } else {
          if (e.originalEvent.deltaY > 0 && this.props.urlState.zStackLevel > minZLevel) {
            this.props.dispatch(updateZStackLevel(this.props.urlState.zStackLevel - 1));
            this.setState({
              shiftScrollCount: this.state.shiftScrollCount + 1,
            });
            this.cacheZoomLevels(true, this.state.slidemap.getLayers().getArray());
          } else if (e.originalEvent.deltaY < 0 && this.props.urlState.zStackLevel < maxZLevel) {
            this.props.dispatch(updateZStackLevel(this.props.urlState.zStackLevel + 1));
            this.setState({
              shiftScrollCount: this.state.shiftScrollCount + 1,
            });
            this.cacheZoomLevels(false, this.state.slidemap.getLayers().getArray());
          }
        }
        return false;
      } else {
        return true;
      }
    }
  }

  componentDidMount = (prevState) => {
    // let zIndex = (this.props.slide.x_fields * 10000) + this.props.slide.y_fields + 1000000000;
    // let zIndex = 100000000000;
    // this.vector.setZIndex(zIndex);
      // getSlideAndInitialiseMapState(this.props.slide_id, (obj) => {
      //   obj.goHome = this.goHome;
      //   obj.addPreviewMapControl = this.addPreviewMapControl;
      //   this.setState(obj)
      // }, this.props.urlState, this.updateUrlState);
  }

  componentDidUpdate = (prevProps, prevState) => {
    // if(prevState.slide_data!=this.state.slide_data){
    //   let zIndex = (this.state.slide_data.x_fields * 10000) + this.state.slide_data.y_fields + 1000000000;
    //   this.vector.setZIndex(zIndex);
    // }
    // //console.log("props",prevProps.brainAnno, this.props.brainAnno)
    // if(prevState.saved_annotations.length != this.state.saved_annotations.length){
    //   this.drawAnnotations(this.state.ai_annotations)
    // }
    // if(prevProps.brainAnno.selected_annotations.length == 0 && this.props.brainAnno.selected_annotations.length!=0){
    //     //console.log("change");
    //     this.setState({
    //       oldView: this.state.slidemap.getView(),
    //     })
    //     var newView = new View({
    //       projection: this.state.slidemap.getView().getProjection(),
    //       extent: this.vector.getSource().getExtent(),
    //       // showFullExtent: true,
    //       center: this.state.slidemap.getView().getCenter(),
    //       zoom: this.state.slidemap.getView().getZoom(),
    //       maxZoom: this.state.slidemap.getView().getZoom(),
    //       minZoom: this.state.slidemap.getView().getZoom(),
    //       rotation: this.state.slidemap.getView().getRotation(),
    //    });
    //    this.state.slidemap.setView(newView);
    //   }

    if(prevState != this.state){
      this.props.dispatch(updateSlideState(this.state))
    }

    if(prevProps.brainAnno.get_whole_cluster != prevProps.brainAnno.get_whole_cluster){
      this.fetchAIResult()
    }

    if(prevProps.brainAnno.cluster_id != this.props.brainAnno.cluster_id){
      
      this.setState({
        cluster: this.props.brainAnno.cluster_id,
      })
      this.fetchAIResult()
    }

    if(prevProps.brainAnno.selected_annotations.length!=0 && this.props.brainAnno.selected_annotations.length==0){
      //console.log("unlock")
      this.setState({
        locked_view: false,
      })
      // this.state.slidemap.setView(this.state.oldView);
      this.drawAnnotations(this.state.ai_annotations);
      // this.state.slidemap.getInteractions().forEach(x => x.setActive(true));
      this.checkSlideViewChange();
    }

    if(this.props.ai_mode){
      this.checkSlideViewChange();
    }
    if(prevState.mapRenderComplete != this.state.mapRenderComplete) {
      //here send empty annos to draw
      
      if(this.state.mapRenderComplete) {
        //console.log("Map rendered complete")
        if(!this.state.locked_view){
        //   //console.log("remove anno")
        //   this.addSelectInteraction();
        this.setState({
          fetching: false,
        })
        this.props.dispatch(updateLoadingState(false));
        this.cancel_req_source.cancel("map changed")
        this.props.dispatch(updateClusterId(0));
        this.props.dispatch(getWholeCluster(false))
        this.setState({
          l_cluster : '',
          l_fetch_all: '',
        })
        this.vector.setSource(new VectorSource({
            features: [],
            wrapX: false
        }))
        }
        if (!this.props.urlState.onStitchedLayer) { 
          this.cacheZStackLayers();
        } else {
          this.lastCachingCall = Date.now();
        }
      }
    }

    if(prevProps.urlState.zStackLevel != this.props.urlState.zStackLevel) {
      this.setState({
        fetching: false,
      })
      this.props.dispatch(updateLoadingState(false));
      this.cancel_req_source.cancel("map changed")
      if(!this.state.locked_view){
      this.props.dispatch(updateClusterId(0));
      this.props.dispatch(getWholeCluster(false))
        this.setState({
          l_cluster : '',
          l_fetch_all: '',
        })
      this.vector.setSource(new VectorSource({
        features: [],
        wrapX: false
    }))}
      let layers = this.state.slidemap.getLayers().getArray();
      //console.log("layers",layers)
      for(let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        layer.setVisible(true);
      }
      for(let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        if(layer.values_.posS == this.props.urlState.zStackLevel || layer.values_.name == mapLayerTypes.ANNOTATION_LAYER_NAME || layer.values_.name == mapLayerTypes.STITCHED_LAYER_NAME
          || layer.values_.name == mapLayerTypes.BRAIN_META_VIEWER_ANNOTATION_LAYER_NAME) {
          layer.setVisible(true);
        } else {
          layer.setVisible(false);
        }
      }
    }

    if(prevProps.urlState.apps_initialised != this.props.urlState.apps_initialised && !this.state.loadedMap) {
      getSlideAndInitialiseMapState(this.props.slide_id, (obj) => {
        obj.addPreviewMapControl = this.addPreviewMapControl;
        obj.goHome = this.goHome;
        this.setState(obj)
      }, this.props.urlState, this.updateUrlState, this.updateLayers, this.onMapRenderingComplete, this.scrollActionOnMap);

      
      
      // this.addSelectInteraction();
    }

    if (!this.state.loadedMap && !this.state.isFetching && !this.state.isErrored) {
      //console.log("loaded-map---");
      this.state.slidemap.setTarget("map-" + this.props.map_id);
        if (this.previewMapRef != undefined) {
          //console.log("Preview Map Was Undefined!!!");
          this.state.slidemap.addControl(this.previewMapRef);
        }
        this.setState({
          loadedMap: true
        })
        //console.log("loaded-map");
        let zIndex = (this.state.slide_data.x_fields * 1000000) + this.state.slide_data.y_fields + 1000000000;
        this.vector.setZIndex(zIndex);
        this.state.slidemap.addLayer(this.vector);  
        this.addSelectInteraction();
        this.state.slidemap.on("DragPan",()=> {
          if(this.props.brainAnno.selected_annotations.length>0){
            message.info("messge")
            return false;
          }
        })
        this.props.dispatch(updateMorphleID(this.state.slide_data.morphle_id, this.state.slide_data.id));
       
        // if (this.props.urlState.takeZStack) {
        //   document.getElementById("map-" + this.props.map_id).addEventListener('wheel', this.scrollActionOnMap, false);
        // } else {
        //   this.state.slidemap.addInteraction(new MouseWheelZoom());
        // }

        // let self = this;
        // let url = getPrefixedUrl(this.state.slide_data.bucket_name + "/" + this.state.slide_data.path + 'meta/slide_meta.json', this.state.slide_data);
        // axios.get(url)
        //   .then(slideMetaRes => {
        //     let stackimage ={};
        //     if(slideMetaRes.data.takeZStack) {
        //         this.state.slidemap.addEventListener('singleclick', (e) => {
        //             if(self.props.urlState.selectedShape == null) {
        //               let tileCoord = getTileNumber(e.coordinate, self.state.slide_data, self.state.imageLog, self.state.imageShape);
        //               if(tileCoord[0] != -1) {
        //                   stackimage.size = getTiledImageSizeInMicrons(self.state.slide_data);
        //                   stackimage.tileCoord = tileCoord;
        //                   self.setState({
        //                       stackimage,
        //                   });
        //                   self.props.dispatch(updateTileCoordinate(tileCoord));
        //                   let quadrant = getQuadrant(e.coordinate, tileCoord, self.state.slide_data, self.state.imageShape, self.state.imageLog, self.state.view.getZoom(), self.state.maxZoom);
        //                   self.props.dispatch(updateQuadrant(quadrant));

        //                   let bounds = self.getBounds(tileCoord, quadrant);


        //                   let style = new Style({
        //                     stroke: new Stroke({
        //                         color: '#50ace9',
        //                     }),
        //                     fill: new Fill({
        //                         color: 'rgba(0, 0, 0, 0.0)'
        //                     })
        //                   });

        //                   let feature = new Feature({
        //                       geometry: new Polygon(bounds),
        //                   });

        //                   feature.setStyle(style);
        //                   // feature.set('color', 'black');
        //                   self.state.vectorLayer.setSource(new VectorSource({
        //                       features: [feature],
        //                       wrapX: false
        //                   }));

        //                   let time = 100;
        //                   let initialStart = slideMetaRes.data.takeBidirectionalZStack ? -2 : 1;
        //                   let initialEnd = slideMetaRes.data.takeBidirectionalZStack ? 2 : 4;
        //                   let finalStart = slideMetaRes.data.takeBidirectionalZStack ? (-1 * ((slideMetaRes.data.numZLevels - 1) / 2)) : 5;
        //                   let finalEnd = slideMetaRes.data.takeBidirectionalZStack ? ((slideMetaRes.data.numZLevels - 1) / 2) : slideMetaRes.data.numZLevels - 1;
        //                   for(let i = initialStart; i <= initialEnd; i++) {
        //                     let urlSuffix = `${self.state.slide_data.bucket_name}/${self.state.slide_data.path}stack`;
        //                     let url = getPrefixedUrl(urlSuffix, self.state.slide_data);
        //                     url = `${url}/x${self.props.urlState.tileCoord[0]}y${self.props.urlState.tileCoord[1]}/${i}.${self.state.slide_data.img_type}`;
        //                     self.imageDownloadTimer = setTimeout(() => self.downloadImage(url), time);
        //                     time += 100;
        //                   }
        //                   time += 500;
        //                   for(let i = finalStart; i <= finalEnd; i++) {
        //                     if(i < -2 && i > 2) {
        //                       let urlSuffix = `${self.state.slide_data.bucket_name}/${self.state.slide_data.path}stack`;
        //                       let url = getPrefixedUrl(urlSuffix, self.state.slide_data);
        //                       url = `${url}/x${self.props.urlState.tileCoord[0]}y${self.props.urlState.tileCoord[1]}/${i}.${self.state.slide_data.img_type}`;
        //                       self.imageDownloadTimer = setTimeout(() => self.downloadImage(url), time);
        //                       time += 100;
        //                     }
        //                   }
        //               }
        //             }
        //         }, false)
        //     }
        //   })
        //   .catch(err => {
        //     //console.log("Failed request", err);
        //   });
      if (this.props.urlState.x === -1 ) this.goHome();
    }
  }

  downloadImage = (url) => {
    let image = new Image();
    image.src = url;
  }

  componentWillUnmount = () => {
    let url = `/api/clean_sync_browsing/?sync_code=${((this.props.urlState.app_state || {})['present_app'] || {}).code}&morphle_id=${this.state.slide_data.morphle_id}`;
      axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
        })
        .catch(err => {
          //console.log("Failed Cleaning");
        });
  }

  getBounds = (tileCoord, quadrant) => {
    let imageName = "x" + tileCoord[0] + "y" + tileCoord[1] + ".jpg";
    let maxZoom = parseInt(this.state.slide_data.z_levels.split(",")[this.state.slide_data.z_levels.split(",").length - 1]);
    let divisions = Math.pow(2, this.props.urlState.z == maxZoom ? 2: this.props.urlState.z == maxZoom - 1 ? 1 : 0);
    let widthThreshold = (2049 * this.state.slide_data.uperpixel) / divisions;
    let heightThreshold = (2449 * this.state.slide_data.uperpixel) / divisions;
    let xOffset = this.props.urlState.z >= maxZoom - 1 ? quadrant[0] : 0;
    let yOffset = this.props.urlState.z >= maxZoom - 1 ? quadrant[1] : 0;

    if (this.state.imageLog["map"]["stitching_info"]["map"][imageName] != undefined) {
      let imagePos = this.state.imageLog["map"]["stitching_info"]["map"][imageName]["map"]["Absolute Position"]["map"];
      let pointA = [(xOffset * widthThreshold) + (imagePos["X"] * this.state.slide_data.uperpixel), (-1 * yOffset * heightThreshold) + this.state.imageShape[1] - ((imagePos["Y"] * this.state.slide_data.uperpixel) + heightThreshold)];
      let pointB = [(xOffset * widthThreshold) + (imagePos["X"] * this.state.slide_data.uperpixel) + widthThreshold, (-1 * yOffset * heightThreshold) + this.state.imageShape[1] - ((imagePos["Y"] * this.state.slide_data.uperpixel) + heightThreshold)];
      let pointC = [(xOffset * widthThreshold) + (imagePos["X"] * this.state.slide_data.uperpixel) + widthThreshold, (-1 * yOffset * heightThreshold) + this.state.imageShape[1] - (imagePos["Y"] * this.state.slide_data.uperpixel)];
      let pointD = [(xOffset * widthThreshold) + (imagePos["X"] * this.state.slide_data.uperpixel), (-1 * yOffset * heightThreshold) + this.state.imageShape[1] - (imagePos["Y"] * this.state.slide_data.uperpixel)];
      return [[pointA, pointB, pointC, pointD, pointA]];
    } else {
      return [[]];
    }
  }

  openZoomAdjustmentApp = (e) => {
    this.props.dispatch(updateAppClosedStatus(keys.displaySizeCalibrationKey.id, false, this.props.urlState));
    e.stopPropagation();
  }

  stopViewing = (e) => {
    if(this.props.loginAlerts.is_staff || this.props.loginAlerts.superuser) {
      window.location.href = "/" + globalUrlPrefix + "/dashboard/";
    } else {
      window.location.href = "/" + globalUrlPrefix + "/cases/";
    }
  }

  toggleModal = () => {
      let modalVisible = !this.state.modalVisible;
      this.setState({
          modalVisible,
      });
  }

  updateTileCoordinates = (tileCoordinates) => {
    let stackimage = Object.assign({}, this.state.stackimage);
    stackimage.tileCoord = tileCoordinates;
    this.setState({
      stackimage,
    });
  }

  goHome = () => {
    if (this.state.layer != undefined) {
      this.state.slidemap.getView().fit(this.state.extent);
    }
  }

  render = () => {
    // //console.log("fetch", this.state.saved_annotations, this.props.brainAnno.count_saved_annotations, 
    // this.props.brainAnno.total_area_saved_annotations)
    //if(this.state.slidemap != undefined)
      //console.log("interactions", this.state.slidemap.getInteractions())
    // //console.log("selected_annos", this.state.selectedAnnotations);
    // //console.log("url", this.props.urlState)
    //console.log("slidedata", this.state.slide_data)
    //console.log("brain_data", this.state.slide)
    //if(this.state.slidemap!=undefined)
        //console.log("abc",this.state.slidemap.getView().calculateExtent(this.state.slidemap.getSize()),this.props.urlState.z,this.props.urlState.r)
    let isMObile = checkIfMobile();

    if (this.state.isErrored) {
      //console.log(this.state.errMessage);
    }
    return (
      this.state.isFetching || this.state.isErrored ? <div>Loading</div> :
        isNaN(this.props.urlState.x) || isNaN(this.props.urlState.y) || isNaN(this.props.urlState.z) || isNaN(this.props.urlState.r) ?
        getErrorComponent() :
        <div>
          <div className={(this.props.urlState || {}).presentCode != undefined ? "no-pointer-activity" : ""}>
            <Row className="slide-map-row"> 
                <Col className="slide-map-col">
                  <Row>
                      {(this.props.urlState || {}).presentCode != undefined ?
                        <div>
                          <div className="border-divider border-divider-top"></div>
                          <div className="border-divider border-divider-left"></div>
                          <div className="border-divider border-divider-right"></div>
                          <div className="border-divider border-divider-bottom"></div>
                        </div>
                        : null
                      }
                      <AppBoard slideState={this.state} updateTileCoordinates={this.updateTileCoordinates} viewerType={slideViewerType.NORMAL_SLIDE_VIEWER} showIcon={false}/>
                      {(this.props.urlState || {}).presentCode != undefined ?
                      <Button className="stop-presentation" type='danger' onClick={this.stopViewing}>
                        Exit Presentation
                      </Button> :
                      null
                      }
                      <Col tabIndex="0" id={"map-" + this.props.map_id} className="slide-map-container" style={{backgroundColor: this.state.backgroundColor}}>
                        <div id="myposition"></div>
                        {(this.props.urlState || {}).presentCode != undefined ? null : <img className="morphle-watermark" id="morphle-watermark" src={logo}></img>}
                        {(((this.props.urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {})[this.screenSizeString] == undefined 
                          && !(cookie.load('morpheus_setting') || {}).is_audience ? 
                          <div className="adjustment-error">
                              <Button type='danger' onClick={this.openZoomAdjustmentApp}>
                                <Icon type="exclamation" style={{color:'white'}} /> Different screen size detected. Click to adjust the size.
                              </Button>
                          </div>
                          : null
                        }
                        {/* {this.state.shiftScrollCount <= 10 && !isMObile && this.props.urlState.takeZStack ?
                          <div className="main-background-color loading-message-slidemap">
                            <b style={{ color: 'white' }}>Use SHIFT + Mouse Scroll Wheel to navigate between Z-Stack Layers</b>
                          </div>
                          : null
                        } */}
                      </Col>
                  </Row>
                </Col>
            </Row>
          </div>
        </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    urlState: state.viewerUrlReducer,
    loginAlerts: state.loginAlerts,
    brainAnno: state.brainAnnoGridReducer,
  };
};

export default connect(mapStateToProps)(SlideMapBrain);
