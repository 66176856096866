import { CiTwoTone } from "@ant-design/icons";
import { FieldViewActions } from "../actionTypes/fieldView.constant";

const InitialState = {
    page: '',
    update_image: false,
    selected_grid: '',
    page_size:'',
    all_grids:[],
    zStack: 0,
}

export const fieldViewReducer = (state = InitialState, action) => {
    switch(action.type){
        case FieldViewActions.UPDATE_PAGE:
            console.log("page", action.response)
            return Object.assign({}, state, {
                page: action.response,
            })
        case FieldViewActions.FILTER_CHANGE_IMAGE:
            console.log("hfjisdhfj", action.response)
            return Object.assign({}, state, {
                update_image:action.response,
            })
        case FieldViewActions.FILTER_GRID_SELECTED:
            console.log("hfjisdhfj", action.response)
            return Object.assign({}, state, {
                selected_grid: action.response,
            })
        case FieldViewActions.UPDATE_PAGE_SIZE:
            return Object.assign({}, state, {
                page_size: action.response,
            })
        case FieldViewActions.UPDATE_ALL_GRIDS:
            return Object.assign({}, state, {
                all_grids: action.response,
            })
        case FieldViewActions.UPDATE_Z_STACK:
            return Object.assign({}, state, {
                zStack: action.response
            })
        case FieldViewActions.RESET_Z_STACK:
            return Object.assign({}, state, {
                zStack: 0,
            })
        case FieldViewActions.RESET:
            return Object.assign({}, state, {
                page: '',
                update_image: false,
                selected_grid: '',
                page_size:'',
                all_grids:[],
                zStack: 0,
            })
        default :
            return state
    }
}