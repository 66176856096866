import React, { Component } from "react";
import ReactDOM from "react-dom";
import BloodSlideMap from "../component/neoviewer/bloodslidemap";
import queryString from "query-string";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { AuthHeader } from "../helper/auth.token";
import { closeDrawer } from "../action/dashboard.action";
import { makeSlideRead } from "../action/slides.action";
import {
  Spin,
  Progress,
  Row,
  Col,
  Button,
  Typography,
  message,
  Input,
  Tooltip,
} from "antd";
import Popover from "@mui/material/Popover";
import { globalUrlPrefix, bloodClass } from "../utils/const";
import { logout } from "../action/login.action";
import { BloodSummary } from "./bloodsummary";
import { BloodGraph } from "./bloodgraphview";
import { bloodClassChanged } from "../action/bloodGrid.action";
import {
  resetWBC,
  resetZStackWBC,
  wbcGridSelected,
  updatePageWBCView,
} from "../action/wbcview.action";
import {
  reset,
  resetZStack,
  fieldGridSelected,
  updatePageFieldView,
} from "../action/fieldView.action";
import { FieldGridView } from "./fieldegridview";
import { Field100xView } from "./field100xView";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  PicRightOutlined,
  PictureOutlined,
  LoadingOutlined,
  EditOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import SettingsIcon from "@mui/icons-material/Settings";
import { FaSortAmountUp, FaSortAmountDown } from "react-icons/fa";
import logo_neo from "../asset/img/morphle-updated-logo.png";
import "antd/dist/antd.css";
import "../asset/style/workflow/tab-pane.css";
import {
  rbcGridSelected,
  updateSelectedGridOverallId,
  updatePageRBCView,
  updateFetchingGridRBC,
} from "../action/bloodGridRBC.action";
import {
  pltGridSelected,
  updateSelectedGridOverallIdPLT,
  updatePagePLT,
  updateFetchingGridPLT,
} from "../action/bloodGridPLT.action";
import { FieldGridViewConfused } from "./fieldegridviewconfused";
import { FieldGridViewRbc } from "./fieldegridviewrbc";
import { FieldGridViewPlt } from "./fieldegridviewplt";
import BloodSettingsApp from "../component/bloodviewer/settings";

var querystring = require("querystring");

// const {TabPane} = Tabs;
const { Text } = Typography;
export class BmaScan40x extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stitching_done: true,
      stitch_percent: 0.0,
      stitch_time: 0.0,
      scan_ongoing: false,
      isFetching: true,
      errorCount: 0,
      blood_class: bloodClass.FIELD,
      navigate: false,
      selected_anno: "",
      view_mode: 1,
      slide_name: "",
      currZStackIndex: "",
      differential: false,
      confirmed: true,
      nameEditable: false,
      slide_data: "",
      size_asc: true,
      uneven_asc: false,
      elongation_asc: false,
      plt_size_asc: true,
      mean_color_asc: false,
      settingsAnchorEl: null,
    };

    this.max_txty_time = 0;
    this.timer = null;
    this.query_params = queryString.parse(this.props.location.search);
    this.slide_id = this.props.match.params.id;
    document.addEventListener("keydown", this.pressRight);
    document.addEventListener("keydown", this.pressLeft);
    document.addEventListener("keydown", this.pressUp);
    document.addEventListener("keydown", this.pressDown);
  }

  pressRight = (event) => {
    if (!event.shiftKey && event.keyCode === 39) {
      this.loadNext(0);
    }
  };
  pressLeft = (event) => {
    if (!event.shiftKey && event.keyCode === 37) {
      this.loadPrev(0);
    }
  };
  pressUp = (event) => {
    if (!event.shiftKey && event.keyCode === 38) {
      this.loadPrev(1);
    }
  };
  pressDown = (event) => {
    if (!event.shiftKey && event.keyCode === 40) {
      this.loadNext(1);
    }
  };

  getDifferentialStatus = () => {
    let url = `/api/get_differential_status/?slide_id=${this.slide_id}`;
    axios.get(url).then((res) => {
      this.setState({
        differential: res.data["differential"],
      });
    });
  };

  componentDidMount = () => {
    this.props.dispatch(makeSlideRead(this.props.match.params.id));
    this.pollForStitchingStatus();
    this.timer = setInterval(() => this.pollForStitchingStatus(), 2000);
    this.props.dispatch(closeDrawer());
    this.getSlideData();
    this.getSlideName();
    this.getDifferentialStatus();
  };

  getSlideData = () => {
    let slideUrl = `/api/slide/${this.props.match.params.id}/`;
    let headers = {
      headers: {
        Authorization: AuthHeader(),
      },
    };
    axios.get(slideUrl, headers).then((res) => {
      console.log("slidedata2", res.data);
      this.setState({
        slide_data: res.data,
      });
    });
  };

  getSlideName = () => {
    let url = `/api/get_slide_name/?id=${this.props.match.params.id}`;
    axios.get(url).then((res) => {
      this.setState({
        slide_name: res.data["name"],
      });
    });
  };

  setSlideName = (slidename) => {
    let val = {
      slidename: slidename,
      slide_id: this.props.match.params.id,
    };
    let url = `/api/set_slide_name/`;
    axios
      .post(url, queryString.stringify(val), {
        headers: { Authorization: AuthHeader() },
      })
      .then((response) => {
        if (
          response.status === 200 ||
          response.status === 301 ||
          response.status === 302
        ) {
          message.success("Name updated successfully", 1.5);
        } else {
          message.info("Failed to edit Name");
        }
      });
  };

  componentDidUpdate = (prevProps) => {
    if (this.state.errorCount === 3) {
      this.props.dispatch(logout());
    }
    if (prevProps.wbcGridData.tag !== this.props.wbcGridData.tag) {
      this.setState({
        confirmed: true,
      });
    }
  };

  pollForStitchingStatus = () => {
    this.setState({
      isFetching: true,
    });

    let url = "/server/slide_status?id=" + this.slide_id;
    axios
      .get(url, { headers: { Authorization: AuthHeader() } })
      .then((response) => {
        let json_data = JSON.parse(response.data);
        if (json_data.status === 0) {
          this.setState({
            scan_ongoing: true,
            stitching_done: false,
            isFetching: false,
          });
        }
        if (json_data.status === 12 || json_data.status === 9) {
          this.setState({
            stitching_done: true,
            scan_ongoing: false,
            isFetching: false,
          });
          clearInterval(this.timer);
          this.timer = null;
        } else {
          let txty_time = parseFloat(json_data.txty_time) / 60;
          this.max_txty_time = Math.max(this.max_txty_time, txty_time);
          let stitcher_percent = json_data.stitcher_percent;
          let stitcher_time = json_data.stitcher_estimated_time / 60000;
          if (stitcher_time === 0) {
            stitcher_time = this.max_txty_time;
          }
          let final_percent = parseFloat(stitcher_percent).toFixed(2);
          let final_time = stitcher_time.toFixed(2);
          let time_left = Date.now() + final_time * 60 * 1000;
          this.setState({
            stitch_percent: final_percent,
            stitch_time: time_left,
            stitching_done: false,
            isFetching: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          errorCount: this.state.errorCount + 1,
        });
        console.log("Failed Getting Value", err);
      });
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
    this.timer = null;
  };

  onTabChange = (key) => {
    return
  };

  changeViewMode = (mode) => {
    this.setState({
      view_mode: parseInt(mode),
    });
  };

  calcNewCount = (shiftColumn, rowLength, currCount, isNext) => {
    let sign = 1;
    let pageSize = this.props.wbcGridData.page_size;
    if (!isNext) {
      sign = -1;
    }
    let newCount;
    if (shiftColumn) {
      let end = false;
      if (isNext) {
        let temp = currCount % pageSize;
        if (temp === 0 || (temp < pageSize && temp > pageSize - rowLength)) {
          end = true;
        }
      } else {
        let temp = currCount % pageSize;
        if (temp >= 1 && temp <= rowLength) {
          end = true;
        }
      }
      if (!end) {
        newCount = currCount + sign * rowLength;
      } else {
        newCount = currCount;
      }
    } else {
      newCount = currCount + sign;
    }
    return newCount;
  };

  load = (grid) => {
    let anno_id = grid["id"];
    let slide_id = this.slide_id;
    if (this.state.blood_class === bloodClass.FIELD) {
      let page_size = this.props.fieldGridData.page_size;
      let url = `/api/get_selected_field_anno_page/?anno_id=${anno_id}&slide_id=${slide_id}&page_size=${page_size}`;
      axios.get(url).then((res) => {
        this.props.dispatch(updatePageFieldView(res.data["page"]));
        this.props.dispatch(fieldGridSelected(grid));
      });
    } else if (this.state.blood_class === bloodClass.WBC) {
      let page_size = this.props.wbcGridData.page_size;
      let page = Math.ceil(grid.count / page_size);
      this.props.dispatch(updatePageWBCView(page));
      this.props.dispatch(wbcGridSelected(grid));
    }
  };

  loadNext = (shiftColumn) => {
    let currCount;
    let newCount;
    let all_grids;
    let rowLength =
      this.state.blood_class === bloodClass.RBC ||
      this.state.blood_class === bloodClass.PLT
        ? 8
        : 3;
    if (this.state.blood_class === bloodClass.FIELD) {
      currCount = this.props.fieldGridData.selected_grid.overall_count;
      newCount = this.calcNewCount(shiftColumn, rowLength, currCount, 1);
      all_grids = this.props.fieldGridData.all_grids;
    } else if (this.state.blood_class === bloodClass.WBC) {
      currCount = this.props.wbcGridData.selected_grid.overall_count;
      newCount = this.calcNewCount(shiftColumn, rowLength, currCount, 1);
      all_grids = this.props.wbcGridData.all_grids;
    } else if (this.state.blood_class === bloodClass.RBC) {
      let curr_grids = this.props.bloodGridRBCData.curr_grids;
      currCount = this.props.bloodGridRBCData.selected_grid.overall_count;
      newCount = this.calcNewCount(shiftColumn, rowLength, currCount, 1);
      let found = false;
      for (let i = 0; i < curr_grids.length; i++) {
        if (parseInt(curr_grids[i].overall_count) === newCount) {
          this.props.dispatch(rbcGridSelected(curr_grids[i]));
          found = true;
        }
      }
      if (
        !found &&
        newCount < this.props.bloodGridRBCData.total_count &&
        this.props.bloodGridRBCData.page <
          this.props.bloodGridRBCData.total_pages &&
        !this.props.bloodGridRBCData.fetching_grid
      ) {
        let page_size = this.props.bloodGridRBCData.page_size;
        let newPage = Math.ceil((currCount + 1) / page_size);
        this.props.dispatch(updateSelectedGridOverallId(newCount));
        this.props.dispatch(updatePageRBCView(newPage));
        this.props.dispatch(updateFetchingGridRBC(true));
      }

      return;
    } else if (this.state.blood_class === bloodClass.PLT) {
      let curr_grids = this.props.bloodGridPLTData.curr_grids;
      currCount = this.props.bloodGridPLTData.selected_grid.overall_count;
      newCount = this.calcNewCount(shiftColumn, rowLength, currCount, 1);
      let found = false;
      for (let i = 0; i < curr_grids.length; i++) {
        if (parseInt(curr_grids[i].overall_count) === newCount) {
          this.props.dispatch(pltGridSelected(curr_grids[i]));
          found = true;
        }
      }
      if (
        !found &&
        newCount < this.props.bloodGridPLTData.total_count &&
        this.props.bloodGridPLTData.page <
          this.props.bloodGridPLTData.total_pages &&
        !this.props.bloodGridPLTData.fetching_grid
      ) {
        let page_size = this.props.bloodGridPLTData.page_size;
        let newPage = Math.ceil((currCount + 1) / page_size);
        this.props.dispatch(updateSelectedGridOverallIdPLT(newCount));
        this.props.dispatch(updatePagePLT(newPage));
        this.props.dispatch(updateFetchingGridPLT(true));
      }
      return;
    }

    if (newCount <= all_grids.length) {
      this.load(all_grids[newCount - 1]);
    }
  };

  loadPrev = (shiftColumn) => {
    let currCount;
    let newCount;
    let all_grids;
    let rowLength =
      this.state.blood_class === bloodClass.RBC ||
      this.state.blood_class === bloodClass.PLT
        ? 8
        : 3;
    if (this.state.blood_class === bloodClass.FIELD) {
      currCount = this.props.fieldGridData.selected_grid.overall_count;
      newCount = this.calcNewCount(shiftColumn, rowLength, currCount, 0);
      all_grids = this.props.fieldGridData.all_grids;
    } else if (this.state.blood_class === bloodClass.WBC) {
      currCount = this.props.wbcGridData.selected_grid.overall_count;
      newCount = this.calcNewCount(shiftColumn, rowLength, currCount, 0);
      all_grids = this.props.wbcGridData.all_grids;
    } else if (this.state.blood_class === bloodClass.RBC) {
      let curr_grids = this.props.bloodGridRBCData.curr_grids;
      currCount = this.props.bloodGridRBCData.selected_grid.overall_count;
      newCount = this.calcNewCount(shiftColumn, rowLength, currCount, 0);
      let found = false;
      for (let i = 0; i < curr_grids.length; i++) {
        if (parseInt(curr_grids[i].overall_count) === newCount) {
          this.props.dispatch(rbcGridSelected(curr_grids[i]));
          found = true;
        }
      }
      if (
        !found &&
        newCount >= 1 &&
        this.props.bloodGridRBCData.page > 1 &&
        !this.props.bloodGridRBCData.fetching_grid
      ) {
        let page_size = this.props.bloodGridRBCData.page_size;
        let newPage = Math.ceil((currCount - 1) / page_size);
        this.props.dispatch(updatePageRBCView(newPage));
        this.props.dispatch(updateSelectedGridOverallId(newCount));
        this.props.dispatch(updateFetchingGridRBC(true));
      }
      return;
    } else if (this.state.blood_class === bloodClass.PLT) {
      let curr_grids = this.props.bloodGridPLTData.curr_grids;
      currCount = this.props.bloodGridPLTData.selected_grid.overall_count;
      newCount = this.calcNewCount(shiftColumn, rowLength, currCount, 0);
      let found = false;
      for (let i = 0; i < curr_grids.length; i++) {
        if (parseInt(curr_grids[i].overall_count) === newCount) {
          this.props.dispatch(pltGridSelected(curr_grids[i]));
          found = true;
        }
      }
      if (
        !found &&
        newCount < this.props.bloodGridPLTData.total_count &&
        this.props.bloodGridPLTData.page < this.props.bloodGridPLTData.total_pages &&
        !this.props.bloodGridPLTData.fetching_grid
      ) {
        let page_size = this.props.bloodGridPLTData.page_size;
        let newPage = Math.ceil((currCount - 1) / page_size);
        this.props.dispatch(updateSelectedGridOverallIdPLT(newCount));
        this.props.dispatch(updatePagePLT(newPage));
        this.props.dispatch(updateFetchingGridPLT(true));
      }
      return;
    }

    if (newCount > 0) {
      this.load(all_grids[newCount - 1]);
    }
  };

  onTabChangeWBCTabs = (index) => {
    if (index === 0) {
      this.setState({
        confirmed: true,
      });
    } else {
      this.setState({
        confirmed: false,
      });
    }
  };

  onTabFilterTabs = (index) => {
  };

  setName = (val) => {
    this.setState({ slide_name: val });
  };

  handleSettingClick = (event) => {
    event.stopPropagation();
    this.setState({
      settingsAnchorEl: event.currentTarget,
    });
  };

  handleSettingClose = () => {
    this.setState({
      settingsAnchorEl: null,
    });
  };

  getSettingsComponent = () => {
    if (this.state.view_mode === 3) return null;
    let res = (
      <Button
        onClick={this.handleSettingClick}
        style={{
          backgroundColor: "rgba(0,0,0,0)",
          borderColor: "rgba(0,0,0,0)",
          position: "absolute",
          left: "0px",
          bottom: "0px",
          width: "3vw",
          color: "white",
          padding: 0,
          height: "fit-content",
        }}
      >
        <SettingsIcon style={{ fontSize: "2vw" }} />
      </Button>
    );
    return res;
  };

  getSettingsMenu = () => {
    let open = Boolean(this.state.settingsAnchorEl);
    if (this.state.view_mode === 3 || !open) return null;
    return (
      <Popover
        open={open}
        anchorEl={this.state.settingsAnchorEl}
        onClose={this.handleSettingClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div
          style={{
            backgroundColor: "#548fa1",
            height: "172px",
            width: "299px",
          }}
        >
          <BloodSettingsApp />
        </div>
      </Popover>
    );
  };

  getSortIcon = (isAsc) => {
    if (isAsc) {
      return <FaSortAmountUp style={{ fontSize: "1vw", color: "#00F548" }} />;
    }
    return <FaSortAmountDown style={{ fontSize: "1vw", color: "#00F548" }} />;
  };

  onClickSort = (stateToBeChanged, val) => {
    this.setState({
      [stateToBeChanged]: val,
    });
  };

  getHeader = () => {
    let nameComponent;
    if (!this.state.nameEditable) {
      nameComponent = (
        <React.Fragment>
          <Text code style={{ color: "cyan" }}>
            <b>{this.state.slide_name}</b>
          </Text>
          <Tooltip title="Edit Name">
            <Button
              onClick={() => {
                this.setState({ nameEditable: true });
              }}
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                borderColor: "rgba(0,0,0,0)",
              }}
            >
              <EditOutlined
                style={{ color: "rgba(255,255,255,0.3)", fontSize: "0.9vw" }}
              />
            </Button>
          </Tooltip>
        </React.Fragment>
      );
    } else {
      nameComponent = (
        <React.Fragment>
          <Input
            autoFocus
            code
            onChange={(e) => this.setName(e.target.value)}
            value={this.state.slide_name}
            bordered={false}
            onPressEnter={() => {
              this.setState({ nameEditable: false });
              this.setSlideName(this.state.slide_name);
            }}
            style={{
              textAlign: "center",
              fontSize: "0.9vw",
              width: "20vw",
              backgroundColor: "rgb(3, 17, 44)",
              borderColor: "white",
              color: "white",
            }}
          />
          <Button
            style={{
              backgroundColor: "rgba(0,0,0,0)",
              borderColor: "rgba(0,0,0,0)",
            }}
            onClick={() => {
              this.setState({ nameEditable: false });
              this.setSlideName(this.state.slide_name);
            }}
          >
            <CheckOutlined style={{ color: "#00FF00", fontSize: "0.9vw" }} />
          </Button>
        </React.Fragment>
      );
    }

    let viewModeToggleButton;
    if(this.state.view_mode === 1){
        viewModeToggleButton = <Button
                                    style={{
                                    border: "0px",
                                    marginTop: "0.4vh",
                                    backgroundColor: "#0b1c3b",
                                    }}
                                    onClick={() => this.changeViewMode(3)}
                                >
                                    <PictureOutlined style={{ fontSize: "2.5vh", color: "cyan" }} />
                                </Button>
    } else {
        viewModeToggleButton = <Button
                                    style={{
                                    border: "0px",
                                    marginTop: "0.4vh",
                                    backgroundColor: "#0b1c3b",
                                    }}
                                    onClick={() => this.changeViewMode(1)}
                                >
                                    <PicRightOutlined style={{ fontSize: "2.5vh", color: "cyan" }} />
                                </Button>
    }

    return (
      <Row
        style={{
          textAlign: "center",
          backgroundColor: "#0b1c3b",
          height: "4.3vh",
        }}
      >
        <Col span={3} style={{ textAlign: "left" }}>
          <Link to={"/" + globalUrlPrefix + "/dashboard"}>
            <img
              src={logo_neo}
              style={{
                height: "3.5vh",
                marginLeft: "0.3vw",
                marginTop: "0.35vh",
              }}
            ></img>
          </Link>
        </Col>
        <Col span={18} style={{ marginTop: "-0.1vh", fontSize: "2.8vh" }}>
          {nameComponent}
        </Col>
        <Col span={3} style={{ textAlign: "right" }}>
          {viewModeToggleButton}
        </Col>
      </Row>
    );
  };

  getDifferentialView = viewerComponent => {

    let differential = this.state.differential;

    return (
      <Row style={{ backgroundColor: "#03112c", height: "95.7vh" }}>
        <Col
          span={
            this.state.view_mode !== 3
              ? this.state.view_mode === 1
                ? this.state.blood_class === bloodClass.FIELD
                  ? 16
                  : 20
                : 24
              : 0
          }
        >
          <Tabs
            onSelect={(index) => {
              this.onTabChange(index);
            }}
          >
            <TabList>
              <Tab>
                <p>FLD</p>
              </Tab>
            </TabList>

            <TabPanel
              style={ this.state.blood_class === bloodClass.FIELD ? { width: "100%" } : {} }
            >
              <Col
                style={{ marginTop: "-0.5vh" }}
                span={ this.state.view_mode === 1 ? 6 : this.state.view_mode === 2 ? 7 : this.state.view_mode === 3 ? 0 : 7 }
              >
                <FieldGridView
                  slide_id={this.slide_id}
                  dispatch={this.props.dispatch}
                  view_mode={this.state.view_mode}
                  selected={this.props.fieldGridData.selected_grid}
                  page={this.props.fieldGridData.page}
                  fieldGridData={this.props.fieldGridData}
                  mode={bloodClass.FIELD}
                  differential={differential}
                  cssfilter={this.viewerSettings}
                />
              </Col>
              <Col
                style={{ paddingTop: "0vh" }}
                span={ this.state.view_mode === 1 ? 18 : this.state.view_mode === 2 ? 17 : this.state.view_mode === 3 ? 0 : 7 }
              >
                <Field100xView
                  selected={this.props.fieldGridData.selected_grid}
                  view_mode={this.state.view_mode}
                  dispatch={this.props.dispatch}
                  all_grids={this.props.fieldGridData.all_grids}
                  slide_id={this.slide_id}
                  fieldGridData={this.props.fieldGridData}
                  mode={bloodClass.FIELD}
                  differential={differential}
                  loadNext={this.loadNext}
                  loadPrev={this.loadPrev}
                  cssfilter={this.viewerSettings}
                />
              </Col>
            </TabPanel>
          </Tabs>
        </Col>
        <Col
          span={
            this.state.view_mode === 1
              ? this.state.blood_class === bloodClass.FIELD ? 8 : 4 : this.state.view_mode === 2 ? 0 : this.state.view_mode === 3 ? 24 : 10
          }
          class="viewer-component"
          id={"viewer-id"}
          style={{ height: "95.5vh", backgroundColor: "#03112c" }}
        >
          {viewerComponent}
        </Col>
      </Row>
    );
  };

  getResults = () => {
    let results;

    this.viewerSettings = (
      ((this.props.mapsState[0] || {}).slideState || {}).slide_data || {}
    ).blood_viewer_settings;

    let differential = this.state.differential;
    
    let stitchingInProgressComponent = (
      <Row key="progress">
        <Row style={{ textAlign: "center" }}>
          <h2>Stitching in Progress</h2>
          <Progress
            style={{ marginTop: "1%", marginBottom: "1%" }}
            type="circle"
            percent={parseFloat(this.state.stitch_percent)}
          />
          <Link to={"/" + globalUrlPrefix + "/dashboard"}>
            <h2>Click to go back to Dashboard</h2>
          </Link>
        </Row>
      </Row>
    );

    let scanOngoingComponent = (
      <Row>
        <Row style={{ textAlign: "center", marginTop: "5em" }}>
          <b>
            Slide is being scanned. <br></br>Progress will be updated soon.
          </b>
          <Link
            to={"/" + globalUrlPrefix + "/dashboard"}
            className="click-to-go-to-dash"
          >
            <h2>Click to go back to Dashboard</h2>
          </Link>
        </Row>
      </Row>
    );

    let viewerComponent;

    if (
      !this.state.scan_ongoing &&
      this.state.stitching_done &&
      !this.state.isFetching
    ) {
      let zooms = (this.state.slide_data || {}).z_levels;
      zooms = zooms ? zooms.split(",") : [];
      let zoomMax = 0;
      if (zooms !== undefined && zooms.length !== 0) {
        zoomMax = zooms.pop();
      }
      zoomMax = parseInt(zoomMax);

      viewerComponent = (
        <div style={{ height: "100%" }}>
          <BloodSlideMap
            key="viewer"
            slide_id={this.slide_id}
            map_id={0}
            view_mode={this.state.view_mode}
            field_view={true}
            mode={this.state.blood_class}
            differential={differential}
            confirmed={this.state.confirmed}
            maxZoomLevel={zoomMax}
            styleSelector = {"slide-map-container-wbc"}
          />
          {this.state.view_mode === 1 ? (
            <Row
              style={
                this.state.blood_class === bloodClass.FIELD || !differential
                  ? {
                      color: "white",
                      fontSize: "1.3vw",
                      position: "fixed",
                      bottom: "1vh",
                      right: "12vw",
                    }
                  : {
                      color: "white",
                      fontSize: "1.3vw",
                      position: "fixed",
                      bottom: "1vh",
                      right: "4vw",
                    }
              }
            >
              Low Power Scan
            </Row>
          ) : null}
        </div>
      );
    }

    if ((this.state.slide_data || {}).specimen_type === undefined) {
      results = null;
    } else {
      results = (
        <div key="view">
          {
            <Spin
              key="loading"
              tip="Loading..."
              spinning={this.state.isFetching}
              delay={500}
            >
              {this.state.scan_ongoing === true ? (
                scanOngoingComponent
              ) : this.state.stitching_done === true ? (
                <div>
                  {this.getHeader()}
                  {this.getDifferentialView(viewerComponent)}
                </div>
              ) : (
                stitchingInProgressComponent
              )}
            </Spin>
          }
        </div>
      );
    }
    return results;
  };

  render() {
    let settingsComponent;
    settingsComponent = this.getSettingsComponent();

    let settingsMenu;
    settingsMenu = this.getSettingsMenu();

    let results = this.getResults();

    return (
      <>
        {results}
        {settingsComponent}
        {settingsMenu}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bloodGridData: state.bloodGridReducer,
    urlState: state.viewerUrlReducer,
    bloodGridRBCData: state.bloodGridRBCReducer,
    bloodGridPLTData: state.bloodGridPLTReducer,
    fieldGridData: state.fieldViewReducer,
    wbcGridData: state.wbcViewReducer,
    pltGridData: state.bloodGridPLTReducer,
    mapsState: state.mapsStateReducer,
  };
};

export default connect(mapStateToProps)(BmaScan40x);
