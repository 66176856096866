import React, {Component} from "react";
import {connect} from 'react-redux';

import * as enhancementKeys from '../utils/enhancementKeys_settings'
import Typography from "@mui/material/Typography";
import {FormControl, Grid, IconButton, Input, MenuItem, Select, Slider, TextField, Tooltip} from "@mui/material";
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import {message} from "antd";
import CircularProgress from '@mui/material/CircularProgress';
import {
    updateBrightness,
    updateContrast, updateGrayscale,
    updateHue,
    updateInvert,
    updateSaturation, updateSlideData, updateViewerSettings
} from "../../../action/maps.state.action";
import {createNewViewerSetting, loadAllViewerSettings} from "../../../action/gamma.state.action";
import {ReplayOutlined} from "@mui/icons-material";


class SettingsApp extends Component {

    constructor(props) {
        super(props);
        this.rowHeight = '89px';

        this.props.dispatch(loadAllViewerSettings());
    }

    initSlideState = () => {
        this.activeMapId = this.props.gammaState.activeMapId;
        this.slideState = this.props.mapsState[this.activeMapId].slideState;
        this.allViewerSettings = this.props.gammaState.allViewerSettings;
        this.viewerSettings = ((this.slideState || {}).slide_data || {}).viewer_settings;
        this.bloodViewerSettings = ((this.slideState || {}).slide_data || {}).blood_viewer_settings;
    }

    updateActiveSlideViewerSettingsId = (id) =>
        this.props.dispatch(updateSlideData(this.activeMapId, this.slideState.slide_data.id, {
            settings: id,
        }));

    restoreSettings = () => {
        if (this.slideState.backupViewerSettings) {
            this.props.dispatch(updateViewerSettings(this.activeMapId, this.slideState.backupViewerSettings));
            this.updateActiveSlideViewerSettingsId(this.slideState.backupViewerSettings.id);
        }
    }

    onChangeRotation = (event, newValue) => {
        if(this.viewerSettings.seen_area_status){
            message.info("Please TURN OFF show seen area in preview app to enable rotation",1.5)
            return;
        }
        this.slideState.slidemap.getView().setRotation((newValue * Math.PI) / 180);
    }

    handleRotationInputChange = (event) => {
        let newValue = event.target.value === '' ? 0 : Number(event.target.value);
        if(newValue < 0){
            newValue = 0;
        }
        else if(newValue > 360){
            newValue = 360;
        }
        this.slideState.slidemap.getView().setRotation((newValue * Math.PI) / 180);
    }

    onChangeEnhancementInputComponent = (event, changeAction, enhancementKey) => {
        let newValue = event.target.value === '' ? enhancementKey.min:Number(event.target.value);
        if(newValue<enhancementKey.min){
            newValue = enhancementKey.min;
        }
        if(newValue>enhancementKey.max){
            newValue = enhancementKey.max;
        }
        this.props.dispatch(changeAction(newValue))
    }

    getImageEnhancementSliderComponent = (enhancementKey, onChange) => {
        let value = this.viewerSettings[enhancementKey.id];
        return <Grid sx={{height:this.rowHeight}}>
            <Grid>
                <Typography>
                    {enhancementKey.name}
                </Typography>
            </Grid >
            <Grid container spacing={2} alignItems={"center"}>
                <Grid item>
                    {enhancementKey.icon}
                </Grid>
                <Grid item xs sm={8}>
                    <Slider
                        disabled = {(this.props.urlState || {}).presentCode != undefined}
                        value = {value}
                        defaultValue={value}
                        key={`slider-${enhancementKey.id}`}
                        min={enhancementKey.min}
                        max={enhancementKey.max}
                        onChange={onChange}
                        color={'secondary'}
                    />
                </Grid>
                <Grid item xs>
                    <Input
                        value = {value}
                        size = 'small'
                        onChange={onChange}
                        inputProps={{
                            // min: enhancementKey.min,
                            // max: enhancementKey.max,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                    />
                </Grid>
            </Grid >
        </Grid>
    }

    render() {
        this.initSlideState();

        if (!this.allViewerSettings || !this.viewerSettings)
            return <div style={{height:'inherit'}}>
                <CircularProgress color={'secondary'} sx={{marginTop:'75%', marginLeft:'40%'}}/>
            </div>

        if (!this.slideState.backupViewerSettings || this.slideState.backupViewerSettings.id !== this.viewerSettings.id)
            this.slideState.backupViewerSettings = {...this.viewerSettings}

        let viewerSettingsSelect =
            <Grid container paddingBottom={1} direction={"row"} justifyContent={"space-between"}>
                <Tooltip title={"Restore"} placement={"right"}>
                    <IconButton color={"secondary"} onClick={() => this.restoreSettings()}>
                        <ReplayOutlined />
                    </IconButton>
                </Tooltip>
                <FormControl variant="standard">
                    <Tooltip title={"Choose Settings"} placement={"left"}>
                        <Select value={this.viewerSettings.id} MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "right",
                            }}}  onChange={e =>
                            this.updateActiveSlideViewerSettingsId(e.target.value)}>
                            {this.allViewerSettings.filter(viewerSettings => viewerSettings.id !== this.bloodViewerSettings.id).map(viewerSettings =>
                                <MenuItem value={viewerSettings.id}>{viewerSettings.name}</MenuItem>
                            )}
                            <TextField color='secondary' margin='normal' variant={"outlined"} placeholder={"+ new"}
                                       size="small" sx={{px: 1, mx:0, width: 80,}}
                                       inputProps={{maxLength: 15}}
                                       onKeyDown={(e) =>
                                           e.keyCode === 13 ? [this.props.dispatch(createNewViewerSetting({
                                               ...this.viewerSettings,
                                               name: e.target.value,
                                           })), e.target.value = ''] : e.stopPropagation()}/>
                        </Select>
                    </Tooltip>
                </FormControl>
            </Grid>

        //rotation is kept different from other enhancement keys idky!
        let rotationMenu =
            <Grid sx={{height:this.rowHeight}}>
                <Grid>
                    <Typography>
                        Rotation
                    </Typography>
                </Grid >
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item>
                        <ThreeSixtyIcon />
                    </Grid>
                    <Grid item xs sm={8}>
                        <Slider
                            disabled = {(this.props.urlState || {}).presentCode !== undefined}
                            value = {(this.slideState.slidemap.getView().getRotation()*180)/Math.PI}
                            min={0}
                            max={360}
                            onChange={this.onChangeRotation}
                            color={'secondary'}
                        />
                    </Grid>
                    <Grid item xs>
                        <Input
                            value = {parseInt((this.slideState.slidemap.getView().getRotation() * 180) / Math.PI)}
                            size = 'small'
                            onChange={this.handleRotationInputChange}
                            inputProps={{
                                min: 0,
                                max: 360,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </Grid>
                </Grid >
            </Grid>

        let enhancementSettingsMenu = [
            this.getImageEnhancementSliderComponent(enhancementKeys.brightnessKey, (event, value) =>
                    this.props.dispatch(updateBrightness(this.activeMapId, this.viewerSettings.id, value))),
            this.getImageEnhancementSliderComponent(enhancementKeys.contrastKey, (event, value) =>
                    this.props.dispatch(updateContrast(this.activeMapId, this.viewerSettings.id, value))),
            this.getImageEnhancementSliderComponent(enhancementKeys.hueKey, (event, value) =>
                    this.props.dispatch(updateHue(this.activeMapId, this.viewerSettings.id, value))),
            this.getImageEnhancementSliderComponent(enhancementKeys.saturationKey, (event, value) =>
                    this.props.dispatch(updateSaturation(this.activeMapId, this.viewerSettings.id, value))),
            this.getImageEnhancementSliderComponent(enhancementKeys.invertKey, (event, value) =>
                    this.props.dispatch(updateInvert(this.activeMapId, this.viewerSettings.id, value))),
            this.getImageEnhancementSliderComponent(enhancementKeys.grayscaleKey, (event, value) =>
                    this.props.dispatch(updateGrayscale(this.activeMapId, this.viewerSettings.id, value))),
        ]

        return <Grid>
            {viewerSettingsSelect}
            {rotationMenu}
            {enhancementSettingsMenu}
        </Grid>
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
        gammaState: state.gammaStateReducer,
        mapsState: state.mapsStateReducer,
    }
}

export default connect(mapStateToProps)(SettingsApp);
