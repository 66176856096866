import { RedoOutlined } from '@ant-design/icons';
import { Input, Radio, Spin, Tooltip, Icon, message, InputNumber, Divider } from 'antd';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { setObjectiveType, setScanName, setScanSpeed, setSpecimenType, takePreview, takePreviewBlood,setPreviewStatusToConfirmed, setDropDistancePointLength, setDropDistancePointOnly, setFocusPoints, updatePreviewStatus, receivedPreviewStatus } from '../../../action/preview_status.action';
import { RegionSelectionConstants } from "../../../actionTypes/region_selection.constant";
import { BloodPreviewConstants } from "../../../actionTypes/blood_preview.constant"
import '../../../asset/style/workflow/slide.css';
import { objectiveType, scanSpeed, specimenType, globalUrlPrefix } from '../../../utils/const';
import { getBarcodeCrop, getOverlayedMaskForPath } from '../preview_utils';
import { setDeviceBusy, setDeviceIdle } from '../../../action/device_status.action';
import axios from 'axios';
import { ScanApiConstant } from '../../../actionTypes/scan.constant';
import { AuthHeader } from '../../../../src/helper/auth.token';
import {updateCountSlotWise} from '../../../action/bloodConfig.action'

var querystring = require('querystring')
let SPECIMEN = {
    'PAP': specimenType.PAP,
    'HE': specimenType.HNE_TISSUE,
    'IHC': specimenType.IHC,
    // 'Blood': specimenType.BLOOD,
}

let SCAN_SPEED = {
    'Minor': scanSpeed.FAST,
    'Few': scanSpeed.MEDIUM,
    'Major': scanSpeed.SLOW,
    'ZSTACK': scanSpeed.ZSTACK,
}

let SCAN_SPEED_NORMAL = {
    'Minor': scanSpeed.FAST,
    'Few': scanSpeed.MEDIUM,
    'Major': scanSpeed.SLOW
}

let OBJECTIVE = {
    '20x': objectiveType.TWENTY_X,
    '40x': objectiveType.FORTY_X,
    '100x': objectiveType.HUNDRED_X,
}

const initialState = {
    tissueType: null,
    scanSpeed: null,
    objective: null,
    takePreview: false,
    isFetching: false,
    barcodeAngle: -90,
    selected: false,
    blood10XRunning: false,
    slide_info: {},
    slideName: "",
    WBCCount: 100,
}

class BloodSlideView extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.setState({
            tissueType: this.getKeyByValue(SPECIMEN, Object.keys(this.props.preview || {}).length === 0 ? this.props.defaultConfig.specimenType : this.props.preview.specimenType),
            scanSpeed: this.getKeyByValue(SCAN_SPEED, Object.keys(this.props.preview || {}).length === 0 ? this.props.defaultConfig.scanSpeed : this.props.preview.speed),
            objective: this.getKeyByValue(OBJECTIVE, Object.keys(this.props.preview || {}).length === 0 ? this.props.defaultConfig.objectiveType : this.props.preview.objectiveType),
        });
        let currPreviewStatus = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).bloodPreviewStatus;
        console.log("mount-", ((currPreviewStatus||{})[this.props.slot_id]||{}).slideName)
        if(((currPreviewStatus||{})[this.props.slot_id]||{}).slideName){
            console.log("mount", ((currPreviewStatus||{})[this.props.slot_id]||{}).slideName)
            this.setState({
                slideName: ((currPreviewStatus||{})[this.props.slot_id]||{}).slideName,
            })
        }

        if(((this.props.bloodConfig||{}).countWBCSlotWise||{})[this.props.slot_id]){
            this.setState({
                WBCCount: ((this.props.bloodConfig||{}).countWBCSlotWise||{})[this.props.slot_id]
            })
        }
    }

    componentDidUpdate = (prevProps) => {
        let prevPreviewStatus = (((prevProps.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus;
        let currPreviewStatus = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus;

        for (const [key, value] of Object.entries(currPreviewStatus)) {
            if (key == this.props.slot_id) {
                if ((prevPreviewStatus[key] == undefined && value.previewStage == RegionSelectionConstants.FETCHED_IMAGE) ||
                (prevPreviewStatus[key] != undefined && prevPreviewStatus[key].previewStage.toLowerCase() != RegionSelectionConstants.FETCHED_IMAGE && 
                value.previewStage.toLowerCase() == RegionSelectionConstants.FETCHED_IMAGE)) {
                    this.props.dispatch(receivedPreviewStatus(ScanApiConstant.TAKEN_PREVIEW, value.previewResponse, key));
                }

                if ((prevPreviewStatus[key] == undefined && value.previewStage == RegionSelectionConstants.CONFIRMED) ||
                (prevPreviewStatus[key] != undefined && ((prevPreviewStatus[key].previewStage.toLowerCase() != RegionSelectionConstants.CONFIRMED && 
                value.previewStage.toLowerCase() == RegionSelectionConstants.CONFIRMED) || 
                (prevPreviewStatus[key].previewConfirmedCount != value.previewConfirmedCount)))) {
                    this.confirmPreviewStatus(value.previewResponse);
                }
            }
        }
        let prevBloodStatus = ((((prevProps.deviceStatus[prevProps.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus[this.props.slot_id]
        let currBloodStatus = ((((this.props.deviceStatus[this.props.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus[this.props.slot_id]

        if ((currBloodStatus || {}).bloodPreviewStage !== undefined && (prevBloodStatus || {}).bloodPreviewStage !== BloodPreviewConstants.COARSE_STARTED 
                && (currBloodStatus || {}).bloodPreviewStage == BloodPreviewConstants.COARSE_DONE && this.state.slide_info=={}) {
            let reg = {
                data: { index: 0 },
                height: 100,
                isChanging: false,
                new: false,
                width: 100,
                x: 0,
                y: 0,
            };

            // this.createCanvas((currBloodStatus||{}).stitchedDir, reg);
            this.getSlideInfo();
        }

        if((currBloodStatus||{}).slideName != (prevBloodStatus||{}).slideName){
            console.log("set-name", (currBloodStatus || {}).slideName)
            this.setState({
                slideName: (currBloodStatus || {}).slideName,
            })
            this.setSlideNameFirstTime((currBloodStatus || {}).slideName);
        }

        if(this.state.slide_info == {} && (currBloodStatus || {}).bloodPreviewStage == BloodPreviewConstants.COARSE_DONE){
            this.getSlideInfo();
        }

        
        if((prevBloodStatus||{}).bloodPreviewStage!=BloodPreviewConstants.COARSE_STARTED && (currBloodStatus||{}).bloodPreviewStage==BloodPreviewConstants.COARSE_STARTED){
            console.log("turn-on")
            this.setState({
                blood10XRunning: true,
            })
        }

        if((prevBloodStatus||{}).bloodPreviewStage==BloodPreviewConstants.COARSE_STARTED && (currBloodStatus||{}).bloodPreviewStage==BloodPreviewConstants.COARSE_DONE){
            console.log("turn-off")
            this.setState({
                blood10XRunning: false,
            })
        }
    }

    componentWillUnmount = () => {
        this.setState({
            initialState,
        });
    }

    thresholdCanvasRef = thresholdCanvas => this.thresholdCanvas = thresholdCanvas;
    
    getSlideInfo = () => {
        let morphle_id = (((((this.props.deviceStatus[this.props.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus[this.props.slot_id]||{}).morphleID
        console.log("morphle_id", morphle_id, this.props.slot_id);
        if(morphle_id){
            let url = `/api/get_slide_data/?morphle_id=${morphle_id}`
            axios.get(url).then(res => {
                console.log(res.data,"response")
                this.setState({
                    slide_info: res.data,
                })
            })
        }
    }

    createCanvas = (specimenTypeVal, reg) => {
        const thresholdContext = this.thresholdCanvas.getContext('2d');
        const thresholdImage = new Image();
		let date = new Date();
        
        thresholdImage.src = "/scan-hdd/" + '/morphle_test/2022/01/04/16/10/1_2022-01-04_16-10-44-0000199_Tilda14_S1T1R1/stitched/0/x0y0.jpg';
        thresholdImage.onload = () => {
            thresholdContext.clearRect(0, 0, this.thresholdCanvas.width, this.thresholdCanvas.height);
            thresholdContext.drawImage(thresholdImage, 0, 0, this.thresholdCanvas.width, this.thresholdCanvas.height);
            // this.calculateThreshold(reg);
        }
    }

    calculateThreshold = (reg) => {
        let imageURLData = this.thresholdCanvas.toDataURL("image/jpeg");
        let x1 = parseInt((reg.x * this.props.preview.response.previewWidth) / 100.0);
        let x2 = parseInt(((reg.x + reg.width) * this.props.preview.response.previewWidth) / 100.0);
        let y1 = parseInt((reg.y * this.props.preview.response.previewHeight) / 100.0);
        let y2 = parseInt(((reg.y + reg.height) * this.props.preview.response.previewHeight) / 100.0);
        let actualReg = [x1, y1, x2, y2];

        this.props.dispatch(setDeviceBusy(this.props.device_id));
        let url = '/server/devices/' + this.props.device_id + "/send_threshold_image_background/?slot_id=" + this.props.slot_id;
        axios({
            method:"post",
            url : url, 
            data: {
                imgBase64: imageURLData, 
                specimenType: this.props.preview.specimenType,
                objectiveType: this.props.preview.objectiveType,
                actualRegion: actualReg,
                previewData: this.props.preview
            }
        })
        .then(response => {
            if (response.status === 200) {
                this.props.dispatch(setPreviewStatusToConfirmed(this.props.slot_id, reg, actualReg));
                // this.props.dispatch(setDropDistancePointOnly(this.props.slot_id, response.data.dropDistancePoint));
                // this.props.dispatch(setFocusPoints(this.props.slot_id, response.data.focusPoints));
                // this.props.dispatch(setDeviceIdle(this.props.device_id));
                // this.props.dispatch(updatePreviewStatus(this.props.slot_id, RegionSelectionConstants.CONFIRMED));
            }
            else {
                this.props.dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_CONFIRMING_SELECTION, response, this.props.slot_id))
                this.props.dispatch(setDeviceIdle(this.props.device_id));
                message.error("Not able to confirm preview settings. Please contact admin.", 2.5);
                this.setState({
                    isFetching: false,
                });
            }
        })
        .catch(err => {
            this.props.dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_CONFIRMING_SELECTION, err, this.props.slot_id))
            this.props.dispatch(setDeviceIdle(this.props.device_id));
            message.error("Not able to confirm preview settings. Please contact admin.", 2.5);
            this.setState({
                isFetching: false,
            });
        })
    }
    
    confirmPreviewStatus = (response) => {
        let region = this.props.preview.region != undefined ? this.props.preview.region : {};
        let actualRegion = this.props.preview.actualRegion != undefined ? this.props.preview.actualRegion : [];
        this.props.dispatch(setPreviewStatusToConfirmed(this.props.slot_id, region, actualRegion));
        this.props.dispatch(setDropDistancePointOnly(this.props.slot_id, response.dropDistancePoint));
        this.props.dispatch(setDropDistancePointLength(this.props.slot_id, response.dropDistancePoint));
        this.props.dispatch(setFocusPoints(this.props.slot_id, response.focusPoints));
        this.props.dispatch(setDeviceIdle(this.props.device_id));
        this.props.dispatch(updatePreviewStatus(this.props.slot_id, RegionSelectionConstants.CONFIRMED));
        this.setState({
            isFetching: false,
        });
    }

    getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    setTissueType = (value) => {

        let isFetchingAllPreviews = false;

        let currPreviewStatus = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus;

        for (const [key, value] of Object.entries(currPreviewStatus)) {
            if (value.previewOngoing) {
                isFetchingAllPreviews = true;
                break;
            }
        }

        this.setState({
            tissueType: value,
        });

        this.props.dispatch(setSpecimenType(this.props.slot_id, SPECIMEN[value]));
        
        if (!(isFetchingAllPreviews || !((this.props.preview || {}).status === RegionSelectionConstants.CONFIRMED &&
        ((this.props.preview || {}).dropDistancePoint != undefined && (this.props.preview || {}).dropDistancePoint.length > 0)))) {
            this.createCanvas(SPECIMEN[value], this.props.preview.region);
        }
    }

    setScanSpeed = (value) => {
        this.setState({
            scanSpeed: value,
        });
        this.props.dispatch(setScanSpeed(this.props.slot_id, SCAN_SPEED[value]));
    }

    setObjectiveType = (value) => {
        this.setState({
            objective: value
        });
        this.props.dispatch(setObjectiveType(this.props.slot_id, OBJECTIVE[value]));
    }

    takePreview = () => {
        console.log("safe-1")
        let safe = this.isSafeToClick();
        console.log("safe-1.5", safe)
        if (!safe){
            console.log("safe-2")
            message.info("Preview already going on.")
            return;
        }
        if(!(this.props.deviceStatus[this.props.device_id] || {}).uiBusy){
            this.setState({
                isFetching: true,
            });
            console.log("blood_viewer")
            this.props.dispatch(takePreviewBlood(this.props.device_id, this.props.slot_id));
        }
        // else {
        //     message.error("Please wait while the device takes a preview")
        // }
    }

    expandEditor = () => {
        this.props.expandedSlideView(this.props.slot_id);
    }

    rotateBarcode = () => {
        this.setState({
            barcodeAngle: this.state.barcodeAngle + 90,
        })
    }

    setSlideNameFirstTime = (name) => {
        let currBloodStatus = ((((this.props.deviceStatus[this.props.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus[this.props.slot_id]
        let slide_id = (currBloodStatus||{}).slideId;
        let old_name = this.state.slideName;
        this.setState({
            slideName: name,
        })
        let val = {
            "slide_id": slide_id,
            "slot_id": this.props.slot_id,
            "slide_name": name,
        }
        console.log("save", val)
        let url = `/server/devices/${this.props.device_id}/update_blood_slide_name/`;
        axios.post(url, querystring.stringify(val),{ headers: { Authorization: AuthHeader() } }).then(res => {
            if(res.status==200){
                // this.props.dispatch(resetMapParams());
                // this.props.dispatch(setPreviewStatusToConfirmed(this.props.slot_id,"",""));
            }
        }).catch(err => {
            this.setState({
                slideName: old_name,
            })
            // message.error("Failed to update config")
        })
    }

    setSlideName = (e) => {
        let currBloodStatus = ((((this.props.deviceStatus[this.props.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus[this.props.slot_id]
        let slide_id = (currBloodStatus||{}).slideId;
        let old_name = this.state.slideName;
        this.setState({
            slideName: e.target.value,
        })
        let val = {
            "slide_id": slide_id,
            "slot_id": this.props.slot_id,
            "slide_name": e.target.value,
        }
        console.log("save", val)
        let url = `/server/devices/${this.props.device_id}/update_blood_slide_name/`;
        axios.post(url, querystring.stringify(val),{ headers: { Authorization: AuthHeader() } }).then(res => {
            if(res.status==200){
                // this.props.dispatch(resetMapParams());
                // this.props.dispatch(setPreviewStatusToConfirmed(this.props.slot_id,"",""));
            }
        }).catch(err => {
            this.setState({
                slideName: old_name,
            })
            // message.error("Failed to update config")
        })
        this.props.dispatch(setScanName(this.props.slot_id, e.target.value));
    }

    toggleSelection = () => {
        this.setState({
            selected: this.state.selected ? false : true,
        })
    }

    openSlideEditor = () => {
        if((this.state.slide_info||{}).id == undefined){
            message.info("Some Error Occured. Contact Admin.")
            return;
        }
        window.location.href = "/" + globalUrlPrefix + `/bloodpreview/${this.props.device_id}/${this.props.slot_id}/${this.state.slide_info['id']}/`
    }

    isSafeToClick = () => {
        let previewStatus=(((((this.props.deviceStatus[this.props.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus||{})
        // console.log("safe-1234",previewStatus)
        for (const [key, value] of Object.entries(previewStatus)) {
            console.log("safe",key, value);
            if(value.bloodPreviewStage == BloodPreviewConstants.COARSE_STARTED){
                return false;
            }
          }
        return true;
    }

    setWBCCount = (count) => {
        let currBloodStatus = ((((this.props.deviceStatus[this.props.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus[this.props.slot_id]
        this.setState({
            WBCCount: count,
        })
        this.props.dispatch(updateCountSlotWise([this.props.slot_id, count]));
    }

    render() {
        console.log("reducer", this.props.bloodConfig)
        let currBloodStatus = ((((this.props.deviceStatus[this.props.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus[this.props.slot_id]

        let isFetchingAllPreviews = false;

        let currPreviewStatus = (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response|| {}).scanPreviewStatus;

        for (const [key, value] of Object.entries(currPreviewStatus)) {
            if (value.previewOngoing) {
                isFetchingAllPreviews = true;
                break;
            }
        }

        let specimenRadioButtons = Object.keys(SPECIMEN).map((specimen) => {
            return <Radio.Button value={specimen} style={{padding: '0px 4px'}}>
                {specimen}
            </Radio.Button>    
        });

        let speedRadioButtons = (this.state.scanSpeed || "").toUpperCase() === scanSpeed.ZSTACK? 
                <Radio.Button checked={true} defaultChecked={true} value={scanSpeed.ZSTACK} style={{padding: '0px 4px'}}>
                    {scanSpeed.ZSTACK}
                </Radio.Button> : 
            Object.keys(SCAN_SPEED_NORMAL).map((speed) => {
                return <Radio.Button value={speed} style={{padding: '0px 4px'}}>
                    {speed}
                </Radio.Button>
            });
        

        let objectiveTypeButtons = Object.keys(OBJECTIVE).map((objective) => {
            return <Radio.Button value={objective} style={{padding: '0px 4px'}}>
                {objective}
            </Radio.Button>    
        });

        const specimenOptions = (
            <div style={{padding: '2px 0px'}}>
                <Radio.Group size={'small'} value={this.state.tissueType}
                onChange={(e) => {e.stopPropagation(); this.setTissueType(e.target.value)}}
                disabled={isFetchingAllPreviews || !((this.props.preview || {}).status === RegionSelectionConstants.CONFIRMED &&
                ((this.props.preview || {}).dropDistancePoint != undefined && (this.props.preview || {}).dropDistancePoint.length > 0))}
                >
                    {specimenRadioButtons}
                </Radio.Group>
            </div>
        )

        const scanSpeedOptions = (
            <div style={{padding: '2px 0px'}}>
                
                <Radio.Group size={'small'} value={this.state.scanSpeed} onChange={(e) => {e.stopPropagation(); this.setScanSpeed(e.target.value)}}                  
                disabled={isFetchingAllPreviews || !((this.props.preview || {}).status === RegionSelectionConstants.CONFIRMED &&
                ((this.props.preview || {}).dropDistancePoint != undefined && (this.props.preview || {}).dropDistancePoint.length > 0))}
                >
                    {speedRadioButtons}
                </Radio.Group>
            </div>
        )

        const objectiveTypeOptions = (
            <div style={{padding: '2px 0px'}}>
                <Radio.Group size={'small'} value={this.state.objective} onChange={(e) => {e.stopPropagation(); this.setObjectiveType(e.target.value)}}>
                    {objectiveTypeButtons}
                </Radio.Group>
            </div>
        )

        let showNameField = (currBloodStatus||{}).bloodPreviewStage == BloodPreviewConstants.COARSE_DONE;

        let quickSettings = <div className='quick-settings-button-group'>
                                {/* {specimenOptions}
                                {scanSpeedOptions} */}
                                {/*{objectiveTypeOptions}*/}
                                {showNameField ? 
                                <Input size="small" placeholder="Slide Name" style={{marginTop: '5px'}} 
                                    onChange={this.setSlideName} value={this.state.slideName}/> : null }
                                <p></p>
                                
                                <span><b># WBC: </b>
                                <InputNumber min={1} max={5000} value={this.state.WBCCount} onChange={this.setWBCCount} />
                                </span>
                            </div>

        let date = new Date();
        let status = (this.props.preview || {}).status;
        
        let preview_image=( (((((this.props.deviceStatus[this.props.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus)[this.props.slot_id]||{}).previewPath;
        if(preview_image != undefined){
        preview_image = preview_image.replace("/media/scandrive/scans","/scan-hdd");
                                }
        let slide = null;
        // let safeToClick= (this.props.deviceStatus[this.props.device_id] || {})
        console.log("123", ((((this.props.deviceStatus[this.props.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus)

        // (((((this.props.deviceStatus[this.props.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus)

        if ((currBloodStatus||{}).bloodPreviewStage == BloodPreviewConstants.COARSE_DONE) {
            slide = <div className='filled-preview-container' 
                        style={this.props.preview.status === RegionSelectionConstants.CONFIRMED ? {boxShadow: '0px 4px 8px 2px green'} : {boxShadow: '0px 4px 8px 2px rgba(0,0,0,0.5)'}}>
                        <Tooltip placement="rightTop" title={!((this.props.deviceStatus[this.props.device_id] || {}).uiBusy || isFetchingAllPreviews) ? 
                        "Click to modify scan settings" : ""}>
                            <div onClick={!((this.props.deviceStatus[this.props.device_id] || {}).uiBusy || isFetchingAllPreviews) ? 
                            this.expandEditor : (e) => {e.stopPropagation();}} 
                            className={!((this.props.deviceStatus[this.props.device_id] || {}).uiBusy || isFetchingAllPreviews) ? 
                            "filled-slide" : "filled-action-ongoing-slide"}>
                                <p>Change Scan Region</p>
                                <img className="slot-image-blood" src = {preview_image+'/rotated_thumbnail.jpg'} style={{ width:'100%', marginLeft:'0px', marginTop:'0px'}}/>
                            </div>
                        </Tooltip>
                        <Tooltip placement="rightTop" title={"Rotate Barcode"}>
                            <div className="barcode-filled" onClick={this.rotateBarcode}>
                                <img className="barcode-image" src = {preview_image+'/barcode_crop.jpg'}
                                //src={getBarcodeCrop(this.props.slot_id)} 
                                    style={{transform: 'rotate(' + this.state.barcodeAngle + 'deg)'}}/>
                                
                                <div className="rotate"><Icon type="reload" /></div>
                            </div>
                        </Tooltip>
                        {/* <RedoOutlined  style={{backgroundColor: 'white', padding: '10px 20px 0px 20px'}} onClick={this.rotateBarcode}/> */}
                        <div style={{display: 'flex', align: 'bottom', borderTop: '2px solid black'}}>
                            {quickSettings}
                        </div>
                    </div>
        } else {
            let previewStatus=(((((this.props.deviceStatus[this.props.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus[this.props.slot_id]||{})
            console.log("12345", previewStatus)
            slide = <div className={(this.props.preview || {}).status === RegionSelectionConstants.CONFIRMED ? 'empty-confirmed-preview-container' : 'empty-unconfirmed-preview-container'}>
                        
                        <div onClick={!((this.props.deviceStatus[this.props.device_id] || {}).uiBusy || isFetchingAllPreviews)  ? this.takePreview : (e) => {e.stopPropagation();} }//&&(this.isSafeToClick() || 
                        className="centered empty-slide"
                            style={!((this.props.deviceStatus[this.props.device_id] || {}).uiBusy || isFetchingAllPreviews) ? {cursor: 'pointer'} : null }>
                            <p>Click to see the Auto-Detected Region</p>
                        </div>
                        <div className="barcode-empty">

                        </div>
                        <div style={{display: 'flex', align: 'bottom', borderTop: '2px solid black'}}>
                            {quickSettings}
                        </div>
                    </div>
                    
        }

        
        console.log((((((this.props.deviceStatus[this.props.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus[this.props.slot_id]||{}).bloodPreviewStage,this.state.blood10XRunning ,"devicestatus")
        return (
            <Spin spinning={this.state.blood10XRunning}>
            {/* <Spin spinning={this.state.isFetching} tip={(this.props.deviceStatus[this.props.device_id] || {}).busyMessage}> */}
                <div className='slide-container'>
                    {slide}
                    <canvas ref={this.thresholdCanvasRef} className="region-selection-canvas-new" style={{display: 'none'}} />
                </div>
            </Spin>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        preview: state.previewStatusReducer[ownProps.slot_id],
        deviceStatus: state.deviceStatusReducer,
        bloodConfig: state.bloodConfigReducer,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(BloodSlideView);
