import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { AuthHeader } from '../helper/auth.token';
import { Spin, Row, Col, PageHeader, Tooltip, Button, Input, Divider, Card, Progress, message } from 'antd';
import { globalUrlPrefix } from '../utils/const';
import { getDeviceStatus } from "../action/device_status.action";
import { sagaMiddleware } from "../helper/store";
import { watchPollStatus } from "../action/device_status.action";

const { TextArea } = Input;
const { Meta } = Card;

export class VignetteDiagnostics extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBusy: false,
            deviceId: this.props.match.params.id,
        }
    }

    componentDidMount = () => {
        sagaMiddleware.run(watchPollStatus, this.props.dispatch, this.props.match.params.id);
    }

    vignetteCablibration = (objective) => {
        
        this.setState({
            isBusy: true,
        });
        const url = "/server/devices/" + this.state.deviceId + "/settings/vignette_diagnostics/" + objective;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            // .then(res => {
            //     message.success("Started Vigentte Calibration.");
            //     this.setState({
            //         isBusy: false,
            //     });
            // })
            // .catch(err => {
            //     message.error("Failed. Contact Admin");
            //     this.setState({
            //         isBusy: false,
            //     });
            // });
        message.success("Started Vigentte Calibration.");
    }

    goToSettings = (e) => {
        window.location.href = "/" + globalUrlPrefix + "/settings/" + this.state.deviceId;
    }

    goToSettings = (e) => {
        window.location.href = "/" + globalUrlPrefix + "/scanners/" + this.state.deviceId;
    }

    render() {

        let scannerStatus = (((this.props.device || {}).scanner_health || {}).scanner_response || {});
        let isScanning = scannerStatus.scanning; 
        isScanning = isScanning === undefined ? false : isScanning;
        let isBusy = scannerStatus.busy;
        isBusy = isBusy === undefined ? false : isBusy;
        let uiBusy = isBusy || (this.props.device || {}).uiBusy;
        uiBusy = uiBusy === undefined ? false : uiBusy;
        let busyMessage = this.state.scannerServerBusyMessage;

        let percentDone = ((scannerStatus || {}).diagonosticsState || {}).vignetteRoutinePercentage || 0;
        if (percentDone >= 99){
            percentDone = 100;
        } 

        return (

            <div>
                <Row>
                    <Col offset={10} span={10}>
                        <PageHeader style={{ backgroundColor: "#ffffff00", paddingBottom: "10px" }} title="Scanner Calibrations"></PageHeader>
                    </Col>
                    <Col span={4} style={{ padding: '16px 24px' }}>
                        <Button type="primary" onClick={this.goToSettings} ghost>
                            Back to Settings
                        </Button>
                    </Col>
                </Row>  
                <Divider />
                <br></br>
                <Row>
                    <Col offset={6} span={12}>
                        <Card>
                            <Row>
                                <Col offset={0} span={6}>
                                    <Button type="primary" onClick={(e) => {e.stopPropagation(); this.vignetteCablibration("FORTY_X")}} disabled={isBusy}>
                                    { percentDone == 100 ? "Restart Slide Calibration for 40X" : "Start Slide Calibration for 40X"}
                                    </Button>
                                </Col>
                                <Col span={6}>
                                    <Button type="primary" onClick={(e) => {e.stopPropagation(); this.vignetteCablibration("TEN_X")}} disabled={isBusy}>
                                    { percentDone == 100 ? "Restart Slide Calibration for 10X" : "Start Slide Calibration for 10X"}
                                    </Button>
                                </Col>
                                <Col span={6}>
                                    <Button type="primary" onClick={(e) => {e.stopPropagation(); this.vignetteCablibration("FOUR_X")}} disabled={isBusy}>
                                    { percentDone == 100 ? "Restart Slide Calibration for 4X" : "Start Slide Calibration for 4X"}
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <br></br>
                <Row>
                    <Col offset={6} span={12}>
                        <Card>
                            <Row>
                                <Col span={3}>
                                    <div style={{fontSize: '16px'}}>
                                        Progress
                                    </div>
                                </Col>
                                <Col span={19}>
                                    <Progress percent={percentDone.toFixed(2)} active/>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                { percentDone == 100 ? <><Divider />
                <Row>
                    <Col offset={10} span={6}>
                        <Button type="primary" onClick={this.goToSettings}>
                            All Done! Go back to Control Panel.
                        </Button>
                    </Col>
                </Row></> : null }
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        device: state.deviceStatusReducer[ownProps.match.params.id]
    };
};

export default connect(mapStateToProps)(VignetteDiagnostics);
