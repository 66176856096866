import React, { Component } from 'react';
import { Row, Col, Input, Checkbox, Divider, Button, message, Slider, InputNumber } from 'antd';
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from 'axios';
import { AuthHeader } from '../../helper/auth.token';
import { liveModeLoadingMessages } from '../../utils/const';
import { updateLiveModeAction } from "../../action/admin.state.action";
import { updateInputFieldInFocus, getLiveImageMoveAndFocus, updateExposure, updateGamma, updateAccessRevoked, updateAccessToLiveMode, getNearestTile } from "../../action/livemode.action";

class LiveModeCameraSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exposureInputValue: 20,
            gammaInputValue: 1,
            lutValue: false,
        }
    }

    setExposureValue = (value) => {
        if (!this.props.adminState.live_mode_action) {
            this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.SET_EXPOSURE_VALUE));
            let partsOfUrl = "api~camera~settings_livemode";
            let url = `/server/scano_text/` + this.props.livemode.deviceId + `/` + partsOfUrl + "?exposure=" + value + "&access_code=" + cookie.loadAll().livemode_access_code;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    if (response.status === 200) {
                        this.getLiveImage();
                        message.success("Exposure set successfully!!", 2.5);
                    }
                    else {
                        console.log(response);
                        // message.error("Access revoked!!", 2.5);
                        this.props.dispatch(updateAccessRevoked(true));
                        this.props.dispatch(updateAccessToLiveMode(false));
                        this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.SET_EXPOSURE_VALUE));
                    }
                })
                .catch(err => {
                    console.log(err);
                    message.error("Not able to set exposure!!", 2.5);
                    this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.SET_EXPOSURE_VALUE));
                })
        } else {
            message.error("Action ongoing...");
        }
    }

    setGammaValue = (value) => {
        if (!this.props.adminState.live_mode_action) {
            this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.SET_GAMMA_VALUE));
            let partsOfUrl = "api~camera~set_gamma_livemode";
            let url = `/server/scano_text/` + this.props.livemode.deviceId + `/` + partsOfUrl + "?gamma=" + value + "&access_code=" + cookie.loadAll().livemode_access_code;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    if (response.status === 200) {
                        this.getLiveImage();
                        message.success("Gamma set successfully!!", 2.5);
                    }
                    else {
                        console.log(response);
                        // message.error("Access revoked!!", 2.5);
                        this.props.dispatch(updateAccessRevoked(true));
                        this.props.dispatch(updateAccessToLiveMode(false));
                        this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.SET_GAMMA_VALUE));
                    }
                })
                .catch(err => {
                    console.log(err);
                    message.error("Not able to set gamma!!", 2.5);
                    this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.SET_GAMMA_VALUE));
                })
        } else {
            message.error("Action ongoing...");
        }
    }

    lutValueChange = (e) => {
        if (!this.props.adminState.live_mode_action) {
            this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.CHANGE_LUT_VALUE));
            let checked = e.target.checked;
            let partsOfUrl = "api~camera~set_lut_livemode";
            let url = `/server/scano_text/` + this.props.livemode.deviceId + `/` + partsOfUrl + "?useLUT=" + checked + "&access_code=" + cookie.loadAll().livemode_access_code;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    if (response.status === 200) {
                        this.getLiveImage();
                        message.success("LUT set successfully!!", 2.5);
                    }
                    else {
                        console.log(response);
                        // message.error("Access revoked!!", 2.5);
                        this.props.dispatch(updateAccessRevoked(true));
                        this.props.dispatch(updateAccessToLiveMode(false));
                        this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.CHANGE_LUT_VALUE));
                    }
                })
                .catch(err => {
                    console.log(err);
                    message.error("Not able to set LUT Value!!", 2.5);
                    this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.CHANGE_LUT_VALUE));
                })
        } else {
            message.error("Action ongoing...");
        }
    }

    setWBValue = (e) => {
        if (!this.props.adminState.live_mode_action) {
            this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.SET_WB));
            let partsOfUrl = "api~camera~settings_livemode";
            let url = `/server/scano_text/` + this.props.livemode.deviceId + `/` + partsOfUrl + "?awb=true" + "&access_code=" + cookie.loadAll().livemode_access_code;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    if (response.status === 200) {
                        this.getLiveImage();
                        message.success("WB set successfully!!", 2.5);
                    }
                    else {
                        console.log(response);
                        // message.error("Access revoked!!", 2.5);
                        this.props.dispatch(updateAccessRevoked(true));
                        this.props.dispatch(updateAccessToLiveMode(false));
                        this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.SET_WB));
                    }
                })
                .catch(err => {
                    console.log(err);
                    message.error("Not able to set WB!!", 2.5);
                    this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.SET_WB));
                })
        } else {
            message.error("Action ongoing...");
        }
    }

    getLiveImage = () => {
        let partsOfUrl = "api~stage~move_and_focus";
        let url = `/server/scano_text/` + this.props.livemode.deviceId + `/` + partsOfUrl;
        if (this.props.adminState.slotID != -1) {
            url += '?slotID=' + this.props.adminState.slotID;
        } else {
            url += '?slotID=';
        }
        url += "&x=0&y=0&z=0&doDrop=" + this.props.adminState.doDropDistanceAF +
            "&doAF=" + this.props.adminState.doAutoFocus + "&numZStack=" + (this.props.livemode.zStackLevels * 2) + 
            "&zStackStep=" + this.props.livemode.stepSizeZStack + "&timestamp=" + Date.now();

        let nearestTile = getNearestTile(this.props.livemode.currentMapPosition[this.props.adminState.slotID].x, this.props.livemode.currentMapPosition[this.props.adminState.slotID].y, this.props.livemode.tileCenters[this.props.adminState.slotID]);
        let nearestTileY = parseInt(nearestTile.split('_')[1]);

        url += `&nearestTileY=` + nearestTileY;

        let errorMessage = '';
        if (this.props.adminState.doDropDistanceAF) {
            errorMessage = liveModeLoadingMessages.DROP_DISTANCE_AF;
        } else if (this.props.adminState.doAutoFocus) {
            errorMessage = liveModeLoadingMessages.AUTO_FOCUS;
        } else {
            errorMessage = liveModeLoadingMessages.MOVE;
        }

        this.props.dispatch(getLiveImageMoveAndFocus(url, errorMessage, this.props.adminState.slotID, this.props.adminState.takeZStack, [], false, 
            this.props.adminState.areaVisited, this.props.adminState.liveModePreviewVectorLayer, this.props.livemode.currentMapPosition));
    }

    resetToDefaults = () => {
        this.setState({
            exposureInputValue: 20,
            gammaInputValue: 1,
        });
        this.setExposureValue(20);
        this.setGammaValue(1);
    }

    exposureValueChange = (value) => {
        if (!this.props.adminState.live_mode_action) {
            this.setState({
                exposureInputValue: value
            })
            this.props.dispatch(updateExposure(value));
        } else {
            message.error("Action ongoing...");
        }
    }

    gammaValueChange = (value) => {
        if (!this.props.adminState.live_mode_action) {
            this.setState({
                gammaInputValue: value
            })
            this.props.dispatch(updateGamma(value));
        } else {
            message.error("Action ongoing...");
        }
    }

    onFocusInputField = () => {
        this.props.dispatch(updateInputFieldInFocus(true));
    }

    onBlurInputField = () => {
        this.props.dispatch(updateInputFieldInFocus(false));
    }

    getCameraAppButton = (text, onClickMethod) => {
        return (
            <Button size="small" onClick={onClickMethod}>
                {text}
            </Button>
        )
    }

    getCameraInputFieldRow = (haveRowStyle, showButton, heading, inputField, button) => {
        return (
            <Row style={haveRowStyle ? { marginTop: 5 } : {}}>
                <Col offset={1} span={8}>
                    <b>{heading}</b>
                </Col>
                <Col offset={1} span={9}>
                    {inputField}
                </Col>
                {showButton ?
                    <Col span={4} style={{marginLeft: 4}}>
                        {button}
                    </Col>
                    : null
                }
            </Row>
        )
    }

    getCameraSliderRow = (haveRowStyle, heading, slider, inputBox) => {
        return (
            <Row style={haveRowStyle ? { marginTop: 5 } : {}}>
                <Col offset={1} span={7}>
                    <b>{heading}</b>
                </Col>
                <Col offset={1} span={9}>
                    {slider}
                </Col>
                <Col offset={1} span={5}>
                    {inputBox}
                </Col>
            </Row>
        )
    }

    getCameraAppInputField = (inputValue, onChangeInput) => {
        return <Input size="small" value={inputValue} onFocus={this.onFocusInputField} onBlur={this.onBlurInputField} onChange={onChangeInput} className="camera-app-input-field" />
    }

    getCameraAppCheckbox = (inputValue, onChangeInput) => {
        return <Checkbox checked={inputValue} onChange={onChangeInput} className="custom-checkbox-style"></Checkbox>
    }

    render() {

        let minExposureValue = (((this.props.device || {}).scanner_health || {}).livemode_config || {}).minExposure != undefined ? 
            (((this.props.device || {}).scanner_health || {}).livemode_config || {}).minExposure : 5;
        let maxExposureValue = (((this.props.device || {}).scanner_health || {}).livemode_config || {}).maxExposure != undefined ? 
            (((this.props.device || {}).scanner_health || {}).livemode_config || {}).maxExposure : 200;

        let minGammaValue = (((this.props.device || {}).scanner_health || {}).livemode_config || {}).minGamma != undefined ? 
            (((this.props.device || {}).scanner_health || {}).livemode_config || {}).minGamma : 0.1;
        let maxGammaValue = (((this.props.device || {}).scanner_health || {}).livemode_config || {}).maxGamma != undefined ? 
            (((this.props.device || {}).scanner_health || {}).livemode_config || {}).maxGamma : 1;

        let sliderDisabled = this.props.adminState.live_mode_action || !this.props.livemode.accessToLiveMode || this.props.livemode.useLiveModeInViewMode 
        || this.props.adminState.slotID < 0 || !this.props.adminState.main_light || this.props.adminState.doDropDistanceAF || !this.props.livemode.atLeastOneImageFetched || this.props.liveView.url == undefined || this.props.livemode.currentMapPosition[this.props.adminState.slotID] == undefined;

        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={24} className="settings-heading">
                            <b>Camera Settings</b>
                        </Col>
                    </Row>
                    <Row>
                        <Col offset={1} span={22} className="custom-top-bottom-margin-divider">
                            <Divider />
                        </Col>
                    </Row>
                    {this.getCameraSliderRow(false, 'Exposure',
                        <Slider value={this.props.livemode.exposureInputValue} min={minExposureValue} max={maxExposureValue} onAfterChange={this.setExposureValue} 
                            onChange={this.exposureValueChange} style={{padding: 0, margin: '8px 0px 0px 0px'}} 
                            disabled={sliderDisabled} 
                            tooltipVisible={false}/>,
                        <InputNumber style={{width: '100%'}} className="card-color" size="small" value={this.props.livemode.exposureInputValue} disabled={true} />    
                    )}
                    {this.getCameraSliderRow(true, 'Gamma',
                        <Slider value={this.props.livemode.gammaInputValue} min={minGammaValue} max={maxGammaValue} step={0.1} onAfterChange={this.setGammaValue} 
                            onChange={this.gammaValueChange} style={{padding: 0, margin: '8px 0px 0px 0px'}} 
                            disabled={sliderDisabled} 
                            tooltipVisible={false}
                            /*onFocus={this.onFocusInputField} onBlur={this.onBlurInputField}*/ />,
                        <InputNumber style={{width: '100%'}} className="card-color" size="small" value={this.props.livemode.gammaInputValue} disabled={true} />
                    )}
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        liveView: state.liveViewReducer,
        adminState: state.adminUrlReducer,
        livemode: state.livemodeReducer,
        device: state.deviceStatusReducer[state.livemodeReducer.deviceId]
    };
};

export default connect(mapStateToProps)(LiveModeCameraSettings);
