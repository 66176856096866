import {
	ADD_NEW_ANNOTATIONS,
	UPDATE_ANNOTATION_STATE,
	UPDATE_DEEP_BIO_STATE,
	UPDATE_GRID_STATE,
	UPDATE_MAP_STATE,
	UPDATE_MAPS_STATE,
	UPDATE_SLIDE_STATE,
	UPDATE_VIEWER_SETTINGS,
	ADD_NEW_DEEP_BIO_ANNOTATIONS,
	DELETE_ANNOTATIONS,
	DELETE_DEEP_BIO_ANNOTATIONS,
	UPDATE_BLOOD_VIEWER_SETTINGS
} from "../actionTypes/maps.state.const";
import axios from "axios";
import {AuthHeader} from "../helper/auth.token";
import {message} from "antd";
import {
	brightnessKey,
	contrastKey,
	grayscaleKey,
	hueKey,
	invertKey, saturationKey
} from "../component/gammaviewer/utils/enhancementKeys_settings";
import {getAnnotationAppFeatures, getFeatureFromAnnotation} from "../component/gammaviewer/utils/annotations_app_utils";
import {isResponseOk} from "../helper/response.status.check";
import {displayError} from "../helper/display.error";

export const initSlides = (slides) => updateMapsState(
	Object.assign({}, slides.map(slide => ({
		slideId: slide.id,
		x: -1,
		y: -1,
		z: 0,
		r: 0,
		digitalZoomStatus: false,
		zStackLevel: 0,
		gridState: {
			grid: false,
			color: "e91e63",
			size: 500,
			width: 3,
		},
		...slide,
	})))
);

export const updateMapState = (mapId, mapState) => ({
	type: UPDATE_MAP_STATE,
	mapId: mapId,
	mapState: mapState
});

export const updateMapsState = (state) => ({
	type: UPDATE_MAPS_STATE,
	state: state
});


// Z Stack Actions
export const updateZStackLevel = (mapId, zStackLevel) => updateMapState(mapId, {
	zStackLevel: zStackLevel,
});


// Slide State Actions
export const updateSlideState = (mapId, slideState) => ({
	type: UPDATE_SLIDE_STATE,
	mapId: mapId,
	slideState: slideState,
});

export const initSlideData = (mapId, slideId) => dispatch => {
	axios.get(`/api/slide/${slideId}/`, {
		headers: {
			Authorization : AuthHeader()
		}
	}).then(response =>
		isResponseOk(response) ?
			dispatch(updateSlideState(mapId, {
				slide_data: response.data,
			})) :
			displayError("Slide Data Init Failed", response)
	).catch(error => displayError("Slide Data Init Failed", error));
}

export const updateSlideData = (mapId, slideId, slideData) => dispatch => {

	axios.patch(`/api/slide/${slideId}/`, slideData, {
		headers: {
			Authorization : AuthHeader()
		}
	}).then(response => {
		if(response.status === 200 || response.status === 301 || response.status === 302) {
			message.success('Slide Update Successful', 2.5);
			dispatch(updateSlideState(mapId, {
				slide_data: response.data,
			}));
		}
		else if(response.status === 403 || response.status === 401 || response.status === 400)
			message.error('Slide Update Failed', 2.5);
		})
		.catch(err =>{
			message.error('Slide Update Failed', 2.5);
			console.log(err);
		});
}

export const updateDigitalZoomStatus = (mapId, digitalZoomStatus) => updateMapState(mapId, {
	digitalZoomStatus: digitalZoomStatus,
});


// Grid App Actions
export const updateGridState = (mapId, gridState) => ({
	type: UPDATE_GRID_STATE,
	mapId: mapId,
	gridState: gridState,
});

export const updateGridStatus = (mapId, grid) => updateGridState(mapId, {
	grid: grid,
});

export const updateGridColor = (mapId, color) => updateGridState(mapId, {
	color: color,
});

export const updateGridSize = (mapId, size) => updateGridState(mapId, {
	size: size,
});

export const updateGridWidth = (mapId, width) => updateGridState(mapId, {
	width: width
});


// Viewer Settings App ACTIONS
export const updateViewerSettings = (mapId, viewerSettings) => dispatch => {
	axios.patch(`/api/viewersetting/${viewerSettings.id}/`, viewerSettings, {
		headers: {
			Authorization : AuthHeader()
		}
	}).then(response => {
		if(response.status === 200 || response.status === 301 || response.status === 302) {
			// not dispatching here to remove ui lag, as server response time effect ui
		}
		else if(response.status === 403 || response.status === 401 || response.status === 400)
			message.error('Settings Update Failed', 2.5);
	})
		.catch(err =>{
			message.error('Settings Update Failed', 2.5);
			console.log(err);
		});

	dispatch({
		type: UPDATE_VIEWER_SETTINGS,
		mapId: mapId,
		viewerSettings: viewerSettings,
	});
}

export const updateBrightness = (mapId, viewerSettingId, brightness) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[brightnessKey.id]: brightness,
});

export const updateContrast = (mapId, viewerSettingId, contrast) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[contrastKey.id]: contrast,
});

export const updateHue = (mapId, viewerSettingId, hue) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[hueKey.id]: hue,
});

export const updateInvert = (mapId, viewerSettingId, invert) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[invertKey.id]: invert,
});

export const updateGrayscale = (mapId, viewerSettingId, grayscale) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[grayscaleKey.id]: grayscale,
});

export const updateSaturation = (mapId, viewerSettingId, saturation) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[saturationKey.id]: saturation,
});


// Blood Viewer Settings App Action
export const updateBloodViewerSettings = (mapId, viewerSettings) => dispatch => {
	axios.patch(`/api/viewersetting/${viewerSettings.id}/`, viewerSettings, {
		headers: {
			Authorization : AuthHeader()
		}
	}).then(response => {
		if(response.status === 200 || response.status === 301 || response.status === 302) {
			// not dispatching here to remove ui lag, as server response time effect ui
		}
		else if(response.status === 403 || response.status === 401 || response.status === 400)
			message.error('Settings Update Failed', 2.5);
	})
		.catch(err =>{
			message.error('Settings Update Failed', 2.5);
			console.log(err);
		});

	dispatch({
		type: UPDATE_BLOOD_VIEWER_SETTINGS,
		mapId: mapId,
		bloodViewerSettings: viewerSettings,
	});
}

export const updateBloodBrightness = (mapId, viewerSettingId, brightness) => updateBloodViewerSettings(mapId, {
	id: viewerSettingId,
	[brightnessKey.id]: brightness,
});

export const updateBloodContrast = (mapId, viewerSettingId, contrast) => updateBloodViewerSettings(mapId, {
	id: viewerSettingId,
	[contrastKey.id]: contrast,
});


// ANNOTATION APP ACTIONS
export const updateAnnotationState = (mapId, annotationState) => ({
	type: UPDATE_ANNOTATION_STATE,
	mapId: mapId,
	annotationState: annotationState,
});

// retrieveAnnotationsAppDataFromBackend
export const retrieveAnnotations = (mapId, slideId, annoDrawer) => dispatch => {
	axios.get(
		`/api/annotation/?slide=${slideId}&anno_drawer=${annoDrawer}`,
		{
			headers : { Authorization: AuthHeader() }
		})
		.then(response => isResponseOk(response)
			? dispatch(updateAnnotationState(mapId, {
				annotations: response.data,
				features: getAnnotationAppFeatures(response.data),
			}))
			: message.info("Failed to retrieve annotations")
		)
		.catch(error => {
			message.info("Failed to retrieve annotations");
			console.log("Error: ", error);
		});
}

// take list of annotations and features to add, existing annotations (compared with id) will be replaced
export const addNewAnnotations = (mapId, annotations, features) => ({
	type: ADD_NEW_ANNOTATIONS,  // reducer is made to facilitate extending the list
	mapId: mapId,
	annotations: annotations,
	features: features,
});

// addNewAnnotationToAnnotationApp  -> use getAnnotationFromPoints(color, area, perimeter, key, coord, center, creator,
// z, slide_id) before
export const addNewAnnotation = (mapId, annotation) => dispatch => {
	axios.post(
		`/api/annotation/`, annotation, {
			headers: {
				Authorization: AuthHeader(),
			},
		})
		.then(response => {
				isResponseOk(response)
					? dispatch(addNewAnnotations(mapId, [response.data],
						[getFeatureFromAnnotation({...annotation, id: response.data.id})]))
					: message.info("Failed to add annotation")
			}
		)
		.catch(error => {
			message.info("Failed to add annotation")
			console.log("Error: ", error);
		});
}

export const updateAnnotation = (mapId, annotation) => dispatch => {
	axios.patch(
		`/api/annotation/${annotation.id}/`, annotation, {
			headers: {
				Authorization: AuthHeader(),
			}
		})
		.then(response => isResponseOk(response)
			? dispatch(addNewAnnotations(mapId, [response.data],
				[getFeatureFromAnnotation(response.data)]))
			: message.info("Failed to update annotation")
		)
		.catch(error => {
			message.info("Failed to update annotation");
			console.log("Error: ", error);
		});
}

// take list of annotation id to delete
export const deleteAnnotations = (mapId, annotationIds) => ({
	type: DELETE_ANNOTATIONS,
	mapId: mapId,
	annotationIds: annotationIds,
});

export const deleteAnnotation = (mapId, annotationId) => dispatch => {
	axios.delete(
		`/api/annotation/${annotationId}/`, {
			headers: {
				Authorization: AuthHeader(),
			}
		})
		.then(response => isResponseOk(response)
			? dispatch(deleteAnnotations(mapId, [annotationId]))
			: message.error("Failed to delete annotation")
		)
		.catch(error => {
			message.error("Failed to delete annotation");
			console.log("Error: ", error);
		});
}

export const updateAnnotationVector = (mapId, vector) => updateAnnotationState(mapId, {
	vector: vector,
});


// DEEP BIO APP ACTIONS
export const updateDeepBioState = (mapId, deepBioState) => ({
	type: UPDATE_DEEP_BIO_STATE,
	mapId: mapId,
	deepBioState: deepBioState,
});

export const retrieveDeepBioAnnotations = (mapId, slideId, annoDrawer) => dispatch => {
	axios.get(
		`/api/annotation/?slide=${slideId}&anno_drawer=${annoDrawer}`, {
			headers: {
				Authorization: AuthHeader(),
			}
		})
		.then(response => isResponseOk(response)
			? dispatch(updateDeepBioState(mapId, {
				annotations: response.data,
				features: getAnnotationAppFeatures(response.data),
			}))
			: message.info("Failed to retrieve Deep Bio Annotations")
		)
		.catch(error => {
			message.info("Failed to retrieve Deep Bio Annotations");
			console.log("Error: ", error);
		});
}

// take list of annotations and features to add, existing annotations (compared with id) will be replaced
export const addNewDeepBioAnnotations = (mapId, annotations, features) => ({
	type: ADD_NEW_DEEP_BIO_ANNOTATIONS,
	mapId: mapId,
	annotations: annotations,
	features: features,
});

export const addNewDeepBioAnnotation = (mapId, annotation) => dispatch => {
	axios.post(
		`/api/annotation/`, annotation, {
			headers: {
				Authorization: AuthHeader(),
			}
		})
		.then(response => isResponseOk(response)
			? dispatch(addNewDeepBioAnnotations(mapId, [response.data],
				[getFeatureFromAnnotation({...annotation, id: response.data.id})]))
			: message.info("Failed to add annotation")
		)
		.catch(error => {
			message.info("Failed to add annotation")
			console.log("Error: ", error);
		});
}

export const updateDeepBioAnnotation = (mapId, annotation) => dispatch => {
	axios.patch(
		`/api/annotation/${annotation.id}/`, annotation, {
			headers: {
				Authorization: AuthHeader(),
			}
		})
		.then(response => isResponseOk(response)
			? dispatch(addNewDeepBioAnnotations(mapId, [response.data],
				[getFeatureFromAnnotation(response.data)]))
			: message.info("Failed to update annotation")
		)
		.catch(error => {
			message.info("Failed to update annotation");
			console.log("Error: ", error);
		});
}

export const deleteDeepBioAnnotations = (mapId, annotationIds) => ({
	type: DELETE_DEEP_BIO_ANNOTATIONS,
	mapId: mapId,
	annotationIds: annotationIds,
});

export const deleteDeepBioAnnotation = (mapId, annotationId) => dispatch => {
	axios.delete(
		`/api/annotation/${annotationId}/`, {
			headers: {
				Authorization: AuthHeader(),
			}
		})
		.then(response => isResponseOk(response)
			? dispatch(deleteDeepBioAnnotations(mapId, [annotationId]))
			: message.error("Failed to delete annotation")
		)
		.catch(error => {
			message.error("Failed to delete annotation");
			console.log("Error: ", error);
		});
}

export const updateDeepBioVector = (mapId, vector) => updateDeepBioState(mapId, {
	vector: vector,
});

export const updateDeepBioResultVector = (mapId, vector) => updateDeepBioState(mapId, {
	resultVector: vector,
});

