import React, {Component} from  "react";
import {Row, Col, Tabs, Input, Spin, Button, message, Switch, InputNumber } from 'antd';
import {connect} from 'react-redux';
import cookie from "react-cookies";
import { Map, View, Feature } from "ol";
import LayerGroup from "ol/layer/Group";
import ImageLayer from "ol/layer/Image";
import Static from "ol/source/ImageStatic";
import Projection from "ol/proj/Projection";
import { DoubleClickZoom } from "ol/interaction";
import { getCenter } from "ol/extent";
import { pointSelectionModeVals, scanSettingsTypeVals, scanWorkflowLoadingMessages, globalUrlPrefix } from "../../../utils/const";
import Point from "ol/geom/Point";
import { Style, Fill, Stroke } from "ol/style";
import CircleStyle from "ol/style/Circle";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { setDeviceBusy, setDeviceIdle } from "../../../action/device_status.action";
import axios from "axios";
import { AuthHeader } from "../../../helper/auth.token";
import ModalImage from "react-modal-image";
import { setDropDistancePointOnly, setDropDistancePointLength, setFocusPoints, updateActionOngoing, takePreviewBlood , setPreviewStatusToConfirmed} from "../../../action/preview_status.action";
import CameraSettings from "./cameraSettings";
import { getSlideAndInitialiseMapState, getQuadrant, getTileNumber, getTiledImageSizeInMicrons, recreateMapLayers, isZoomForTiled } from '../../neoviewer/map_utils'
import '../../../asset/style/workflow/advanced-preview-settings-component.css';
import {mapLayerTypes} from "../../../../src/utils/const"
import BloodSlideMap from '../../neoviewer/bloodslidemap'
import BloodPreviewSlideMap from '../../neoviewer/blood_preview_slidemap'
import {resetMapState, resetMapParams} from '../../../action/morpheus.state.action'
import { yTopChanger, yBottomChanger,yTopChange, yBottomChange, countWBC } from '../../../action/bloodConfig.action';
const { Search } = Input;
const { TabPane } = Tabs;
var querystring = require('querystring');

class BloodPreviewSettingsComp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            slide_id : '',
            y_start:'',
            y_end:'',
            wbcCount:100,
        }
    }


    componentDidMount = () => {
        // this.props.dispatch(resetMapParams());
        let bloodData = ((((((this.props.deviceStatus||{})[this.props.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus||{})[this.props.slot_id]
        // let prevbloodData = ((((((prevProps.deviceStatus||{})[this.props.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus||{})[this.props.slot_id]
        // if((bloodData||{}).ystart != (prevbloodData||{}).ystart && (bloodData||{}).yend != (prevbloodData||{}).yend){
            this.setState({
                y_start:bloodData.ystart,
                y_end: bloodData.yend,
                wbcCount: bloodData.noWBC
            })
            this.props.dispatch(yTopChange(bloodData.ystart))
            this.props.dispatch(yBottomChange(bloodData.yend))
            this.props.dispatch(countWBC(bloodData.noWBC))
            // this.props.dispatch(resetMapParams());
        // }
    }
    
    componentDidUpdate = (prevProps) => {
        let bloodData = ((((((this.props.deviceStatus||{})[this.props.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus||{})[this.props.slot_id]
        let prevbloodData = ((((((prevProps.deviceStatus||{})[this.props.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus||{})[this.props.slot_id]
        if((bloodData||{}).ystart != (prevbloodData||{}).ystart && (bloodData||{}).yend != (prevbloodData||{}).yend){
            this.setState({
                y_start:bloodData.ystart,
                y_end: bloodData.yend,
                wbcCount: bloodData.noWBC
            })
            this.props.dispatch(yTopChange(bloodData.ystart))
            this.props.dispatch(yBottomChange(bloodData.yend))
            this.props.dispatch(countWBC(bloodData.noWBC))
        }
    }

    componentWillUnmount = () => {
        // this.props.dispatch(resetMapParams());
    }

    wbcCountChange = (val) => {
        console.log("count", val)
        this.props.dispatch(countWBC(val))
        this.setState({
            wbcCount: val,
        })
    }

    saveChanges = () => {
        let val = {
            "y_top": this.props.bloodConfig.yTop,
            "y_bottom": this.props.bloodConfig.yBottom,
            "countWBC": 0,
            "slot_id": this.props.slot_id,
        }
        let url = `/server/devices/${this.props.device_id}/update_blood_params/`;
        axios.post(url, querystring.stringify(val),{ headers: { Authorization: AuthHeader() } }).then(res => {
            if(res.status==200){
                message.info("Config Updated");
                this.props.closeExpadedSlideView(this.props.slot_id);
                // this.props.dispatch(resetMapParams());
                // this.props.dispatch(setPreviewStatusToConfirmed(this.props.slot_id,"",""));
            }
        }).catch(err => {
            message.error("Failed to update config")
        })
    }


    render() {
        let viewerComponent;
        let bloodData = ((((((this.props.deviceStatus||{})[this.props.device_id]||{}).scanner_health)||{}).scanner_response||{}).bloodPreviewStatus||{})[this.props.slot_id]
        console.log("yy", this.props.id)
        //ymin should always be lesser one 
        //ymax should always be greater one
        let y_min = Math.min(bloodData.ystart, bloodData.yend)
        let y_max = Math.max(bloodData.ystart, bloodData.yend)
        viewerComponent = <div style={{marginLeft:'30px'}}>
                        <BloodPreviewSlideMap key="viewer" slide_id={this.props.id} map_id={0} view_mode={4} field_view={true} mode={"PREVIEW"}
                        y_top={y_min} y_bottom={y_max} expandedView={this.props.expandedView}/>
                        </div>
    

        let is_superuser = cookie.loadAll().superuser === "true";
        
        return(
            <div>
                <Row style={{textAlign:'center', fontSize:'20px'}}>
                    <h3>Region Selection</h3>
                </Row>
                <Row>
                    {viewerComponent}
                </Row>
                <Row style={{marginTop:'20px', marginLeft:'38px'}}>
                    {/* <Col span={4} >
                        WBC Count
                        <InputNumber style={{marginLeft:'10px'}} min={1} max={5000} value={this.props.bloodConfig.countWBC} onChange={this.wbcCountChange} />
                    </Col> */}
                    <Col span={4}>
                        <Button onClick={this.saveChanges} type={"primary"}>
                            Finalize Slide Settings
                        </Button>
                    </Col>
                </Row>
                
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return{
        deviceStatus: state.deviceStatusReducer ,
        bloodConfig: state.bloodConfigReducer,
    }
}

export default connect(mapStateToProps)(BloodPreviewSettingsComp);
