export const UPDATE_MAP_STATE = "UPDATE_MAP_STATE";
export const UPDATE_MAPS_STATE = "UPDATE_ALL_MAP_STATE";
export const UPDATE_SLIDE_STATE = "UPDATE_SLIDE_STATE";
export const UPDATE_GRID_STATE = "UPDATE_GRID_STATE";
export const UPDATE_VIEWER_SETTINGS = "UPDATE_VIEWER_SETTINGS";
export const UPDATE_BLOOD_VIEWER_SETTINGS = "UPDATE_BLOOD_VIEWER_SETTINGS";
export const UPDATE_ANNOTATION_STATE = "UPDATE_ANNOTATION_STATE";
export const ADD_NEW_ANNOTATIONS = "ADD_NEW_ANNOTATIONS";
export const DELETE_ANNOTATIONS = "DELETE_ANNOTATIONS";
export const UPDATE_DEEP_BIO_STATE = "UPDATE_DEEP_BIO_STATE";
export const ADD_NEW_DEEP_BIO_ANNOTATIONS = "ADD_NEW_DEEP_BIO_ANNOTATIONS";
export const DELETE_DEEP_BIO_ANNOTATIONS = "DELETE_DEEP_BIO_ANNOTATIONS";
