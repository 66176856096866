import React, { Component } from 'react';
import cookie from "react-cookies";
import { CodepenSquareFilled,SafetyCertificateOutlined, FolderOpenOutlined, FolderOutlined, PlusOutlined, EyeOutlined, SmileOutlined } from '@ant-design/icons';
import {
  Col,
  Spin,
  Divider,
  Table,
  Input,
  Button,
  Row,
  Tooltip,
  Empty,
  Pagination,
  Tag,
  Select,
  Popconfirm,
} from 'antd';
import { globalUrlPrefix, openInNewTabType } from '../utils/const';
import axios from "axios";
import { updateUrlStateInReducer,updateUrlStateInReducerClia, updateCaseSearch, changePage, updateValidationId, clearAllFilters } from '../action/search.case.action';
import { caseUpdateAlert, assignUsersUpdateAlert, removeUserUpdateAlert, markCaseUnread, markCaseAndSlidesRead } from '../action/cases.action'
import { makeMenuVisible } from "../action/context.menu.action";
import { connect } from "react-redux";
import { openDrawer } from "../action/dashboard.action";
import { historyMP } from '../helper/history';
import FilterComp from "../component/case/filter";
import '../asset/style/custom_antd_table_style.css';
import { AuthHeader } from "../helper/auth.token";
import { message, Result, List } from "antd";

var querystring = require('querystring');

const { Search } = Input;
const { Column, ColumnGroup } = Table;
const ButtonGroup = Button.Group;
const Option = Select.Option;


const pageSize = 15;

class CliaView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      search: this.props.urlState.search,
      initializationDone: false,
      case_status: -1,
      selected_users: [],
      validationIds: [],
      selectedValidation: '',
      assignedValidations: {},
      validatonSummary: [],
      currentValidationId: this.props.validationId,
      view_type: this.props.type,
      validationName:'',
    }

    this.props.dispatch(updateUrlStateInReducerClia(this.props.urlState, this.props.type));
  }

  componentWillMount() {
    this.props.dispatch(openDrawer());
    // this.getValidationIDs();
    // this.getValidationSummary();
    this.getValidationData();
    // this.props.dispatch(updateValidationId(this.props.validationId, this.props.urlState));
  }

  componentWillUnmount(){
    // this.props.dispatch(clearAllFilters());
  }

  getValidationData = (id) => {
    let url = `/api/get_validation_data/?id=${this.props.validationId}`;
    console.log("xxx")
    axios.get(url)
        .then(response => {
          console.log("naaam",response.data['name'])
          this.setState({
            validationName: response.data['name'],
          })
        })
  }

  getCases = (cases) => {
    console.log("cases",cases)
    let newData = []
    Object.keys(cases).sort(
      function (a, b) {
        return cases[a].displayOrder - cases[b].displayOrder;
      }
    ).map((key) => {
      let curr = cases[key];
      curr.key = curr.id;
      curr.case = this.getCaseDesign(curr);
      curr.type = openInNewTabType.CASE;
      newData.push(curr);
    });
    console.log("new-data",newData)
    return newData;
  }

  getCaseDesign = (caseInfo) => {
    const isLongCaseID = caseInfo.case_id.length > 15;
    const caseIdElem = (
      caseInfo.unread ? 
        <b>{isLongCaseID ? `${caseInfo.case_id.slice(0, 15)}...` : caseInfo.case_id}</b> :
        <span>{isLongCaseID ? `${caseInfo.case_id.slice(0, 15)}...` : caseInfo.case_id}</span>
    );
    const finalCaseIdElem = isLongCaseID ? <Tooltip title={caseInfo.case_id} key={caseInfo.case_id}>{caseIdElem}</Tooltip> : caseIdElem;

    const isLongName = caseInfo.patient_name.length > 25;
    const nameElem = (
      caseInfo.unread ? 
        <b>{isLongName ? `${caseInfo.patient_name.slice(0, 25)}...` : caseInfo.patient_name}</b> :
        <span>{isLongName ? `${caseInfo.patient_name.slice(0, 25)}...` : caseInfo.patient_name}</span>
    );
    const finalNameElem = isLongName ? <Tooltip title={caseInfo.patient_name} key={caseInfo.patient_name}>{nameElem}</Tooltip> : nameElem;

    const isLongStatus = caseInfo.case_status.length > 35;
    const statusElem = (
      <Tag key={caseInfo.case_id} color="#3b7199" onClick={(event) => event.stopPropagation()}>
        {isLongStatus ? `${caseInfo.validation_status.slice(0, 35)}...` : caseInfo.validation_status}
      </Tag>
    );
    let finalStatusElem=""
    finalStatusElem = this.props.type=='assigned'?
      isLongStatus ? <Tooltip title={caseInfo.case_status} key={caseInfo.case_status}>{statusElem}</Tooltip> : statusElem:null;
    
    let assigned_names=""
    assigned_names = this.props.type=='assigned'?
       this.getAssignedNamesComponent(caseInfo) :null;
    // const assigned_names = this.getAssignedNamesComponent(caseInfo);
    // const assigned_validation = this.getAssignedValidationsComponent(caseInfo);
    //const assigned_validation = this.getAssignedValidations(caseInfo.case_id)
    return <Row>
      <Col span={3} offset={1}>
        {finalCaseIdElem}
      </Col>
      <Col span={5} offset={1}>
        {finalNameElem}
      </Col>
      <Col span={5} offset={1}>
        {finalStatusElem}
      </Col>
      <Col span={5} offset={1}>
        {assigned_names}
      </Col>
      {/* <Col span={5}>
        {assigned_validation}
      </Col> */}
    </Row>
  }


  getValidationSummary = () => {
    let url = '/api/fetch_validation_summary/';
    axios.get(url)
        .then(response => {
          console.log("summary",response.data["result"])
          this.setState({validatonSummary:response.data["result"]})
        })
  }

  getAssignedNamesComponent = (caseInfo) => {
    return caseInfo.assigned_users.map((user, index) => {
      const full_name = user.first_name + ' ' + user.last_name;
      const isLongUserName = full_name.length > 20;
      let color = 'blue';
      const userNameElem = (
        <Tag key={user.id} color={color} onClick={(event) => event.stopPropagation()} style={{ cursor: 'pointer' }}>
          {isLongUserName ? `${full_name.slice(0, 20)}...` : full_name}
        </Tag>
      );
      const finalUserTag = isLongUserName ? <Tooltip title={full_name} key={user.id}>{userNameElem}</Tooltip> : userNameElem;
      return <Popconfirm
        title={"Remove user " + full_name + " from this case?"}
        onConfirm={(event) => this.handleCancelAssignedUser(event, caseInfo, user.id)}
        okText="Yes"
        palcement="topRight"
        style={{ padding: 20 }}
        cancelText="No"
        onCancel={(event) => {
          event.stopPropagation();
        }}>
        {finalUserTag}
      </Popconfirm>
    });
  }

  getAssignedValidationsComponent = (caseInfo) => {
    // if(caseInfo.assignedValidations == undefined){
    //   return;
    // }
    return ((caseInfo).assigned_validations).map((validation, index)=>{
      let color = 'green';
      const validationTag = (
        <Tag key={validation.id} color={color} style={{ cursor: 'pointer' }}>
          {validation.validation_id}
        </Tag>
      );
      return validationTag
    });
  }

  handleCancelAssignedUser = (event, currCase, user_id) => {
    event.stopPropagation();
    let val = {
      id: currCase.id,
      case_id: currCase.case_id,
      displayOrder: currCase.displayOrder,
      user_id: user_id
    };
    this.props.dispatch(removeUserUpdateAlert(currCase.id, val, this.props.urlState));
  }

  generateReport = () => {
    let url = `/api/get_pdf/?case_id=${this.state.selectedCase.case_id}`;
    window.open(url);
  }

  initializeSearch = () => {
    if (!this.state.initializationDone && ((this.props.urlState||{}).search||{}).length > 0) {
      this.setState({
        search: this.props.urlState.search,
        initializationDone: true,
      })
    }
  }

  onCaseSearch = (value) => {
    this.setState({
      selectedRowKeys: [],
    })
    this.props.dispatch(updateCaseSearch(value, this.props.urlState));
  }

  onInputChange = (e) => {
    if (e.target.value == '') {
      this.onSeachReset();
    }
    this.setState({
      search: e.target.value,
    });
  }

  onSeachReset = () => {
    if (this.state.search != '') {
      let search = '';
      this.props.dispatch(updateCaseSearch(search, this.props.urlState));
      this.setState({
        search,
        selectedRowKeys: [],
      });
    }
  }

  getStatusOptions = () => {
    let status_options = Object.keys(this.props.allCaseStatus).map((key) => {
      let value = this.props.allCaseStatus[key];
      return <Option key={value.id} value={value.id}>{value.status}</Option>
    });
    status_options.unshift(<Option value={-1} key={-1}>Select Status</Option>);
    return status_options;
  }

  getUserOptions = () => {
    return Object.keys(this.props.allUsers).map((key) => {
      let value = this.props.allUsers[key];
      console.log("value--", key, value)
      return <Option key={value.id} value={value.id}>{value.first_name + ' ' + value.last_name}</Option>
    });
  }

  allUnreadCases = (selectedCases) => {
    for (let i = 0; i < selectedCases.length; i++) {
      if (this.props.allCases[selectedCases[i]].unread) {
        return false;
      }
    }
    return true;
  }

  makeCasesUnread = () => {
    this.state.selectedRowKeys.map((value, index) => {
      let curr_case = this.props.allCases[value];
      this.props.dispatch(markCaseUnread(curr_case));
    });
  }

  makeCasesRead = () => {
    this.state.selectedRowKeys.map((value, index) => {
      let curr_case = this.props.allCases[value];
      this.props.dispatch(markCaseAndSlidesRead(curr_case));
    });
  }

  getValidationIDs = () => {
    let url = `/api/get_all_validation_id/`;
    axios.post(url,querystring.stringify({user: parseInt(cookie.load('user_id'))}),{ headers: { Authorization: AuthHeader() }})
        .then(response => {
            console.log(response.data.ids)
            let data = response.data['data']
            let ids = []
            for(let i=0; i<data.length; i++){
              ids.push(data[i].id)
            }
            // let ids = response.data.ids
            let op = ids.map((id,index) =><Option value={id} key={index}>{id}</Option>)
            this.setState({validationIds:op})
        })
    }
  

  getRowActionsIfAtleastOneRowSelected = () => {

    return this.state.selectedRowKeys.length > 0 ?
    <div>
          {this.props.type=='assigned'?
          <Row> 
                    <Col span={6}>
                      <Button  onClick={this.handleChangeUnassignValidation} type="primary">
                        {`Remove from ${this.state.validationName}`}
                      </Button>
                    </Col>
                    <Col span={4} offset={1}>
                      <Select
                        mode="multiple"
                        value={this.state.selected_users}
                        placeholder="Select Users To Assign"
                        onChange={this.handleUserChange}
                        filterOption={this.filterOptionUsers}
                        style={{ width: 300, marginLeft: 8 }}
                      >
                        {this.getUserOptions()}
                      </Select>
                    </Col>
                    <Col span={6} offset={1}>
                        <Button onClick={this.sumitUsersAndStatus}>
                          Submit
                        </Button>
                    </Col> 
          </Row>
          : <Row>
          <Button onClick={this.handleChangeAssignValidation} type="primary">
              {`Add to ${this.state.validationName}`}
          </Button> 
        </Row>}
        
      </div>
      :
      null
  }

  handleTypeChange = (status) => {
    this.setState({
      case_status: status,
    });
  }

  handleUserChange = (value) => {
    console.log("val--", value)
    this.setState({
      selected_users: value,
    })
  }

  handleValidationChange = (value) => {
      this.setState({
          selectedValidation: value,
      })
  }

  filterOptionUsers = (inputValue, option) => {
    return option.props.children.toLowerCase().includes(inputValue.toLowerCase())
  }

  handleChangeAssignValidation = ()=>{
      let selectedRowKeys = this.state.selectedRowKeys;
      let selectedValidation = this.props.validationId;
      let url = "/api/assign_validation/"
      axios.post(url,querystring.stringify({selected_cases:selectedRowKeys, validation:selectedValidation}),{ headers: { Authorization: AuthHeader() }})
        .then(response => {
            console.log(response)
            message.success("Case/Cases Successfully Updated");
            setTimeout(() => {   window.location.reload(); }, 500);
        })
    }

    handleChangeUnassignValidation = ()=>{
      let selectedRowKeys = this.state.selectedRowKeys;
      let selectedValidation = this.props.validationId;
      let url = "/api/unassign_validation/"
      axios.post(url,querystring.stringify({selected_cases:selectedRowKeys, validation:selectedValidation}),{ headers: { Authorization: AuthHeader() }})
        .then(response => {
            console.log(response)
            message.success("Case/Cases Successfully Updated");
            setTimeout(() => {   window.location.reload(); }, 500);
        })
    }
  

  sumitUsersAndStatus = () => {
    if (this.state.selected_users.length > 0) {
      let assigned_users_id = [];
      let all_cases=[]
      console.log("selected_users", this.state.selected_users)
      this.state.selectedRowKeys.map((value, index) => {
        // this.handleChangeAssignUsers(this.props.allCases[value], this.state.selected_users, this.state.selectedRowKeys);
        all_cases.push(this.props.allCases[value])
      });
      this.handleChangeAssignUsers(all_cases, this.state.selected_users, this.state.selectedRowKeys)
    }

    if (this.state.case_status !== -1) {
      this.state.selectedRowKeys.map((value, index) => {
        this.handleChangeStatus(this.props.allCases[value], this.state.case_status, this.state.selectedRowKeys);
      });
    }

    if (this.state.selectedValidation != ''){
        this.handleChangeAssignValidation(this.state.selectedRowKeys, this.state.selectedValidation);
    }

    this.setState({
      selected_users: [],
      case_status: -1,
    })
    // setTimeout(() => {   window.location.reload(); }, 500);
   
  }

  handleChangeStatus = (currCase, input, selectedRows) => {
    let val = {
      case_id: currCase.case_id,
      displayOrder: currCase.displayOrder,
      case_status: input
    };
    this.props.dispatch(caseUpdateAlert(currCase.id, val, selectedRows));
  };

  handleChangeAssignUsers = (currCases, input, selectedRows) => {
    let values = [];
    let ids = [];
    for (let i = 0; i<currCases.length; i++){
      let val = {
        id: currCases[i].id,
        case_id: currCases[i].case_id,
        displayOrder: currCases[i].displayOrder,
        assigned_users: input
      };
      values.push(val)
      ids.push(currCases[i].id)
    }
    this.props.dispatch(assignUsersUpdateAlert(ids, values, input, selectedRows));
  };

  getFilters = () => {
    return this.state.selectedRowKeys.length > 0 ? undefined : <FilterComp />;
  }

  getSpace = () => {
    return this.state.selectedRowKeys.length > 0 ? undefined : <div style={{height: '50px'}}></div>;
  }

  getRowActions = () => {
    return <Row>
      <Col span={this.state.selectedRowKeys.length > 0 ? 24 : 0}>
        {/* <span style={{ marginRight: "10px" }}>
          {this.state.selectedRowKeys.length > 0 ? "What do you want to do with the selected cases ? " : ""}
        </span> */}
        {this.getRowActionsIfAtleastOneRowSelected()}
      </Col>
      {this.props.type=='assigned' && this.state.selectedRowKeys.length == 0 ? 
      <Row>
      <Col span={this.state.selectedRowKeys.length > 0 ? 0 : 3} style={{marginLeft:'-12px'}}>{this.getFilters()}</Col>
      <Col span={this.state.selectedRowKeys.length > 0 ? 0 : 6} style={{marginTop:'10px', fontSize:'15px'}}>{`Showing ${this.props.urlState.count} of ${this.props.total_count}`}</Col>  
      </Row>
      : null }
      {/* <Row><Col span={this.state.selectedRowKeys.length > 0 ? 0 : 20}>{}</Col></Row>} */}
      
    </Row>
  }

  getPaginationDiv = () => {
    return <Pagination
      total={this.props.total}
      showTotal={(total, range) =>
        this.isMobile ? `` : `Showing ${range[0]}-${range[1]} out of ${total} total cases`
      }
      current={this.props.urlState.page}
      pageSize={pageSize}
      defaultCurrent={1}
      onChange={this.updatePageNew}
      size="small"
      className="pagination-mp"
    />
  }

  updatePage = (pageNumber) => {
    historyMP.push('/' + globalUrlPrefix + '/clia?page=' + pageNumber);
    this.props.dispatch(changePage(pageNumber, this.props.urlState));
  }

  updatePageNew = (pageNumber) => {
    historyMP.push('/' + globalUrlPrefix + `/clia_validation/${this.props.validationId}?&page=` + pageNumber);//neo/clia_validation/VALIDATION_STUDY_1?&page=1
    this.props.dispatch(changePage(pageNumber, this.props.urlState));
  }

  linkCase = (id) => {
    window.location.href = "/" + globalUrlPrefix + "/case/" + id;
  };

  linkCliaCase = (id) => {
    window.location.href = "/" + globalUrlPrefix + "/clia_case/" + id
  }

  openAddNewCase = () => {
    window.location.href = "/" + globalUrlPrefix + "/addcase/?clia=true";
  }

  openAddNewValidationStudy = () => {
      window.location.href = "/" + globalUrlPrefix + "/addvalidation";
  }

  openSummary = () => {
      window.location.href = "/" + globalUrlPrefix + "/summary"
  }

  openDashboard = () => {
    window.location.href = "/";
  }

  getOSDetails = () => {
    let OSVersion = navigator.appVersion
    let os = 'Not Supported'
    if(OSVersion.indexOf("Win") != -1){
      os = "Windows"
    }
    else if(OSVersion.indexOf("Mac") != -1){
      os = "Mac"
    }
    else if(OSVersion.indexOf("Linux") != -1){
      os = "Linux"
    }
    return os
  }

  downloadCLIAApp = () => {
    let appName;
    let os = this.getOSDetails();
    let url =`/api/download_app/?os=${os}`; // `http://0.0.0.0:8002/api/download_app/?os=${os}`
    // window.open(url, "_blank");
    // axios.get(url);
    if(os=='Windows'){
          appName='morphle-clia.exe'
         }
          else if(os=='Mac'){
            appName='morphle-clia.app'
          }
          else if(os=='Linux'){
            appName='morphle-clia.deb'
          }
    axios({
      url: url, //your url
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'morphle-clia.deb'); //or any other extension
        document.body.appendChild(link);
        link.click();
    });
  }

  isValidUUID = (uuid) => {
    let token = ""+uuid
    token=token.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (token === null) {
      return false;
    }
    return true;
  }

  render() {
    this.initializeSearch();
    let data = this.getCases(this.props.allCases);
    let os = this.getOSDetails();
    console.log("case-data",data)
    console.log("rk",this.state.selectedRowKeys)
    let columns = [
      {
        title: () => {
          return <div>
            <Row>
              <Col span={3} offset={1}>
                <b >CASE NAME</b>
              </Col>
              <Col span={5} offset={1}>
                <b >PATIENT'S NAME</b>
              </Col>
              <Col span={5} offset={1}>
                <b >STATUS</b>
              </Col>
              <Col span={5} offset={1}>
                <b >ASSIGNED USERS</b>
              </Col>
              {/* <Col span={5}>
              <b className="white-text">VALIDATION STUDY</b>
              </Col> */}
            </Row>
          </div>
        },
        dataIndex: "case",
        key: "case",
      }
    ]

    let unassigned_columns = [
      {
        title: () => {
          return <div>
            <Row>
              <Col span={3} offset={1}>
                <b >CASE NAME</b>
              </Col>
              <Col span={5} offset={1}>
                <b >PATIENT'S NAME</b>
              </Col>
            </Row>
          </div>
        },
        dataIndex: "case",
        key: "case",
      }
    ]

    const onRow = (record, rowIndex) => {
      return {
        onClick: event => {
          this.linkCliaCase(record.id);
        },
        onContextMenu: (event) => {
          event.preventDefault();
          this.props.dispatch(makeMenuVisible({ visible: true, x: event.clientX, y: event.clientY, record: record, height: this.props.height }));
        }
      };
    }

    let rowActions = [
      <Row className="row-actions">
        {this.getRowActions()}
      </Row>
    ];

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRowKeys: selectedRowKeys
        })
      },
      selectedRowKeys: this.state.selectedRowKeys,
      columnWidth: '10px',

    };

    

    if(cookie.loadAll().isMobile==='true'){
      return (
        <div>
          <Result
              status="warning"
              title="CLIA doesnt support mobile devices! Switch to PC!"
              extra={
                <Button type="primary" key="console" onClick={this.openDashboard}>
                  Dashboard
                </Button>
              }
            />
        </div>
      )
    }

    if(os == 'Not Supported'){
      return(<div>
        <Result
              status="warning"
              title="CLIA only supports Windows/Linux/MacOS!"
              extra={
                <Button type="primary" key="console" onClick={this.openDashboard}>
                  Dashboard
                </Button>
              }
            />
      </div>)
    }
    os = this.getOSDetails();

    let steps;
    if(os=="Windows"){
      steps = <div>
        <h3>Installation on Windows:</h3>
        <ol style={{display:'inline-block',textAlign:'left'}}>
          <li> Make sure you are using chrome browser</li>
          <li> Download the App</li>
          <li> Double Click on downloaded MorphleClia.exe file to run it</li>
        </ol>
      </div>
    }
    else if(os=="Mac"){
      steps = <div>
        <h3>Installation on MAC:</h3>
        <ul style={{display:'inline-block',textAlign:'left'}}>
          <li>Make sure you are using chrome browser</li>
          <li>Download the App</li>
          <li>Double Click on downloaded MorphleClia.app file to run it</li>
        </ul>
      </div>
    }
    else if(os=="Linux"){
      steps = <div style={{textAlign:'center'}}>
        <h3>Installation on Linux:</h3>
        <ol style={{display:'inline-block',textAlign:'left'}}>
          <li>Make sure you are using chrome browser</li>
          <li>Download the App</li>
          <li>Double Click on downloaded MorphleClia.deb file to install it</li>
          <li>After installation go to the apps and run MorphleClia</li>
        </ol>
      </div>
    }

    console.log("ccookkiiee",cookie.loadAll().screen_supported)
    console.log(typeof(cookie.loadAll().screen_supported))
    
    if((cookie.loadAll().screen_supported==='false' || !this.isValidUUID(cookie.loadAll().screen_supported))&& cookie.loadAll().clia_screen_check=="true"){
      return(
        <div>
          <Result
            icon={<SmileOutlined />}
            title="CLIA : Digital Pathology Validation"
            extra={<div>
                <p style={{fontSize:'20px'}}>Your organisation allows only set number of Digital displays (Monitors) for CLIA. <br></br>To verify compatibility please download the app and follow the mentioned steps to RUN it.</p>
                <p style={{fontSize:'20px'}}>If already downloaded just follow the steps to RUN it.</p>
                <Button type="primary" onClick={this.downloadCLIAApp}>Download</Button>
              </div>
              }
          />
                <div style={{fontSize:'20px'}}>
                  
                  {steps}
                </div>
        </div>
      )
    }

    return (
      <div>
        <Spin tip="Loading..." spinning={this.props.isFetching} delay={500}>
          {this.props.isFetching === true ? <div></div> :
            <div>
              <Row>
                <Col span={24} offset={0} className="all-slides">
                  {/* <br /> */}
                  <Row>
                    {/* <Col span={2}>
                      {cookie.loadAll().is_staff === "true" ?
                        <Button onClick={this.openAddNewCase} className="custom-button-style add-case-button-position custom-button-background">
                          Add New Case
                                        <PlusOutlined style={{ fontSize: "13px" }} />
                        </Button> : undefined}
                    </Col> */}
                    {/* <Col span={3} style={{marginLeft:'15px'}}>
                      {cookie.loadAll().is_staff === "true" ?
                        <Button onClick={this.openAddNewValidationStudy} className="custom-button-style add-case-button-position custom-button-background">
                          Add Validation Study
                                        <PlusOutlined style={{ fontSize: "13px" }} />
                        </Button> : undefined}
                    </Col> */}
                    {/* <Col span={3}>
                      {cookie.loadAll().is_staff === "true" ?
                        <Button onClick={this.openSummary} className="custom-button-style add-case-button-position custom-button-background">
                          View Summary
                                        <EyeOutlined style={{ fontSize: "13px" }}  />
                        </Button> : undefined}
                    </Col> */}
                    {/* <Col span={6} offset={6}>
                      <Search
                        value={this.state.search || ''}
                        onChange={this.onInputChange}
                        placeholder="Search by Case ID"
                        onSearch={this.onCaseSearch}
                        enterButton
                      />
                    </Col>
                    <Col span={6}>
                      <Button type="danger" onClick={this.onSeachReset} style={{ marginLeft: 10 }}>
                        Reset
                      </Button>
                    </Col>
                    <br />
                    <br /> */}
                  </Row>
                  {/* <Row>
                    {this.getPaginationDiv()}
                  </Row> */}
                  
                  <div>
                    {rowActions}
                    {/* <Divider /> */}
                  </div>  
                  
                  {this.props.type == "assigned"?
                    <Row>
                      <Table
                        // className="custom-hover-table-case custom-table-height custom-table-selection custom-table-header"
                        className="custom-hover-table-case custom-table-height custom-table-selection custom-table-header"
                        rowClassName={(record, index) => (record.unread ? 'slide-table-rows custom-hover-table-box unread' : 'slide-table-rows custom-hover-table-box read')}
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        onRow={onRow}
                        locale={{ emptyText: (<Empty description="No Cases Found" />) }}
                        rowSelection={cookie.loadAll().is_staff==="true"?rowSelection:null}
                        size="small"
                      />
                    </Row>
                  :
                    <Row>
                      <Table
                        // className="custom-hover-table-case custom-table-height custom-table-selection custom-table-header"
                        className="custom-hover-table-case custom-table-height custom-table-selection custom-table-header"
                        rowClassName={(record, index) => (record.unread ? 'slide-table-rows custom-hover-table-box unread' : 'slide-table-rows custom-hover-table-box read')}
                        columns={unassigned_columns}
                        dataSource={data}
                        pagination={false}
                        onRow={onRow}
                        locale={{ emptyText: (<Empty description="No Cases Found" />) }}
                        rowSelection={cookie.loadAll().is_staff==="true"?rowSelection:null}
                        size="small"
                      />
                    </Row>
                  }
                  {/* <Row>
                    <Table
                      // className="custom-hover-table-case custom-table-height custom-table-selection custom-table-header"
                      className="custom-hover-table-case custom-table-height custom-table-selection custom-table-header"
                      rowClassName={(record, index) => (record.unread ? 'slide-table-rows custom-hover-table-box unread' : 'slide-table-rows custom-hover-table-box read')}
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      onRow={onRow}
                      locale={{ emptyText: (<Empty description="No Cases Found" />) }}
                      rowSelection={cookie.loadAll().is_staff==="true"?rowSelection:null}
                      size="small"
                    />
                  </Row> */}
                  <Row>
                    {this.getPaginationDiv()}
                  </Row>
                  {/* <Divider />
                  <Row>
                    <h1>VALIDATION SUMMARY</h1>
                    <Table
                     className="custom-hover-table-case custom-table-height custom-table-selection custom-table-header"
                    //  columns={validation_columns}
                     dataSource={this.state.validatonSummary}
                     size="small"
                    >
                      <Column title="CASE ID" span={1} dataIndex="case_id"/>
                      <Column title="PATHOLOGIST" span={1} dataIndex="part_designation"/>
                      <Column title="DATE OF CASE" span={1} dataIndex="date"/>
                      <Column title="CASE TYPE" span={1} dataIndex="case_type"/>
                      <Column title="POST WASHOUT DIGITAL DIAGNOSIS" span={1} dataIndex="post_washout_diagnosis"/>
                      <Column title="GLASS DIAGNOSIS" span={1} dataIndex="glass_diagnosis"/>
                      <Column title="QUALITY OF SCAN" span={1} dataIndex="quality_of_scan"/>
                      <Column title="DIAGNOSTIC CERTAINTY" span={1} dataIndex="diagnostic_certainty"/>
                      <Column title="CONCORDANCE DESIGNATION" span={1} dataIndex="concordance_designation"/>
                      <Column title="AGREE/DISAGREE" span={1} dataIndex="agree_disagree"/>
                      
                    </Table>
                  </Row> */}
                  {/* <Divider />
                  <Row>
                    {this.state.selectedRowKeys.length > 0?
                      this.props.type=='assigned'?
                        <Button  onClick={this.handleChangeUnassignValidation} type="primary" size="large" block>
                          {`UNASSIGN      ${this.state.validationName}`}
                        </Button>
                      :
                      <Button onClick={this.handleChangeAssignValidation} type="primary" size="large" block>
                          {`ASSIGN      ${this.state.validationName}`}
                      </Button>
                    :
                    null}
                  </Row> */}
                </Col>
              </Row>
            </div>

          }
        </Spin>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginStatus: state.loginAlerts.loginStatus,
    allCases: state.casesReducer,
    allCaseStatus: state.caseStatusReducer.case_status,
    allUsers: state.caseStatusReducer.users,
    numPages: state.caseStatusReducer.numPages,
    pageNumber: state.caseStatusReducer.pageNumber,
    total: state.caseStatusReducer.total,
    urlState: state.searchCaseReducer,
    isFetching: state.searchCaseReducer.isFetching
  };
};

export default connect(mapStateToProps)(CliaView);